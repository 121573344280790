import React from "react";
import { FullTextHTMLDiv, FullTextContainerHTML } from "./FullTextElements";

const FullTextHTMLplaceholderSection = ({ id, content }) => {
  return (
    <FullTextContainerHTML id={id}>
      <FullTextHTMLDiv>{content}</FullTextHTMLDiv>
    </FullTextContainerHTML>
  );
};

export default FullTextHTMLplaceholderSection;
