import React from "react";
import "./App.css";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import SigninPage from "./pages/signin";
import Licenses from "./pages/licenses";
import Imprint from "./pages/imprint";
import Knowledge from "./pages/knowledge";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/signin" element={<SigninPage />} exact />
        <Route path="/licenses" element={<Licenses />} exact />
        <Route path="/imprint" element={<Imprint />} exact />
        <Route path="/knowledge" element={<Knowledge />} exact />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

// Wrap the higher-order component function to enable Application Insights on it
export default withAITracking(reactPlugin, App);
