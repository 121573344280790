export default function convertHtmlToOoxml(htmlString, parser) {
  // const parser = new DOMParser();
  const nodeTree = parser.parseFromString(
    `<div>${htmlString}</div>`,
    "text/html"
  );

  // console.log("NodeTree", htmlString, nodeTree);

  // loop paragraphs, ignore any other tags for now
  return [...nodeTree.body.firstChild.childNodes].reduce((acc, cur) => {
    if (cur.nodeName === "P") {
      const completeParagraphXml =
        acc +
        xmlDictionary.P[0] +
        recurseChildNodes(cur.childNodes) +
        xmlDictionary.P[1];

      console.log("conversion", cur, completeParagraphXml);

      return completeParagraphXml;
    } else {
      return acc;
    }
  }, "");
}

function recurseChildNodes(childNodes, previousFormatting = []) {
  const nodeArray = [...childNodes];

  return nodeArray.reduce((totalXMLString, currentHtmlTag) => {
    if (currentHtmlTag.nodeName === "#text") {
      // combine formatting and create the run
      const combinedFormatString =
        xmlDictionary.runProperties[0] +
        previousFormatting.reduce(
          (acc, cur) => (cur[0] ? acc + cur[0] : acc),
          ""
        ) +
        xmlDictionary.runProperties[1];

      return (
        totalXMLString +
        xmlDictionary.run[0] +
        xmlDictionary.runProperties[0] +
        combinedFormatString +
        xmlDictionary.runProperties[1] +
        xmlDictionary["#text"][0] +
        escapeXmlEntities(currentHtmlTag.data) +
        xmlDictionary["#text"][1] +
        xmlDictionary.run[1]
      );
    } else if (currentHtmlTag.nodeName === "BR") {
      return (
        totalXMLString +
        xmlDictionary.run[0] +
        xmlDictionary.BR[0] +
        xmlDictionary.run[1]
      );
    } else {
      const currentFormatting = xmlDictionary[currentHtmlTag.nodeName] || [
        null,
        null,
      ];
      console.log("Current Formatting:", currentFormatting);
      const newFormattingArray = previousFormatting.concat([currentFormatting]);

      return (
        totalXMLString +
        recurseChildNodes(currentHtmlTag.childNodes, newFormattingArray)
      );
    }
  }, "");
}
// xml:space="preserve"
const xmlDictionary = {
  "#text": ["<w:t>", "</w:t>"],
  run: ["<w:r>", "</w:r>"],
  P: ["<w:p>", "</w:p>"],
  runProperties: ["<w:rPr>", "</w:rPr>"],
  STRONG: ["<w:b/>", null],
  B: ["<w:b/>", null],
  EM: ["<w:i/>", null],
  I: ["<w:i/>", null],
  U: ['<w:u w:val="single"/>', null],
  BR: ["<w:br/>", null],
};

function escapeXmlEntities(xmlText) {
  return xmlText
    .replace(/&/g, "&#38;")
    .replace(/</g, "&#60;")
    .replace(/>/g, "&#62;")
    .replace(/"/g, "&#34;")
    .replace(/'/g, "&#39;");
}
