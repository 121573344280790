import React from "react";
import { Button } from "../ButtonElement";
import i18next from "../../glossary";
import { Trans, useTranslation } from "react-i18next";

import dismissalProtectionProcess from "../SurveySection/images/dismissalProtectionProcess.png";
import dismissalProtectionProcessEN from "../SurveySection/images/dismissalProtectionProcessEN.png";
import severanceNegotiation from "../SurveySection/images/severanceNegotiation.png";
import severanceNegotiationEN from "../SurveySection/images/severanceNegotiationEN.png";
import reasonsForDismissal1 from "../SurveySection/images/reasonsForDismissal1.png";
import reasonsForDismissal1EN from "../SurveySection/images/reasonsForDismissal1EN.png";
import reasonsForDismissal2 from "../SurveySection/images/reasonsForDismissal2.png";
import reasonsForDismissal2EN from "../SurveySection/images/reasonsForDismissal2EN.png";
import dismissalProcess from "../SurveySection/images/dismissalProcess.png";
import dismissalProcessEN from "../SurveySection/images/dismissalProcessEN.png";
import VideoDE from "../../videos/VideoDE.mp4";
import VideoEN from "../../videos/VideoEN.mp4";

import {
  WikiContainer,
  WikiWrapper,
  // WikiRow,
  // Column1,
  // Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  VideoBg,
  VideoWrapper,
  Hashlink,
} from "./WikiElements";

function singleImg(img, alt) {
  return <Img src={img} alt={alt} />;
}

function multipleImg(img, img2, alt) {
  // console.log("hier: ", img);

  // return img.map((object, i) => <Img src={object} alt={alt} key={i}></Img>
  return (
    <>
      <Img src={img} alt={alt}></Img>
      <Img src={img2}></Img>
    </>
  );
  // )
}

function Images(props) {
  // function isObject(obj) {
  //   return obj === Object(obj);
  // }
  const img = props.img;
  const img2 = props.img2;
  const alt = props.alt;
  // if(isObject(img) === false ) {
  if (!img2) {
    return singleImg(img, alt);
  }
  return multipleImg(img, img2, alt);
}

const WikiSection = ({
  lightBg,
  imgStart,
  id,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  contentLink,
  buttonLabel,
  buttonTo,
  img,
  img2,
  alt,
  primary,
  dark,
  dark2,
}) => {
  const { t, i18n } = useTranslation();
  const lng = i18n.language;
  // console.log("lng wiki: ", lng);

  return (
    <>
      <WikiContainer id="dismissalProtectionProceedings">
        <WikiWrapper>
          <TextWrapper
            lightBg={false}
            dark="true"
            primary="true"
            darkText={true}
          >
            <TopLine>{topLine}</TopLine>
            <Heading lightText={true}>{t("wikiHeadline1")}</Heading>

            <Subtitle darkText={false}>
              <p>
                <Trans i18nKey="wikiDescription1"></Trans>
              </p>
            </Subtitle>
          </TextWrapper>

          {(() => {
            switch (lng) {
              case "de":
                return (
                  <ImgWrap>
                    <React.Fragment>
                      <Images
                        img={dismissalProtectionProcess}
                        alt={"dismissalProtectionProceedingsImg"}
                      />
                    </React.Fragment>
                  </ImgWrap>
                );
              case "en":
                return (
                  <ImgWrap>
                    <React.Fragment>
                      <Images
                        img={dismissalProtectionProcessEN}
                        alt={"dismissalProtectionProceedingsENImg"}
                      />
                    </React.Fragment>
                  </ImgWrap>
                );

              default:
                return (
                  <ImgWrap>
                    <React.Fragment>
                      <Images
                        img={dismissalProtectionProcess}
                        alt={"dismissalProtectionProceedingsImg"}
                      />
                    </React.Fragment>
                  </ImgWrap>
                );
            }
          })()}

          <BtnWrap>
            <Button
              to="dismissalProtectionProceedings"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              primary="true"
              dark="true"
              dark2="true"
            >
              {t("infoButton2")}
            </Button>
          </BtnWrap>
        </WikiWrapper>
      </WikiContainer>
      <WikiContainer
        lightBg={true}
        dark="false"
        primary="false"
        darkText={false}
        id="severanceNegotiation"
      >
        <WikiWrapper>
          <TextWrapper>
            <TopLine>{topLine}</TopLine>
            <Heading lightText={false}>{t("wikiHeadline2")}</Heading>
            <Subtitle darkText={true}>
              <p>
                <Trans i18nKey="wikiDescription2"></Trans>
              </p>
            </Subtitle>
          </TextWrapper>
          {(() => {
            switch (lng) {
              case "de":
                return (
                  <ImgWrap>
                    <React.Fragment>
                      <Images
                        img={severanceNegotiation}
                        alt={"severanceNegotiationImg"}
                      />
                    </React.Fragment>
                  </ImgWrap>
                );
              case "en":
                return (
                  <ImgWrap>
                    <React.Fragment>
                      <Images
                        img={severanceNegotiationEN}
                        alt={"severanceNegotiationENImg"}
                      />
                    </React.Fragment>
                  </ImgWrap>
                );

              default:
                return (
                  <ImgWrap>
                    <React.Fragment>
                      <Images
                        img={severanceNegotiation}
                        alt={"severanceNegotiationImg"}
                      />
                    </React.Fragment>
                  </ImgWrap>
                );
            }
          })()}

          <BtnWrap>
            <Button
              to="dismissalProtectionProceedings"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              primary="false"
              dark="false"
              dark2="false"
            >
              {t("infoButton2")}
            </Button>
          </BtnWrap>
        </WikiWrapper>
      </WikiContainer>
      <WikiContainer
        lightBg={false}
        dark="true"
        primary="true"
        darkText={true}
        id="videosKnowledge"
      >
        <WikiWrapper>
          <TextWrapper>
            <TopLine>{topLine}</TopLine>
            <Heading lightText={true}>{t("wikiHeadline3")}</Heading>
          </TextWrapper>

          {(() => {
            switch (lng) {
              case "de":
                return (
                  <ImgWrap>
                    <React.Fragment>
                      <VideoWrapper class="knowledgeVideos">
                        <VideoBg
                          // autoPlay
                          loop
                          controls
                          src={VideoDE}
                          type="video/mp4"
                        />
                      </VideoWrapper>
                    </React.Fragment>
                  </ImgWrap>
                );
              case "en":
                return (
                  <ImgWrap>
                    <React.Fragment>
                      <VideoWrapper class="knowledgeVideos">
                        <VideoBg
                          // autoPlay
                          loop
                          controls
                          src={VideoEN}
                          type="video/mp4"
                        />
                      </VideoWrapper>
                    </React.Fragment>
                  </ImgWrap>
                );

              default:
                return (
                  <ImgWrap>
                    <React.Fragment>
                      <VideoWrapper class="knowledgeVideos">
                        <VideoBg
                          // autoPlay
                          loop
                          muted
                          controls
                          src={VideoDE}
                          type="video/mp4"
                        />
                      </VideoWrapper>
                    </React.Fragment>
                  </ImgWrap>
                );
            }
          })()}

          <BtnWrap>
            <Button
              to="dismissalProtectionProceedings"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              primary="true"
              dark="true"
              dark2="true"
            >
              {t("infoButton2")}
            </Button>
          </BtnWrap>
        </WikiWrapper>
      </WikiContainer>
      <WikiContainer
        lightBg={true}
        dark="false"
        primary="false"
        darkText={false}
        id="reasonsForDismissal"
      >
        <WikiWrapper>
          <TextWrapper>
            <TopLine>{topLine}</TopLine>
            <Heading lightText={false}>{t("wikiHeadline4")}</Heading>
            <Subtitle darkText={true}>
              <p>
                <Trans i18nKey="wikiDescription4"></Trans>
              </p>
            </Subtitle>
          </TextWrapper>
          {(() => {
            switch (lng) {
              case "de":
                return (
                  <ImgWrap>
                    <React.Fragment>
                      <Images
                        img={reasonsForDismissal1}
                        img2={reasonsForDismissal2}
                        alt={"reasonsForDismissalImg"}
                      />
                    </React.Fragment>
                  </ImgWrap>
                );
              case "en":
                return (
                  <ImgWrap>
                    <React.Fragment>
                      <Images
                        img={reasonsForDismissal1EN}
                        img2={reasonsForDismissal2EN}
                        alt={"reasonsForDismissalENImg"}
                      />
                    </React.Fragment>
                  </ImgWrap>
                );

              default:
                return (
                  <ImgWrap>
                    <React.Fragment>
                      <Images
                        img={reasonsForDismissal1}
                        img2={reasonsForDismissal2}
                        alt={"reasonsForDismissalImg"}
                      />
                    </React.Fragment>
                  </ImgWrap>
                );
            }
          })()}

          <BtnWrap>
            <Button
              to="dismissalProtectionProceedings"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              primary="false"
              dark="false"
              dark2="false"
            >
              {t("infoButton2")}
            </Button>
          </BtnWrap>
        </WikiWrapper>
      </WikiContainer>
      <WikiContainer
        lightBg={false}
        dark="true"
        primary="true"
        darkText={true}
        id="dismissalProcess"
      >
        <WikiWrapper>
          <TextWrapper>
            <TopLine>{topLine}</TopLine>
            <Heading lightText={true}>{t("wikiHeadline5")}</Heading>
            <Subtitle darkText={false}>
              <p>
                <Trans i18nKey="wikiDescription5"></Trans>
              </p>
            </Subtitle>
          </TextWrapper>
          {(() => {
            switch (lng) {
              case "de":
                return (
                  <ImgWrap>
                    <React.Fragment>
                      <Images
                        img={dismissalProcess}
                        alt={"dismissalProcessImg"}
                      />
                    </React.Fragment>
                  </ImgWrap>
                );
              case "en":
                return (
                  <ImgWrap>
                    <React.Fragment>
                      <Images
                        img={dismissalProcessEN}
                        alt={"dismissalProcessENImg"}
                      />
                    </React.Fragment>
                  </ImgWrap>
                );

              default:
                return (
                  <ImgWrap>
                    <React.Fragment>
                      <Images
                        img={dismissalProcess}
                        alt={"dismissalProcessImg"}
                      />
                    </React.Fragment>
                  </ImgWrap>
                );
            }
          })()}
          <BtnWrap>
            <Button
              to="dismissalProtectionProceedings"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              primary="true"
              dark="true"
              dark2="true"
            >
              {t("infoButton2")}
            </Button>
          </BtnWrap>
        </WikiWrapper>
      </WikiContainer>
    </>
  );
};

export default WikiSection;
