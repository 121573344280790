import severanceGuidance from "./components/SurveySection/images/SeveranceGuidance.jpg";
import abfindungBeratung from "./components/SurveySection/images/AbfindungBeratung.jpg";
import abfindungBerechnung from "./components/SurveySection/images/AbfindungBerechnung.jpg";
import calculation from "./components/SurveySection/images/calculation.png";
import lawyerAdvice from "./components/SurveySection/images/LawyerAdvice.jpg";
import person from "./components/SurveySection/images/person.png";
import pen from "./components/SurveySection/images/pen.png";
import table from "./components/SurveySection/images/table.png";
import finishLine from "./components/SurveySection/images/OCFinishLine.png";
import contactOC from "./components/SurveySection/images/contactOC.png";
import laptop from "./components/SurveySection/images/laptop.png";

export const surveyJson = {
  locale: "de",
  pages: [
    {
      name: "disclaimer",
      elements: [
        {
          type: "html",
          name: "introduction",
          title: "",
          html: "",
        },
        {
          type: "image",
          name: "calcImage",
          imageLink: {
            default: severanceGuidance,
            de: abfindungBeratung,
          },
          contentMode: "image",
          imageHeight: 200,
          imageWidth: 250,
          maxWidth: "5vw",
        },

        {
          type: "checkbox",
          name: "disclaimer",
          title: "Disclaimer",

          description: {
            de: "Das Abfindungsrechner-Tool („Tool“) wurde von der OC Solutions (OC Services GmbH) zu Informationszwecken erstellt. Es stellt keine individuelle rechtliche oder steuerliche Auskunft dar und ist nicht geeignet, eine individuelle Beratung durch fachkundige Personen zu ersetzen. User sollten nicht allein auf der Grundlage der Informationen des Tools handeln. Die Informationen stellen einen unverbindlichen kursorischen Überblick über die Rechtslage am 15. März 2023 anhand der vom User gewählten Optionen dar und dürfen ohne die vorherige schriftliche Zustimmung der OC Services GmbH von niemand anderem außer dem User herangezogen, zitiert oder anderweitig verwendet werden. Es besteht keine Verpflichtung zu einer Beibehaltung oder Aktualisierung des Tools. <br><br><b> Mir ist bekannt, dass die folgende Abfindungsberechnung nur eine erste Einschätzung gibt und der Betrag letztlich mit dem/der Arbeitnehmer*in ausgehandelt werden muss. Der Abfindungsrechner bietet dabei eine Einschätzungshilfe, stellt jedoch keine individuelle Rechtsberatung dar. Zudem gilt er nicht, sobald eine Abfindungsregelung mit dem Betriebsrat auszuhandeln ist.</b>",

            default:
              'The severance calculator tool ("Tool") has been developed by OC Solutions (OC Services GmbH) for information purposes only. This Tool does not constitute individual legal or tax advice and cannot replace individual guidance by competent professionals. Users should not act solely on the basis of the Tool\'s information. The information is a non-binding cursory overview of the legal situation on 15 March 2023 based on the options selected by the user and may not be referred to, quoted or otherwise used by anyone other than the user without the prior written consent of OC Services GmbH. There is no obligation to maintain or update the tool.<br><br><b>I understand that the following severance calculation is only an estimate and up to negotiations with the employee. The Tool supplies an initial assessment aid but does not constitute individual legal advice. Moreover, it does not apply as soon as a severance scheme has to be negotiated with the works council.</b>',
          },
          startWithNewLine: false,
          isRequired: true,
          requiredErrorText: {
            de: "Bitte akzeptieren Sie den Disclaimer, um fortzufahren.",
            default: "Please accept the disclaimer to continue.",
          },
          choices: [
            {
              value: "verstanden",
              text: {
                de: "Zustimmen <br><br><br>",
                default: "Accept <br><br><br>",
              },
            },
          ],
        },
      ],
      title: "",
    },

    {
      name: "details",
      elements: [
        {
          type: "text",
          name: "annualGrossAmount",
          title: {
            default: "Please type in the annual fix salary.",
            de: "Bitte geben Sie das Jahresfixgehalt ein.",
          },
          description: {
            default: "Annual gross salary in EUR ",
            de: "Jahresfixgehalt in EUR (brutto) ",
          },
          isRequired: true,
          requiredErrorText: {
            default: "*Response required.",
            de: "*Bitte beantworten Sie diese Frage.",
          },
          inputType: "number",
          min: "1",
          minErrorText: {
            default: "Please enter a number.",
            de: "Bitte geben Sie einen Wert an.",
          },
          step: 0,
        },
        {
          type: "text",
          name: "variablePayments",
          startWithNewLine: false,
          title: {
            default: "Variable payments, if any.",
            de: "Variable Bezüge, falls vorhanden.",
          },
          description: {
            default:
              "Achievable annual variable or average annual variable of the last three years if higher",
            de: "Jährlicher Zielbetrag oder Durchschnitt der Zahlungen der letzten 3 Jahre falls höher.",
          },
          inputType: "number",
          min: "0",
        },
        {
          type: "panel",
          name: "panelEmploymentDuration",
          elements: [
            {
              type: "text",
              name: "startDate",
              title: {
                default: "Start date of employment",
                de: "Datum des Beschäftigungsbeginns ",
              },
              description: {
                default:
                  "Eventually taking a prior start date into account if recognition is compulsory, i. e. in case of transfer of undertaking from a previous employer",
                de: "Eventuell unter Berücksichtigung eines früheren Anfangsdatums, wenn die Anerkennung obligatorisch ist, d. h. im Falle eines Betriebsübergangs von einem früheren Arbeitgeber",
              },
              isRequired: true,
              requiredErrorText: {
                default: "*Response required.",
                de: "*Bitte beantworten Sie diese Frage.",
              },
              inputType: "date",
              max: "=today()",
            },
            {
              type: "radiogroup",
              name: "anticpatedEndDateYN",
              startWithNewLine: false,
              title: {
                de: "Beschäftigungsdauer bis zum voraussichtlichen Ende der Beschäftigung berechnen?",
                default:
                  "Calculate duration of employment until anticipated end of employment?",
              },
              description: {
                de: "Ansonsten Beschäftigungsdauer bis heute",
                default: "Otherwise calculated until today",
              },
              defaultValue: "yes",
              choices: [
                {
                  value: "yes",
                  text: {
                    de: "Ja",
                    default: "Yes",
                  },
                },
                {
                  value: "no",
                  text: {
                    default: "No",
                    de: "Nein",
                  },
                },
              ],
            },
            {
              type: "text",
              name: "anticipatedEndDate",
              visibleIf: "{anticpatedEndDateYN} = 'yes'",
              title: {
                default: "Anticipated end of employment",
                de: "Voraussichtliches Ende der Beschäftigung",
              },
              requiredErrorText: {
                default: "*Response required.",
                de: "*Bitte beantworten Sie diese Frage.",
              },
              inputType: "date",
            },
            {
              type: "expression",
              name: "duration",
              visible: false,
              visibleIf:
                "{startDate} notempty and {anticpatedEndDateYN} = 'no' or {startDate} notempty and {anticpatedEndDateYN} = 'yes' and {anticipatedEndDate} notempty",
              // visibleIf:
              //   "{startDate} > '1900-01-01' and {anticpatedEndDateYN} = 'no' or {startDate} > '1900-01-01' and {anticpatedEndDateYN} = 'yes' and {anticipatedEndDate} notempty",
              // visibleIf: "{startDate} > '1900-01-01'",
              startWithNewLine: false,
              title: {
                default:
                  "Duration of employment (calculated until today if no end date is indicated)",
                de: "Dauer der Beschäftigung (bis heute, wenn Endtermin nicht angegeben)",
              },
              description: {
                default: "Decimal number taking months into account.",
                de: "Dezimalzahl unter Berücksichtigung der Monate.",
              },
              expression: "diffDates({startDate}, {anticipatedEndDate})",
              maximumFractionDigits: 2,
            },
          ],
        },
        {
          type: "panel",
          name: "specialCases",
          elements: [
            {
              type: "html",
              name: "specialCasesDescription",
              html: {
                de: "Liegt einer der folgenden  Sonderfälle vor? Wenn kein Sonderfall vorliegt, wird die Berechnung auf Basis der üblichen <a class='ruleOfThumb' href=https://severance-calculator.osborneclarke.com/knowledge#severanceNegotiation target='_blank'>“Faustformel”</a> durchgeführt. Falls doch, werden die besonderen Gegebenheiten bei der Empfehlung zum weiteren Vorgehen berücksichtigt.",
                default:
                  "Do any of the following special cases apply? If not, the calculation will return the standard <a class='ruleOfThumb' href=https://severance-calculator.osborneclarke.com/knowledge#severanceNegotiation target='_blank'>“rule of thumb”</a> amount. If yes, the special situation will be taken into account for the recommendation for further proceeding.",
              },
            },
            {
              type: "panel",
              name: "panel14",
              elements: [
                {
                  type: "checkbox",
                  name: "employee50",
                  title: {
                    default: "Employee 50+",
                    de: "Arbeitnehmer*in 50+",
                  },

                  description: {
                    default:
                      "Employee is older than 50 years but not yet near pension age.",
                    de: "Arbeitnehmer*in ist älter als 50 Jahre, aber noch nicht rentennah.",
                  },
                  choices: [
                    {
                      value: "apply",
                      text: {
                        default: "applicable",
                        de: "zutreffend",
                      },
                    },
                  ],
                },
                {
                  type: "checkbox",
                  name: "noReason",
                  title: {
                    default: "Effective grounds for termination uncertain?",
                    de: "Wirksamer Kündigungsgrund unsicher",
                  },
                  description: {
                    default:
                      "We have doubts whether our reasons for termination will be recognised as valid reasons in the sense of the Dismissal Protection Act (= increased risk of litigation).",
                    de: "Wir haben Zweifel, ob unsere Kündigungsgründe als berechtigte Gründe im Sinne des Kündigungsschutzgesetzes anerkannt werden (= erhöhtes Prozessrisiko).",
                  },
                  enableIf: "{extraordinaryDismissal} <> ['apply']",
                  validators: [
                    {
                      type: "expression",
                    },
                  ],
                  choices: [
                    {
                      value: "apply",
                      text: {
                        default: "applicable",
                        de: "zutreffend",
                      },
                    },
                  ],
                },

                {
                  type: "checkbox",
                  name: "handicapped",
                  title: {
                    default: "Severely handicapped or equivalent",
                    de: "Schwerbehindert oder gleichgestellt",
                  },
                  description: {
                    default: "Employee is severely handicapped or equivalent. ",
                    de: "Arbeitnehmer*in ist schwerbehindert oder gleichgestellt. ",
                  },
                  choices: [
                    {
                      value: "apply",
                      text: {
                        default: "applicable",
                        de: "zutreffend",
                      },
                    },
                  ],
                },
                {
                  type: "checkbox",
                  name: "maternityParentalLeave",
                  title: {
                    default: "Maternity or parental leave/ parental part-time",
                    de: "Mutterschutz, Elternzeit oder Elternteilzeit",
                  },
                  description: {
                    default:
                      "Employee is on maternity or parental leave or works parental part-time",
                    de: "Arbeitnehmer*in befindet sich in Mutterschutz, Elternzeit oder Elternteilzeit",
                  },
                  choices: [
                    {
                      value: "apply",
                      text: {
                        default: "apply",
                        de: "zutreffend",
                      },
                    },
                  ],
                },
                {
                  type: "checkbox",
                  name: "employeeRepresentativeBody",
                  title: {
                    default: "Employee representative body",
                    de: "Arbeitnehmervertretung",
                  },
                  description: {
                    default:
                      "Employee is part of an employee representative body (after the end of a mandate ongoing protection for up to 12 months).",
                    de: "Arbeitnehmer*in ist Mitglied einer Arbeitnehmervertretung (nach Ende eines Mandats noch bis zu 12 Monate nachwirkender Schutz).",
                  },
                  choices: [
                    {
                      value: "apply",
                      text: {
                        default: "applicable",
                        de: "zutreffend",
                      },
                    },
                  ],
                },
                {
                  type: "checkbox",
                  name: "dataProtectionOfficer",
                  title: {
                    default: "Data protection officer",
                    de: "Datenschutzbeauftragter",
                  },
                  description: {
                    default:
                      "Employee is the data protection officer of the company (or had this role within the last 12 months).",
                    de: "Arbeitnehmer*in ist Datenschutzbeauftragter des Unternehmens (oder war dies binnen der letzten 12 Monate).",
                  },
                  choices: [
                    {
                      value: "apply",
                      text: {
                        default: "applicable",
                        de: "zutreffend",
                      },
                    },
                  ],
                },
              ],
              title: {
                default: "Arguments in favour <br>of the employee",
                de: "Günstige Argumente <br>für den Arbeitnehmer",
              },
            },
            {
              type: "panel",
              name: "panel15",
              elements: [
                {
                  type: "checkbox",
                  name: "lessThan6",
                  visibleIf: "{duration} <= '0.499999'",
                  title: {
                    default: "The employee is employed less than 6 months.",
                    de: "Probezeit/Wartezeit",
                  },
                  description: {
                    default: "Waiting period for dismissal protection.",
                    de: "Der/Die Arbeitnehmer*in ist weniger als 6 Monate beschäftigt (Wartezeit nach dem Kündigungsschutzgesetz)",
                  },
                  enableIf: "{duration} <= '0.499999'",
                  defaultValue: ["true"],
                  requiredIf: "{duration} <= '0.499999'",
                  validators: [
                    {
                      type: "expression",
                      text: {
                        default: "Please check the employment start date.",
                        de: "Bitte überprüfen Sie das Datum des Beschäftigungsbeginns.",
                      },
                      expression:
                        "{duration} < '0.4999' and {lessThan6} = [true] or {duration} > '0.499999' and {lessThan6} <> [true]",
                    },
                  ],
                  choices: [
                    {
                      value: "true",
                      text: {
                        default: "applicable",
                        de: "zutreffend",
                      },
                    },
                  ],
                  choicesVisibleIf: "{duration} <= '0.499999'",
                  choicesEnableIf: "{duration} <= '0.499999'",
                },
                {
                  type: "checkbox",
                  name: "noMoreThan10Empl",
                  popupknowledge: {
                    default:
                      "As an exception, if more than five employees have been employed already before 31 December 2003, the Dismissal Protection Act applies for them nevertheless. Part-time employees working up to 20 hours per week count as 0.5, those working up to 30 hours per week count as 0.75 for the calculation of the headcount.",
                    de: "*Ausnahmsweise gilt das Kündigungsschutzgesetz auch dann, wenn bereits vor dem 31. Dezember 2003 mehr als fünf Arbeitnehmer*innen beschäftigt wurden. Teilzeitbeschäftigte mit bis zu 20 Wochenstunden zählen mit 0,5, solche mit bis zu 30 Wochenstunden mit 0,75 für die Berechnung der Kopfzahl.",
                  },
                  title: {
                    default:
                      "The operation (Betrieb) has no more than 10 employees",
                    de: "Kleinbetrieb",
                  },
                  description: {
                    default:
                      "Hence it will be considered as small establishment where no justifying reason for dismissal according to the Dismissal Protection Act (Kündigungsschutzgesetz) is required.",
                    de: "Der Betrieb hat nicht mehr als 10 Arbeitnehmer*innen (daher gilt er als Kleinbetrieb, in dem kein rechtfertigender Grund für eine Kündigung nach dem Kündigungsschutzgesetz erforderlich ist).",
                  },
                  choices: [
                    {
                      value: "apply",
                      text: {
                        default: "applicable",
                        de: "zutreffend",
                      },
                    },
                  ],
                },
                {
                  type: "checkbox",
                  name: "extraordinaryDismissal",
                  title: {
                    default: "Reasons for an extraordinary dismissal.",
                    de: "Außerordentliche Kündigung ",
                  },
                  description: {
                    default:
                      "We have reasons for an extraordinary dismissal without notice due to a severe breach of contract by the employee.",
                    de: "Wir haben Gründe für eine außerordentliche fristlose Kündigung wegen einer schweren Vertragsverletzung.",
                  },
                  enableIf: "{noReason} <> ['apply']",
                  choices: [
                    {
                      value: "apply",
                      text: {
                        default: "applicable",
                        de: "zutreffend",
                      },
                    },
                  ],
                },
                {
                  type: "checkbox",
                  name: "formalPriorWarnings",
                  title: {
                    default: "Formal prior warnings.",
                    de: "Abmahnung",
                  },
                  description: {
                    default: "We gave the employee formal prior warnings.",
                    de: "Wir haben vorher bereits Abmahnungen ausgesprochen",
                  },
                  choices: [
                    {
                      value: "apply",
                      text: {
                        default: "applicable",
                        de: "zutreffend",
                      },
                    },
                  ],
                },
              ],
              title: {
                default: "Arguments in favour <br>of the employer",
                de: "Günstige Argumente <br>für den Arbeitgeber",
              },
              startWithNewLine: false,
            },
          ],
          title: {
            default: "Special cases",
            de: "Sonderfälle",
          },

          showNumber: true,
          showQuestionNumbers: "off",
          questionStartIndex: "'a'",
        },
      ],
    },
    {
      name: "Standardfall/50+",
      elements: [
        {
          type: "panel",
          name: "panelSeekLawyerAdviceNoReason",
          elements: [
            {
              type: "image",
              name: "lawyerAdviceNoReasonImg",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: lawyerAdvice,
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "lawyerAdviceNoReason",

              minWidth: "200px",
              startWithNewLine: false,
              html: {
                default:
                  "Based on the information you entered, there are doubts whether the reasons for termination will be recognised as valid operational, person-related or behaviour-related reasons in the sense of the Dismissal Protection Act. In order to better assess the ligitation risks, we highly recommend to seek lawyer advice.",
                de: "Aufgrund der von Ihnen gemachten Angaben bestehen Zweifel, ob die Kündigungsgründe als ausreichende betriebliche, personenbezogene oder verhaltensbedingte Gründe im Sinne des Kündigungsschutzgesetzes anerkannt würden. Zur besseren Einschätzung der Prozessrisiken, raten wir Ihnen dringend, sich anwaltlich beraten zu lassen.",
              },
            },
          ],
          title: {
            de: "Anwaltliche Beratung empfohlen",
            default: "Seek lawyer advice",
          },
          visibleIf:
            "{noReason} = ['apply'] and {formalPriorWarnings} <> ['apply'] or {noReason} = ['apply'] and {formalPriorWarnings} = ['apply'] or {noReason} = ['apply'] and {employee50} = ['apply']",
        },
        {
          type: "panel",
          name: "panel11",
          elements: [
            {
              type: "image",
              name: "Calculation image",
              minWidth: "50px",
              maxWidth: "250px",
              imageHeight: 200,
              startWithNewLine: false,
              imageLink: calculation,
              // imageWidth: 250,
              //maxWidth: "5vw",
              //  imageHeight: 70,
              //  imageWidth: 120,
            },
            {
              type: "html",
              name: "variante1amountText",
              minWidth: "50px",
              startWithNewLine: false,
              html: {
                default:
                  "<br><br><br>Based on the data you entered and considering the <b> overall remuneration</b>, the calculated severance amount in line with the explained <a class='ruleOfThumb' href=https://severance-calculator.osborneclarke.com/knowledge#severanceNegotiation target='_blank'>“rule of thumb”</a> is:",
                de: "<br><br><br>Auf der Grundlage der von Ihnen eingegebenen Daten und unter Berücksichtigung der  <b> Gesamtvergütung</b>, errechnet sich nach der erläuterten <a class='ruleOfThumb' href=https://severance-calculator.osborneclarke.com/knowledge#severanceNegotiation target='_blank'>“Faustformel”</a> ein Abfindungsbetrag in Höhe von: \n<br>\n",
              },
            },
            {
              type: "expression",
              name: "resultVariante1",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "(EUR gross)",
                de: "(EUR brutto)",
              },
              description: {
                default:
                  "(Formula: Monthly earnings x duration of employment x severance factor 0.5)",
                de: "(Formel: Monatsverdienst x Dauer der Beschäftigung x Abfindungsfaktor 0,5)",
              },
              valueName: "calcResultVariante1",
              expression:
                "(({annualGrossAmount}+{variablePayments})/12)*{duration}*0.5",
              displayStyle: "currency",
              currency: "EUR",
            },
          ],
          visibleIf:
            "{formalPriorWarnings} <> ['apply'] or {noReason} = ['apply'] and {formalPriorWarnings} = ['apply']",
          title: {
            default: "Severance Amount based on <u><b>Rule of thumb</u></b>",
            de: "Abfindungsbetrag nach der <u><b>Faustformel</u></b>",
          },
        },
        {
          type: "panel",
          name: "corridor",
          elements: [
            {
              type: "image",
              name: "imageNegotiationCorridor",
              minWidth: "50px",
              maxWidth: "200px",
              startWithNewLine: false,
              imageLink: {
                default: severanceGuidance,
                de: abfindungBerechnung,
              },
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "question33",
              minWidth: "50px",
              startWithNewLine: false,
              html: {
                default:
                  "<br><br><br>It seems likely that a severance in the following corridor would <br>be a reasonable basis for a termination agreement:",
                de: "<br><br><br>Es ist davon auszugehen, dass eine Abfindung im folgenden Rahmen eine angemessene Grundlage für einen Aufhebungsvertrag darstellt:",
              },
            },
            {
              type: "expression",
              name: "variante1from",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "from",
                de: "von",
              },
              description: {
                default: "(Minimum = factor 0.5 based on the fix salary only)",
                de: "(Minimum = Faktor 0,5 nur auf der Grundlage des Festgehalts)",
              },
              valueName: "calcVariante1from",
              expression: "(({annualGrossAmount})/12)*{duration}*0.5",
              displayStyle: "currency",
              currency: "EUR",
            },
            {
              type: "expression",
              name: "variante1to",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "to",
                de: "bis",
              },
              description: {
                default: "(Maximum = factor 0.7 based on overall remuneration)",
                de: "(Maximum = Faktor 0,7 auf Grundlage der Gesamtvergütung)",
              },
              valueName: "calcVariante1to",
              expression:
                "((({annualGrossAmount}+{variablePayments})/12)*{duration})*0.7",
              displayStyle: "currency",
              currency: "EUR",
            },
          ],
          visibleIf:
            "{employee50} <> ['apply'] and {noReason} <> ['apply'] and {formalPriorWarnings} <> ['apply']",
          title: {
            de: "Empfohlener Verhandlungsrahmen",
            default: "Negotiation Corridor",
          },
        },
        {
          type: "panel",
          name: "panel_50+",
          elements: [
            {
              type: "html",
              name: "question52",
              minWidth: "110px",
              maxWidth: "350px",
              html: {
                default: "<h3>Negotiation Corridor</h3>",
                de: "<h3>Empfohlener Verhandlungsrahmen</h3>",
              },
            },
            {
              type: "image",
              name: "imageEmployee50+",
              minWidth: "50px",
              maxWidth: "250px",
              imageLink: {
                default: severanceGuidance,
                de: abfindungBerechnung,
              },
              text: {
                default: "Negotiation Corridor",
                de: "Empfohlener Verhandlungsrahmen",
              },
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "factorEmp50+",
              minWidth: "50px",
              startWithNewLine: false,
              html: {
                default:
                  "<br><br><br>For employees 50+ some courts suggest a higher severance factor of 0.75. Hence, it seems likely that a severance in the following corridor would be a reasonable basis for a termination agreement:",
                de: "<br><br><br>Für Arbeitnehmer*innen 50+ empfehlen einige Gerichte einen höheren Abfindungsfaktor von 0,75. Es erscheint daher wahrscheinlich, dass eine Abfindung im folgenden Rahmen eine vertretbare Grundlage für einen Aufhebungsvertrag darstellt:",
              },
            },
            {
              type: "expression",
              name: "from50noReasonNotApplicable",
              visibleIf: "{noReason} <> ['apply']",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "from",
                de: "von",
              },
              description: {
                default: "(Minimum = factor 0.5 based on the fix salary only)",
                de: "(Minimum = Faktor 0,5 nur auf der Grundlage des Festgehalts)",
              },
              valueName: "calcFrom50noReasonNotApplicable",
              expression: "(({annualGrossAmount})/12)*{duration}*0.5",
              displayStyle: "currency",
              currency: "EUR",
            },
            {
              type: "expression",
              name: "to50noReasonNotApplicable",
              visibleIf: "{noReason} <> ['apply']",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "to",
                de: "bis",
              },
              description: {
                default: "(Maximum = factor 1.0 based on overall remuneration)",
                de: "(Maximum = Faktor 1,0 auf der Grundlage der Gesamtvergütung)",
              },
              valueName: "calcTo50noReasonNotApplicable",
              expression:
                "((({annualGrossAmount}+{variablePayments})/12)*{duration})*1",
              displayStyle: "currency",
              currency: "EUR",
            },
          ],
          visibleIf: "{employee50} = ['apply'] and {noReason} <> ['apply']",
          title: {
            default: "Employee older 50 years",
            de: "Arbeitnehmer*innen 50+",
          },
        },
        {
          type: "panel",
          name: "panel6",
          elements: [
            {
              type: "html",
              name: "negCorEmp50+NoReason",
              html: {
                default: "<h3>Negotiation Corridor</h3>",
                de: "<h3>Empfohlener Verhandlungsrahmen</h3>",
              },
            },
            {
              type: "image",
              name: "image50+NoReason",
              minWidth: "50px",
              maxWidth: "250px",
              imageLink: {
                default: severanceGuidance,
                de: abfindungBerechnung,
              },
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "empl50+&noReason",
              minWidth: "50px",
              startWithNewLine: false,
              html: {
                default:
                  "<br><br><br>For employees 50+ some courts suggest a higher severance factor of 0.75. However, as according to your assessment there are doubts whether the reasons for termination will be recognised as valid reasons in the sense of the Dismissal Protection Act (= increased risk of litigation), the employee has good chances to be successful at court and obtain reinstatement. Therefore, a higher severance factor is probably needed to obtain a settlement, if the employee is ready to terminate the employment at all. A severance in the following corridor would be a reasonable basis for a termination agreement in such case:",
                de: "<br><br><br>Für Arbeitnehmer*innen 50+ empfehlen einige Gerichte einen höheren Abfindungsfaktor von 0,75. Da nach Ihrer Einschätzung Zweifel bestehen, ob die Kündigungsgründe als ausreichende Gründe im Sinne des Kündigungsschutzgesetzes anerkannt würden (= erhöhtes Prozessrisiko), hat der/die Arbeitnehmer*in gute Chancen, vor Gericht erfolgreich zu sein und eine Weiterbeschäftigung zu erreichen. Daher ist wahrscheinlich ein höherer Abfindungsfaktor erforderlich, um eine einvernehmliche Beendigung des Arbeitsverhältnisses zu erreichen, wenn der/die Arbeitnehmer*in überhaupt bereit ist, das Arbeitsverhältnis zu beenden. Eine Abfindung in folgendem Rahmen wäre in einem solchen Fall regelmäßig eine angemessene Basis für eine Aufhebungsvereinbarung:",
              },
            },
            {
              type: "expression",
              name: "from50+WithNoReason",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "from",
                de: "von",
              },
              description: {
                default: "(Minimum = factor 0.75 based on the fix salary only)",
                de: "(Minimum = Faktor 0,75 nur auf der Grundlage des Festgehalts)",
              },
              valueName: "calcFrom50WithNoReason",
              expression: "(({annualGrossAmount})/12)*{duration}*0.75",
              displayStyle: "currency",
              currency: "EUR",
            },
            {
              type: "expression",
              name: "to50+WithNoReason",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "to",
                de: "bis",
              },
              description: {
                default: "(Maximum = factor 1.5 based on overall remuneration)",
                de: "(Maximum = Faktor 1,5 auf der Grundlage der Gesamtvergütung)",
              },
              valueName: "calcTo50WithNoReason",
              expression:
                "((({annualGrossAmount}+{variablePayments})/12)*{duration})*1.5",
              displayStyle: "currency",
              currency: "EUR",
            },
          ],
          visibleIf: "{employee50} = ['apply'] and {noReason} = ['apply']",
          title: {
            default:
              "Employee older 50 years & effective grounds for termination uncertain",
            de: "Arbeitnehmer*innen 50+ & wirksamer Kündigungsgrund unsicher",
          },
        },
        {
          type: "panel",
          name: "panel_NoReasonsForDismissal",
          elements: [
            {
              type: "image",
              name: "imageNoReason",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: {
                default: severanceGuidance,
                de: abfindungBeratung,
              },
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "noReasonNoWarning",
              minWidth: "50px",
              startWithNewLine: false,
              html: {
                default:
                  "<br><br><br>However, as according to your assessment there are doubts whether the reasons for termination will be recognised as valid reasons in the sense of the Dismissal Protection Act (= increased risk of litigation), the employee has good chances to be successful at court and obtain reinstatement. Therefore, a higher severance factor is probably needed to obtain a settlement, if the employee is ready to terminate the employment at all. A severance in the following corridor would usually be a reasonable basis for a termination agreement in such case:",
                de: "<br><br><br>Da nach Ihrer Einschätzung Zweifel bestehen, ob die Kündigungsgründe als ausreichende Gründe im Sinne des Kündigungsschutzgesetzes anerkannt würden (= erhöhtes Prozessrisiko), hat der/die Arbeitnehmer*in gute Chancen, vor Gericht erfolgreich zu sein und eine Weiterbeschäftigung zu erreichen. Daher ist wahrscheinlich ein höherer Abfindungsfaktor erforderlich, um eine einvernehmliche Beendigung des Arbeitsverhältnisses zu erreichen, wenn der/die Arbeitnehmer*in überhaupt bereit ist, das Arbeitsverhältnis zu beenden. Eine Abfindung in folgendem Rahmen wäre in einem solchen Fall regelmäßig eine angemessene Basis für eine Aufhebungsvereinbarung:",
              },
            },
            {
              type: "expression",
              name: "minimum075NoReason",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "from",
                de: "von",
              },
              description: {
                default: "(Minimum = factor 0.75 based on the fix salary only)",
                de: "(Minimum = Faktor 0,75 nur auf Grundlage des Festgehalts)",
              },
              valueName: "calcMinimum075NoReason",
              expression: "(({annualGrossAmount})/12)*{duration}*0.75",
              displayStyle: "currency",
              currency: "EUR",
            },
            {
              type: "expression",
              name: "maximum15NoReason",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "to",
                de: "bis",
              },
              description: {
                default:
                  "(Maximum = factor 1.5 based on the overall remuneration)",
                de: "(Maximum = Faktor 1,5 auf Grundlage der Gesamtvergütung)",
              },
              valueName: "calcMaximum15NoReason",
              expression:
                "((({annualGrossAmount}+{variablePayments})/12)*{duration})*1.5",
              displayStyle: "currency",
              currency: "EUR",
            },
            {
              type: "expression",
              name: "shortemploymentrelationships",
              visibleIf:
                "{noReason} = ['apply'] and ({calcMaximum15NoReason} < ({annualGrossAmount}/12*3))",
              title: {
                default: "Short employment relationships",
                de: "Kurze Arbeitsverhältnisse",
              },
              description: {
                default:
                  "For short employment relationships, a severance in the amount of at least three monthly salaries might be required which would lead to a higher amount here: ",
                de: "Bei kurzen Arbeitsverhältnissen kann eine Abfindung in Höhe von mindestens drei Monatsgehältern erforderlich werden, was hier zu einem höheren Betrag führen würde: ",
              },
              valueName: "calcShortEmployment",
              expression: "({annualGrossAmount}/12)*3",
              displayStyle: "currency",
              currency: "EUR",
            },
          ],
          visibleIf:
            "{noMoreThan10Empl} <> ['apply'] and {noReason} = ['apply'] and {employee50} <> ['apply'] and {formalPriorWarnings} <> ['apply']",
          title: {
            default: "Effective grounds for termination uncertain",
            de: "Wirksamer Kündigungsgrund unsicher",
          },
        },
        {
          type: "panel",
          name: "panelNoReasonWithWarning",
          elements: [
            {
              type: "image",
              name: "question24",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: {
                default: severanceGuidance,
                de: abfindungBeratung,
              },
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "noReasonWithWarning",
              minWidth: "50px",
              startWithNewLine: false,
              html: {
                default:
                  "<br><br><br>However, as according to your assessment there are doubts whether the reasons for termination will be recognised as valid reasons in the sense of the Dismissal Protection Act (= increased risk of litigation) despite formal prior warning(s), the employee has good chances to be successful at court and obtain reinstatement. Therefore, a higher severance factor is probably needed to obtain a settlement, if the employee is ready to terminate the employment at all. A severance in the following corridor would usually be a reasonable basis for a termination agreement in such case:",
                de: "<br><br><br>Da nach Ihrer Einschätzung Zweifel bestehen, ob die Kündigungsgründe trotz vorheriger Abmahnung(en) als ausreichende Gründe im Sinne des Kündigungsschutzgesetzes anerkannt würden (= erhöhtes Prozessrisiko), hat der/die Arbeitnehmer*in gute Chancen, vor Gericht erfolgreich zu sein und eine Weiterbeschäftigung zu erreichen. Daher ist wahrscheinlich ein höherer Abfindungsfaktor erforderlich, um eine einvernehmliche Beendigung des Arbeitsverhältnisses zu erreichen, wenn der/die Arbeitnehmer*in überhaupt bereit ist, das Arbeitsverhältnis zu beenden. Eine Abfindung in folgendem Rahmen wäre in einem solchen Fall regelmäßig eine angemessene Basis für eine Aufhebungsvereinbarung:",
              },
            },
            {
              type: "expression",
              name: "minNoReasonWarning",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "from",
                de: "von",
              },
              description: {
                default: "(Minimum = factor 0.75 based on the fix salary only)",
                de: "(Minimum = Faktor 0,75 nur auf Grundlage des Festgehalts)",
              },
              valueName: "calcMinNoReasonWarning",
              expression: "(({annualGrossAmount})/12)*{duration}*0.75",
              displayStyle: "currency",
              currency: "EUR",
            },
            {
              type: "expression",
              name: "maxNoReasonWarning",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "to",
                de: "bis",
              },
              description: {
                default:
                  "(Maximum = factor 1.5 based on the overall remuneration)",
                de: "(Maximum = Faktor 1,5 auf Grundlage der Gesamtvergütung)",
              },
              valueName: "calcMaxNoReasonWarning",
              expression:
                "((({annualGrossAmount}+{variablePayments})/12)*{duration})*1.5",
              displayStyle: "currency",
              currency: "EUR",
            },
            {
              type: "expression",
              name: "question43",
              visibleIf:
                "{noReason} = ['apply'] and ({maxNoReasonWarning} < ({annualGrossAmount}/12*3))",
              title: {
                default: "Short employment relationships",
                de: "Kurze Arbeitsverhältnisse",
              },
              description: {
                default:
                  "For short employment relationships, a severance in the amount of at least three monthly salaries might be required which would lead to a higher amount here: ",
                de: "Bei kurzen Arbeitsverhältnissen kann eine Abfindung in Höhe von mindestens drei Monatsgehältern erforderlich werden, was hier zu einem höheren Betrag führen würde: ",
              },
              expression: "({annualGrossAmount}/12)*3",
              displayStyle: "currency",
              currency: "EUR",
            },
          ],
          visibleIf:
            "{noMoreThan10Empl} <> ['apply'] and {noReason} = ['apply'] and {employee50} <> ['apply'] and {formalPriorWarnings} = ['apply']",
          title: {
            default: "Effective grounds for termination uncertain",
            de: "Wirksamer Kündigungsgrund unsicher",
          },
        },
        {
          type: "panel",
          name: "severanceManualCalc",
          elements: [
            {
              type: "text",
              name: "factorCalc",
              minWidth: "5vh",
              maxWidth: "40vw",
              startWithNewLine: false,
              title: {
                default: "Severance factor (e.g. 0.6)",
                de: 'Abfindungsfaktor (z. B. "0.6")',
              },
              inputType: "number",
              min: "0",
              max: "3",
              maxErrorText: {
                default: "The value should not be greater than {3}",
                de: "Der Wert darf nicht höher sein als 3.",
              },
            },
            {
              type: "boolean",
              name: "fixOrOverall",
              minWidth: "150px",
              maxWidth: "400px",
              startWithNewLine: false,
              title: {
                default: "Fix salary or overall remuneration?",
                de: "Fixgehalt oder Gesamtgehalt?",
              },
              defaultValue: "false",
              labelTrue: {
                default: "Fix salary",
                de: "Fixgehalt",
              },
              labelFalse: {
                default: "Overall remuneration",
                de: "Gesamtgehalt",
              },
              valueTrue: "fixSalary",
              valueFalse: "overallRemuneration",
            },
            {
              type: "expression",
              name: "severanceCalc",
              minWidth: "5vh",
              maxWidth: "40vw",
              startWithNewLine: false,
              title: {
                default: "Severance amount",
                de: "Abfindungsbetrag",
              },
              expression:
                "(({Fix/overall remuneration}*{duration})/12)*{factorCalc}",
              displayStyle: "currency",
              currency: "EUR",
            },
            {
              type: "expression",
              name: "Fix/overall remuneration",
              startWithNewLine: true,
              visible: false,
              minWidth: "100px",
              maxWidth: "250px",
              title: {
                de: "Fixgehalt/Gesamtgehalt",
              },
              expression:
                'iif({fixOrOverall} == "overallRemuneration", {annualGrossAmount}+{variablePayments}, {annualGrossAmount})',
              displayStyle: "currency",
              currency: "EUR",
            },
          ],
          visibleIf:
            "({formalPriorWarnings} <> ['apply'] or {noReason} = ['apply'] and {formalPriorWarnings} = ['apply']) or ({employee50} <> ['apply'] and {noReason} <> ['apply'] and {formalPriorWarnings} <> ['apply']) or ({employee50} = ['apply'] and {noReason} <> ['apply']) or ({employee50} = ['apply'] and {noReason} = ['apply']) or ({noMoreThan10Empl} <> ['apply'] and {noReason} = ['apply'] and {employee50} <> ['apply'] and {formalPriorWarnings} <> ['apply']) or ({noMoreThan10Empl} <> ['apply'] and {noReason} = ['apply'] and {employee50} <> ['apply'] and {formalPriorWarnings} = ['apply'])",
          title: {
            default: "Choose factor for the severance calculation",
            de: "Faktor für die Abfindungsberechnung selbst auswählen",
          },
        },
      ],
      visibleIf:
        "{extraordinaryDismissal} <> ['apply'] and {noMoreThan10Empl} <> ['apply'] and {lessThan6} <> [true] and {dataProtectionOfficer} <> ['apply'] and {employeeRepresentativeBody} <> ['apply'] and {handicapped} <> ['apply'] and {maternityParentalLeave} <> ['apply']",
    },
    {
      name: "Außerord. Kündigung",
      elements: [
        {
          type: "panel",
          name: "panel20",
          elements: [
            {
              type: "image",
              name: "question20",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: lawyerAdvice,
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "extraordinaryDismissalLawyerAdvice",
              visibleIf:
                "{extraordinaryDismissal} = ['apply'] or {extraordinaryDismissal} = ['apply'] and {formalPriorWarnings} = ['apply']",
              startWithNewLine: false,
              html: {
                default:
                  "Based on the information you entered, you see reasons for an extraordinary dismissal without notice due to a severe breach of contract by the employee. We highly recommend to <b>seek lawyer advice</b> to evaluate the situation, the legal requirements for dismissal and the possible next steps together. It may even be possible to reach a termination agreement only on the basis of the ordinary notice period, but without paying a severance.",
                de: "Aufgrund der von Ihnen gemachten Angaben sehen Sie Gründe für eine außerordentliche fristlose Kündigung aufgrund einer schwerwiegenden Vertragsverletzung. Wir empfehlen Ihnen dringend, sich <b>anwaltlich beraten zu lassen</b>, um die Situation, die rechtlichen Voraussetzungen für eine Kündigung und die möglichen nächsten Schritte gemeinsam zu bewerten. Unter Umständen ist es sogar möglich, eine Aufhebungsvereinbarung nur auf Basis der ordentlichen Kündigungsfrist, jedoch ohne Zahlung einer Abfindung zu erreichen.",
              },
            },
            {
              type: "html",
              name: "priorWarningLawyerAdvice",
              visibleIf:
                "{formalPriorWarnings} = ['apply'] and {extraordinaryDismissal} <> ['apply']",
              startWithNewLine: false,
              html: {
                default:
                  "Based on the information you have provided, you have already given the employee a warning, but you do not yet see sufficient grounds for extraordinary termination without notice due to a serious breach of contract. We highly recommend to <b>seek lawyer advice</b> to evaluate the situation, the legal requirements for dismissal and the possible next steps together. It may even be possible to obtain a termination settlement against payment of a smaller severance.",
                de: "Aufgrund der von Ihnen gemachten Angaben haben Sie den/die Arbeitnehmer*in bereits zuvor abgemahnt, sehen jedoch noch keine ausreichenden Gründe für eine außerordentliche fristlose Kündigung aufgrund einer schwerwiegenden Vertragsverletzung. Wir empfehlen Ihnen dringend, sich <b>anwaltlich beraten zu lassen</b>, um die Situation, die rechtlichen Voraussetzungen für eine Kündigung und die möglichen nächsten Schritte gemeinsam zu bewerten. Unter Umständen ist es sogar möglich, eine Aufhebungsvereinbarung gegen Zahlung einer geringeren Abfindung zu erreichen.",
              },
            },
          ],
          title: {
            de: "Anwaltliche Beratung empfohlen",
            default: "Seek lawyer advice",
          },
          visibleIf:
            "{extraordinaryDismissal} = ['apply'] and {dataProtectionOfficer} <> ['apply'] and {handicapped} <> ['apply'] and {maternityParentalLeave} <> ['apply'] and {employeeRepresentativeBody} <> ['apply'] or {formalPriorWarnings} = ['apply'] and {dataProtectionOfficer} <> ['apply'] and {handicapped} <> ['apply'] and {maternityParentalLeave} <> ['apply'] and {employeeRepresentativeBody} <> ['apply']",
        },
        {
          type: "panel",
          name: "panel7",
          elements: [
            {
              type: "image",
              name: "question59",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: calculation,
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "variante2amountText",
              minWidth: "50px",
              startWithNewLine: false,
              html: {
                default:
                  "<br><br><br>Nevertheless, for your information, we calculated the severance amount in line with the explained <a class='ruleOfThumb' href=https://severance-calculator.osborneclarke.com/knowledge#severanceNegotiation target='_blank'>“rule of thumb”</a>. Based on the data you entered and considering <b><u>the overall remuneration</b></u>, it would be:",
                de: "<br><br><br>Dennoch haben wir zu Ihrer Information den Abfindungsbetrag nach der erläuterten <a class='ruleOfThumb' href=https://severance-calculator.osborneclarke.com/knowledge#severanceNegotiation target='_blank'>“Faustformel”</a> berechnet. Auf der Grundlage der von Ihnen eingegebenen Daten und unter Berücksichtigung der Gesamtvergütung ergibt sich ein Betrag von\n",
              },
            },
            {
              type: "expression",
              name: "resultVariante2",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "(EUR gross)",
                de: "(EUR brutto)",
              },
              description: {
                default:
                  "(Formula: Monthly earnings x duration of employment x severance factor 0.5)",
                de: "(Formel: Monatsverdienst x Dauer der Beschäftigung x Abfindungsfaktor 0,5)",
              },
              valueName: "amountExtDisOrWarn",
              expression:
                "(({annualGrossAmount}+{variablePayments})/12)*{duration}*0.5",
              displayStyle: "currency",
              currency: "EUR",
            },
          ],
          title: {
            default: "Severance Amount based on <u><b>Rule of thumb</u></b>",
            de: "Abfindungsbetrag nach der <u><b>Faustformel</u></b>",
          },
        },
        {
          type: "panel",
          name: "panel8",
          elements: [
            {
              type: "image",
              name: "imageExtraordinaryDismissal",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: {
                default: severanceGuidance,
                de: abfindungBerechnung,
              },
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "question18",
              minWidth: "50px",
              startWithNewLine: false,
              html: {
                default:
                  "<br><br><br>Depending on the strength of the reasons for dismissal, it seems likely that a severance in the following corridor would be a reasonable basis for a termination agreement:",
                de: "<br><br><br>Je nach Gewicht der Kündigungsgründe dürfte eine Abfindung im folgenden Rahmen eine angemessene Grundlage für einen Aufhebungsvertrag sein:",
              },
            },
            {
              type: "expression",
              name: "variante2from",
              visibleIf:
                "{formalPriorWarnings} = ['apply'] and {extraordinaryDismissal} <> ['apply']",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "from",
                de: "von",
              },
              description: {
                default: "(Minimum = factor 0.25 based on the fix salary only)",
                de: "(Minimum = Faktor 0,25 nur auf der Grundlage des Festgehalts)",
              },
              valueName: "calcVariante2from",
              expression: "(({annualGrossAmount})/12)*{duration}*0.25",
              displayStyle: "currency",
              currency: "EUR",
            },
            {
              type: "expression",
              name: "negCorExtraordDis",
              visibleIf:
                "{extraordinaryDismissal} = ['apply'] or {extraordinaryDismissal} = ['apply'] and {formalPriorWarnings} = ['apply']",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "from",
                de: "von",
              },
              description: {
                default: "No severance",
                de: "Keine Abfindung",
              },
              valueName: "calcnegCorExtraordDis",
              expression: "(({annualGrossAmount})/12)*{duration}*0.2*0",
              displayStyle: "currency",
              currency: "EUR",
            },
            {
              type: "expression",
              name: "variante2to035",
              visibleIf: "{extraordinaryDismissal} = ['apply']",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "to",
                de: "bis",
              },
              description: {
                default:
                  "(Maximum = factor 0.35 based on overall remuneration)",
                de: "(Maximum = Faktor 0,35 auf Grundlage der Gesamtvergütung)",
              },
              valueName: "calcVariante2to035",
              expression:
                "((({annualGrossAmount}+{variablePayments})/12)*{duration})*0.35",
              displayStyle: "currency",
              currency: "EUR",
            },
            {
              type: "expression",
              name: "variante2to07",
              visibleIf:
                "{formalPriorWarnings} = ['apply'] and {extraordinaryDismissal} <> ['apply']",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "to",
                de: "bis",
              },
              description: {
                default: "(Maximum = factor 0.7 based on overall remuneration)",
                de: "(Maximum = Faktor 0,7 auf Grundlage der Gesamtvergütung)",
              },
              valueName: "calcvariante2to07",
              expression:
                "((({annualGrossAmount}+{variablePayments})/12)*{duration})*0.7",
              displayStyle: "currency",
              currency: "EUR",
            },
          ],
          visibleIf: "{employee50} <> ['apply']",
          title: {
            de: "Empfohlener Verhandlungsrahmen",
            default: "Negotiation Corridor",
          },
        },
        {
          type: "panel",
          name: "panelManualCalcExtraDis",
          elements: [
            {
              type: "text",
              name: "factor06",
              minWidth: "5vh",
              maxWidth: "40vw",
              startWithNewLine: false,
              title: {
                default: "Severance factor (e.g. 0.6)",
                de: 'Abfindungsfaktor (z. B. "0.6")',
              },
              inputType: "number",
              min: "0",
              max: "3",
              maxErrorText: {
                default: "The value should not be greater than {3}",
                de: "Der Wert darf nicht höher sein als 3.",
              },
            },
            {
              type: "boolean",
              name: "overallRemOrFixSalary",
              minWidth: "150px",
              maxWidth: "400px",
              startWithNewLine: false,
              title: {
                de: "Fixgehalt oder Gesamtgehalt?",
                default: "Fix salary or overall remuneration?",
              },
              defaultValue: "false",
              labelTrue: {
                default: "Fix salary",
                de: "Fixgehalt",
              },
              labelFalse: {
                default: "Overall remuneration",
                de: "Gesamtgehalt",
              },
              valueTrue: "fixSalary",
              valueFalse: "overallRemuneration",
            },
            {
              type: "expression",
              name: "question48",
              minWidth: "5vh",
              maxWidth: "40vw",
              startWithNewLine: false,
              title: {
                default: "Severance amount",
                de: "Abfindungsbetrag",
              },
              expression: "(({question55}*{duration})/12)*{factor06}",
              displayStyle: "currency",
              currency: "EUR",
            },
            {
              type: "expression",
              name: "question55",
              visible: false,
              minWidth: "200px",
              maxWidth: "250px",
              title: {
                de: "Fixgehalt/Gesamtgehalt",
              },
              expression:
                'iif({overallRemOrFixSalary} == "overallRemuneration", {annualGrossAmount}+{variablePayments}, {annualGrossAmount})',
              displayStyle: "currency",
              currency: "EUR",
            },
          ],
          title: {
            default: "Choose factor for the severance calculation",
            de: "Faktor für die Abfindungsberechnung selbst auswählen",
          },
        },
      ],
      visibleIf:
        "{extraordinaryDismissal} = ['apply'] and {noMoreThan10Empl} <> ['apply'] and {dataProtectionOfficer} <> ['apply'] and {handicapped} <> ['apply'] and {maternityParentalLeave} <> ['apply'] and {employeeRepresentativeBody} <> ['apply'] or {formalPriorWarnings} = ['apply'] and {noMoreThan10Empl} <> ['apply'] and {dataProtectionOfficer} <> ['apply'] and {handicapped} <> ['apply'] and {maternityParentalLeave} <> ['apply'] and {employeeRepresentativeBody} <> ['apply'] and {noReason} <> ['apply']",
    },
    {
      name: "<6MonateBeschäftigt",
      elements: [
        {
          type: "panel",
          name: "Employment for less than 6 months",
          elements: [
            {
              type: "image",
              name: "question21",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: person,
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "question22",
              startWithNewLine: false,
              html: {
                default:
                  "Based on the information you entered, the employment only lasted less than 6 months. Therefore, the employee is still in the so-called “waiting period” and does not enjoy protection under the Dismissal Protection Act <i>(Kündigungsschutzgesetz)</i>. Therefore, employees in the waiting period usually do not get a severance payment when being dismissed. However, the dismissal needs to be <b> originally signed </b>(strict statutory written-form requirement) and transmitted with proof of receipt before the end of the waiting period. Also the applicable notice period needs to be observed. ",
                de: 'Nach den von Ihnen gemachten Angaben hat das Arbeitsverhältnis weniger als 6 Monate bestanden. Daher befindet sich der/die Arbeitnehmer*in noch in der so genannten "Wartezeit" und genießt keinen Schutz durch das Kündigungsschutzgesetz. Daher erhalten Arbeitnehmer*innen in der Wartezeit in der Regel keine Abfindung, wenn sie entlassen werden. Allerdings muss die Kündigung <b>im Original unterschrieben</b> (strenges gesetzliches Schriftformerfordernis) und vor Ablauf der Wartezeit mit Nachweis des Zugangs übermittelt werden. Auch die geltende Kündigungsfrist muss eingehalten werden.',
              },
            },
          ],
          readOnly: false,
          title: {
            default: "Employment for less than 6 months",
            de: "Beschäftigungsdauer unter 6 Monate",
          },
          startWithNewLine: false,
          width: "initial",
          minWidth: "initial",
        },
        {
          type: "panel",
          name: "Seek lawyers advice",
          elements: [
            {
              type: "image",
              name: "question32",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: lawyerAdvice,
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "question63",
              visibleIf: "{maternityParentalLeave} = ['apply']",
              minWidth: "200px",
              startWithNewLine: false,
              html: {
                default:
                  "However, you indicated that the employee is on maternity or parental leave. This triggers special protection against dismissal even during the waiting period and we <b>highly recommend to seek lawyer advice</b>.",
                de: "Allerdings haben Sie angegeben, dass Mutterschutz bzw. Elternzeit besteht. Dies begründet einen Sonderkündigungsschutz, der auch während der Wartezeit gilt und wir empfehlen Ihnen dringend, sich dazu <b>anwaltlich beraten</b> zu lassen.",
              },
            },
            {
              type: "html",
              name: "question58",
              visibleIf: "{maternityParentalLeave} <> ['apply']",
              minWidth: "200px",
              startWithNewLine: false,
              html: {
                default:
                  "If there are any particularities you wish to discuss or if you want your OC lawyer to prepare the dismissal, you can let us know.",
                de: "Wenn es Besonderheiten gibt, die Sie besprechen möchten, oder wenn Sie möchten, dass Ihr OC-Anwalt die Kündigung vorbereitet, können Sie uns dies mitteilen.",
              },
            },
          ],
          title: {
            default: "Seek lawyers advice",
            de: "Anwaltliche Beratung empfohlen",
          },
        },
        {
          type: "panel",
          name: "Written-form requirement",
          elements: [
            {
              type: "image",
              name: "question11",
              minWidth: "50px",
              maxWidth: "250px",
              imageLink: pen,
              imageHeight: 200,
              // imageWidth: 120,
            },

            {
              type: "html",
              name: "question29",
              startWithNewLine: false,
              html: {
                default:
                  "<h3>Note</h3>\nKindly note that this preliminary automated assessment does not replace individual legal advice. We will be glad to get in touch with you if you wish.\n",
                de: "<h3>Hinweis</h3>\nBitte beachten Sie, dass diese automatisierte Vorprüfung eine individuelle Rechtsberatung nicht ersetzt. Wir setzen uns gerne mit Ihnen in Verbindung, wenn Sie dies wünschen.",
              },
            },
          ],
          visibleIf: "{lessThan6} = [true]",
          title: {
            default: "Please note",
            de: "Bitte beachten",
          },
        },
      ],
      visibleIf: "({lessThan6} == [true])",
    },
    {
      name: "Sonderkündigung&NOTlessthan6",
      elements: [
        {
          type: "panel",
          name: "panel9",
          elements: [
            {
              type: "image",
              name: "question35",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: lawyerAdvice,
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "question60",
              visibleIf: "{handicapped} = ['apply']",
              minWidth: "200px",
              startWithNewLine: false,
              html: {
                default:
                  "Based on the information you entered, the employee enjoys <b>special protection against dismissal </b>as he/she is severely handicapped. Due to this special protection, we <b>highly recommend to seek lawyer advice</b>.",
                de: "Aufgrund der von Ihnen gemachten Angaben genießt der/die Arbeitnehmer*in einen besonderen Kündigungsschutz, da er/sie schwerbehindert ist. Aufgrund dieses besonderen Schutzes empfehlen wir Ihnen dringend, sich anwaltlich beraten zu lassen.",
              },
            },
            {
              type: "html",
              name: "question19",
              visibleIf: "{employeeRepresentativeBody} = ['apply']",
              minWidth: "200px",
              startWithNewLine: false,
              html: {
                default:
                  "Based on the information you entered, the employee enjoys <b>special protection against dismissal </b>as he/she is part of an employee representative body. Due to this special protection, we <b>highly recommend to seek lawyer advice</b>.",
                de: "Aufgrund der von Ihnen gemachten Angaben genießt der/die Arbeitnehmer*in einen besonderen Kündigungsschutz, da er/sie Mitglied einer Arbeitnehmervertretung ist. Aufgrund dieses besonderen Schutzes empfehlen wir Ihnen dringend, sich anwaltlich beraten zu lassen.",
              },
            },
            {
              type: "html",
              name: "question10",
              visibleIf: "{dataProtectionOfficer} = ['apply']",
              minWidth: "200px",
              startWithNewLine: false,
              html: {
                default:
                  "Based on the information you entered, the employee enjoys <b>special protection against dismissal </b>as he/she is a data protection officer. Due to this special protection, we <b>highly recommend to seek lawyer advice</b>.",
                de: "Aufgrund der von Ihnen gemachten Angaben genießt der/die Arbeitnehmer*in einen besonderen Kündigungsschutz, da er/sie Datenschutzbeauftragter ist. Aufgrund dieses besonderen Schutzes empfehlen wir Ihnen dringend, sich anwaltlich beraten zu lassen.",
              },
            },
            {
              type: "html",
              name: "question4",
              visibleIf: "{maternityParentalLeave} = ['apply']",
              minWidth: "200px",
              startWithNewLine: false,
              html: {
                default:
                  "Based on the information you entered, the employee enjoys <b>special protection against dismissal </b>as he/she is on maternity or parental leave or works parental part-time. Due to this special protection, we <b>highly recommend to seek lawyer advice</b>.",
                de: "Aufgrund der von Ihnen gemachten Angaben genießt der/die Arbeitnehmer*in einen besonderen Kündigungsschutz, da er/sie in Mutterschutz, Elternzeit oder Elternteilzeit ist. Aufgrund dieses besonderen Schutzes empfehlen wir Ihnen dringend, sich anwaltlich beraten zu lassen. ",
              },
            },
            {
              type: "html",
              name: "berücksichtigungAußerordKündigung",
              visibleIf: "{extraordinaryDismissal} = ['apply']",
              startWithNewLine: false,
              html: {
                default:
                  "The existence of reasons for an extraordinary termination can then be taken into account during the consultation.",
                de: "Bei der Beratung kann dann das Vorliegen von Gründen für eine außerordentliche Kündigung berücksichtigt werden.",
              },
            },
          ],
          title: {
            default: "Seek lawyers advice",
            de: "Anwaltliche Beratung empfohlen",
          },
        },
        {
          type: "panel",
          name: "panel2",
          elements: [
            {
              type: "image",
              name: "imageSonderkündigungNotLessThan6",
              minWidth: "50px",
              maxWidth: "250px",
              imageLink: {
                default: severanceGuidance,
                de: abfindungBerechnung,
              },
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "question36",
              minWidth: "50px",
              startWithNewLine: false,
              html: {
                default:
                  "<br><br><br><span>In cases of special protection against dismissal, the severance payments usually exceed the explained <a class='ruleOfThumb' href=https://severance-calculator.osborneclarke.com/knowledge#severanceNegotiation target='_blank'>“rule of thumb”</a> and may rather be in the following corridor:<br><br><br>In individual cases where the employee wishes to terminate the employment relationship, it may be possible to reach a termination agreement with a lower severance payment than the corridor shown here.</span>",
                de: "<br><br><br><span>In Fällen mit Sonderkündigungsschutz gehen die Abfindungen in der Regel über die erläuterte <a class='ruleOfThumb' href=https://severance-calculator.osborneclarke.com/knowledge#severanceNegotiation target='_blank'>“Faustformel”</a> hinaus und können sich eher in folgendem Rahmen bewegen: <br><br><br>In Einzelfällen, in denen der/die Arbeitnehmer*in sich selbst eine Beendigung des Arbeitsverhältnisses wünscht, lässt sich ggf. ein Aufhebungsvertrag mit einer niedrigeren Abfindung als nach dem hier dargestellten Korridor erzielen.</span>",
              },
            },
            {
              type: "expression",
              name: "minimum15",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "from",
                de: "von",
              },
              description: {
                default:
                  "(Minimum = Monthly earnings including variable x duration of employment x factor 1.0)",
                de: "(Minimum = Monatliche Vergütung inkl. Variable x Dauer der Beschäftigung x Abfindungsfaktor 1,0)",
              },
              valueName: "calcMinimum15",
              expression:
                "(({annualGrossAmount}+{variablePayments})/12)*{duration}*1",
              displayStyle: "currency",
              currency: "EUR",
            },
            {
              type: "expression",
              name: "maximum2",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "to",
                de: "bis",
              },
              description: {
                default:
                  "(Maximum = Monthly earnings including variable x duration of employment x factor 2.0)",
                de: "(Maximum = Monatliche Vergütung inkl. Variable x Dauer der Beschäftigung x Abfindungsfaktor 2,0)",
              },
              valueName: "calcMaximum2",
              expression:
                "(({annualGrossAmount}+{variablePayments})/12)*{duration}*2",
              displayStyle: "currency",
              currency: "EUR",
            },
          ],
          title: {
            default: "Negotiation Corridor",
            de: "Empfohlener Verhandlungsrahmen",
          },
        },
        {
          type: "panel",
          name: "manualCalcExtraNotLess6",
          elements: [
            {
              type: "text",
              name: "severancefactor3",
              minWidth: "5vh",
              maxWidth: "40vw",
              startWithNewLine: false,
              title: {
                default: "Severance factor (e.g. 0.6)",
                de: 'Abfindungsfaktor (z. B. "0.6")',
              },
              inputType: "number",
              min: "0",
              max: "3",
              maxErrorText: {
                default: "The value should not be greater than {3}",
                de: "Der Wert darf nicht höher sein als 3.",
              },
            },
            {
              type: "boolean",
              name: "fixSalaryOverallRem",
              minWidth: "150px",
              maxWidth: "400px",
              startWithNewLine: false,
              title: {
                de: "Fixgehalt oder Gesamtgehalt?",
                default: "Fix salary or overall remuneration?",
              },
              defaultValue: "false",
              labelTrue: {
                default: "Fix salary",
                de: "Fixgehalt",
              },
              labelFalse: {
                default: "Overall remuneration",
                de: "Gesamtgehalt",
              },
              valueTrue: "fixSalary",
              valueFalse: "overallRemuneration",
            },
            {
              type: "expression",
              name: "severanceAmount3",
              minWidth: "5vh",
              maxWidth: "40vw",
              startWithNewLine: false,
              title: {
                default: "Severance amount",
                de: "Abfindungsbetrag",
              },
              expression: "(({question70}*{duration})/12)*{severancefactor3}",
              displayStyle: "currency",
              currency: "EUR",
            },
            {
              type: "expression",
              name: "question70",
              visible: false,
              minWidth: "200px",
              maxWidth: "250px",
              title: {
                de: "Fixgehalt/Gesamtgehalt",
              },
              expression:
                'iif({fixSalaryOverallRem} == "overallRemuneration", {annualGrossAmount}+{variablePayments}, {annualGrossAmount})',
              displayStyle: "currency",
              currency: "EUR",
            },
          ],
          title: {
            default: "Choose factor for the severance calculation",
            de: "Faktor für die Abfindungsberechnung selbst auswählen",
          },
        },
      ],
      visibleIf:
        "{handicapped} = ['apply'] and {lessThan6} <> [true] or {employeeRepresentativeBody} = ['apply'] and {lessThan6} <> [true] or {dataProtectionOfficer} = ['apply'] and {lessThan6} <> [true] or {maternityParentalLeave} = ['apply'] and {lessThan6} <> [true]",
    },
    {
      name: "smallBusiness10Emp",
      elements: [
        {
          type: "image",
          name: "question65",
          minWidth: "50px",
          maxWidth: "250px",
          startWithNewLine: false,
          imageLink: person,
          imageHeight: 200,
          // imageWidth: 120,
        },
        {
          type: "html",
          name: "question15",
          startWithNewLine: false,
          html: {
            default:
              "<h3>\nSmall business up to 10 employees</h3><br>\nBased on the data you entered the establishment where the employee works has a maximum of 10 employees. Hence it will be considered as small establishment where no justifying reason for dismissal according to the Dismissal Protection Act, <i>Kündigungsschutzgesetz</i>, is required. Accordingly, often no severance is paid at all. <br><br>\nIf the headcount is close to 10, kindly note the details of the calculation: As an exception, if more than five employees have been employed already before 31 December 2003, the Dismissal Protection Act applies for them nevertheless. Part-time employees working up to 20 hours per week count as 0.5, those working up to 30 hours per week count as 0.75 for the calculation of the headcount.\n",
            de: "<h3>\nKleinbetrieb bis 10 Arbeitnehmer*innen</h3>\nNach den von Ihnen eingegebenen Daten hat der Betrieb, in dem der/die Arbeitnehmer*in arbeitet, höchstens 10 Arbeitnehmer. Daher wird er als Kleinbetrieb betrachtet, für den kein rechtfertigender Grund für eine Kündigung nach dem Kündigungsschutzgesetz erforderlich ist. Dementsprechend wird oftmals gar keine Abfindung gezahlt.\n<br><br>\nLiegt die Zahl der Beschäftigten nahe 10, beachten Sie bitte die Einzelheiten der Berechnung: Wurden bereits vor dem 31. Dezember 2003 mehr als fünf Arbeitnehmer*innen dauerhaft beschäftigt, gilt für sie ausnahmsweise trotzdem das Kündigungsschutzgesetz. Teilzeitbeschäftigte mit bis zu 20 Wochenstunden zählen mit 0,5, solche mit bis zu 30 Wochenstunden mit 0,75 für die Berechnung der Mitarbeiterzahl.\n\n",
          },
        },
        {
          type: "panel",
          name: "panel1",
          elements: [
            {
              type: "image",
              name: "question23",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: calculation,
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "question27",
              minWidth: "50px",
              startWithNewLine: false,
              html: {
                default:
                  "<br><br><br><span>The qualification as small establishment also impacts on the usual severance calculation if the Company decides to offer a severance nevertheless. Such severance is usually only about half of the explained <a class='ruleOfThumb' href=https://severance-calculator.osborneclarke.com/knowledge#severanceNegotiation target='_blank'>“rule of thumb”</a>, if any. Considering the <b><u>overall remuneration</u></b>, such severance amount would be:</span>",
                de: "<br><br><br><span>Die Einstufung als Kleinbetrieb wirkt sich auch auf die übliche Abfindungsberechnung aus für den Fall, dass die Gesellschaft sich entschließt trotz Kleinbetriebstatus eine Abfindung anzubieten. Diese beträgt in der Regel nur etwa die Hälfte der erläuterten <a class='ruleOfThumb' href=https://severance-calculator.osborneclarke.com/knowledge#severanceNegotiation target='_blank'>“Faustformel”</a>, falls überhaupt eine Abfindung gezahlt wird. Unter Berücksichtigung der <b>Gesamtvergütung</b> würde ein solcher Abfindungsbetrag betragen:\n</span>",
              },
            },
            {
              type: "expression",
              name: "earningsDur025",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "(EUR gross)",
                de: "(EUR brutto)",
              },
              description: {
                default:
                  "(Monthly earnings x duration of employment x severance factor 0.25)",
                de: "(Formel: Monatsverdienst x Dauer der Beschäftigung x Abfindungsfaktor 0,25)",
              },
              expression:
                "(({annualGrossAmount}+{variablePayments})/12)*{duration}*0.25",
              displayStyle: "currency",
              currency: "EUR",
            },
          ],
          title: {
            default: "Severance Amount based on <u><b>Rule of thumb</u></b>",
            de: "Abfindungsbetrag nach der <u><b>Faustformel</u></b>",
          },
        },
        {
          type: "panel",
          name: "panel10",
          elements: [
            {
              type: "image",
              name: "imageSmallBusiness",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: {
                default: severanceGuidance,
                de: abfindungBerechnung,
              },
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "question39",
              minWidth: "50px",
              startWithNewLine: false,
              html: {
                default:
                  "Hence, it seems likely that a severance in the following corridor would be a reasonable basis for a termination agreement:",
                de: "Es liegt daher nahe, dass eine Abfindung in folgendem Rahmen eine vertretbare Grundlage für einen Aufhebungsvertrag darstellt:",
              },
            },
            {
              type: "expression",
              name: "question41",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "from",
                de: "von",
              },
              description: {
                default: "No severance",
                de: "Keine Abfindung",
              },
              expression: "(({annualGrossAmount})/12)*{duration}*0",
              displayStyle: "currency",
              currency: "EUR",
            },
            {
              type: "expression",
              name: "maxOverAll03",
              minWidth: "50px",
              startWithNewLine: false,
              title: {
                default: "to",
                de: "bis",
              },
              description: {
                default:
                  "(Maximum = factor 0.3 based on the overall remuneration)",
                de: "(Maximum = Faktor 0,3 auf Grundlage der Gesamtvergütung)",
              },
              valueName: "calcMaxOverAll03",
              expression:
                "((({annualGrossAmount}+{variablePayments})/12)*{duration}*0.3)",
              displayStyle: "currency",
              currency: "EUR",
            },
          ],
          title: {
            de: "Empfohlener Verhandlungsrahmen",
            default: "Negotiation Corridor",
          },
        },
        {
          type: "panel",
          name: "manualCalc4",
          elements: [
            {
              type: "text",
              name: "question37",
              minWidth: "5vh",
              maxWidth: "40vw",
              startWithNewLine: false,
              title: {
                default: "Severance factor (e.g. 0.6)",
                de: 'Abfindungsfaktor (z. B. "0.6")',
              },
              inputType: "number",
              min: "0",
              max: "3",
              maxErrorText: {
                default: "The value should not be greater than {3}",
                de: "Der Wert darf nicht höher sein als 3.",
              },
            },
            {
              type: "boolean",
              name: "question47",
              minWidth: "150px",
              maxWidth: "400px",
              startWithNewLine: false,
              title: {
                de: "Fixgehalt oder Gesamtgehalt?",
                default: "Fix salary or overall remuneration?",
              },
              defaultValue: "false",
              labelTrue: {
                default: "Fix salary",
                de: "Fixgehalt",
              },
              labelFalse: {
                default: "Overall remuneration",
                de: "Gesamtgehalt",
              },
              valueTrue: "fixSalary",
              valueFalse: "overallRemuneration",
            },
            {
              type: "expression",
              name: "question56",
              minWidth: "5vh",
              maxWidth: "40vw",
              startWithNewLine: false,
              title: {
                default: "Severance amount",
                de: "Abfindungsbetrag",
              },
              expression: "(({question71}*{duration})/12)*{question37}",
              displayStyle: "currency",
              currency: "EUR",
            },
            {
              type: "expression",
              name: "question71",
              visible: false,
              minWidth: "200px",
              maxWidth: "250px",
              title: {
                de: "Fixgehalt/Gesamtgehalt",
              },
              expression:
                'iif({question47} == "overallRemuneration", {annualGrossAmount}+{variablePayments}, {annualGrossAmount})',
              displayStyle: "currency",
              currency: "EUR",
            },
          ],
          title: {
            default: "Choose factor for the severance calculation",
            de: "Faktor für die Abfindungsberechnung selbst auswählen",
          },
        },
      ],
      visibleIf:
        "{noMoreThan10Empl} = ['apply'] and {maternityParentalLeave} <> ['apply'] and {dataProtectionOfficer} <> ['apply'] and {handicapped} <> ['apply'] and {employeeRepresentativeBody} <> ['apply'] and {lessThan6} <> [true] or {noMoreThan10Empl} = ['apply'] and {formalPriorWarnings} = ['apply'] and {maternityParentalLeave} <> ['apply'] and {dataProtectionOfficer} <> ['apply'] and {handicapped} <> ['apply'] and {employeeRepresentativeBody} <> ['apply'] and {lessThan6} <> [true] or {noMoreThan10Empl} = ['apply'] and {extraordinaryDismissal} = ['apply'] and {maternityParentalLeave} <> ['apply'] and {dataProtectionOfficer} <> ['apply'] and {handicapped} <> ['apply'] and {employeeRepresentativeBody} <> ['apply'] and {lessThan6} <> [true]",
    },
    {
      name: "resultsOptionsGeneral",
      elements: [
        {
          type: "panel",
          name: "panel13",
          elements: [
            {
              type: "image",
              name: "question28",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: table,
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "question61",
              minWidth: "200px",
              startWithNewLine: false,
              html: {
                default:
                  "You could try to obtain a <b>mutual termination agreement</b> with the employee by offering the minimum calculated amount as a starting point. Alternatively, you might proceed to a <b>dismissal</b> whereby a court settlement to end the unfair dismissal proceedings will probably only be achievable against payment of a severance package within the outlined corridor. In both cases, the employee’s claims are likely to be higher if he/she sees good arguments on his/her side to win the court case. Usually employees will have the expectation to obtain a severance based on their <b>overall</b> remuneration (amount indicated above).",
                de: "Sie könnten versuchen, eine <b>einvernehmliche Beendigung</b> des Arbeitsverhältnisses zu erreichen, indem Sie zunächst den berechneten Mindestbetrag anbieten. Alternativ könnten Sie eine <b>Kündigung</b> aussprechen, wobei ein gerichtlicher Vergleich zur Beendigung des Kündigungsschutzverfahrens vermutlich nur gegen Zahlung einer Abfindung innerhalb des skizzierten Rahmens zu erzielen sein wird. In beiden Fällen werden die Abfindungsforderungen des Arbeitnehmers wahrscheinlich höher sein, wenn er gute Argumente auf seiner Seite sieht, um den Prozess zu gewinnen. In der Regel haben Arbeitnehmer*innen die Erwartung, dass die Abfindung auf Basis ihrer <b>Gesamtvergütung</b> berechnet wird (Betrag siehe oben).",
              },
            },
          ],
          visibleIf:
            "{noReason} <> ['apply'] and {noMoreThan10Empl} <> ['apply'] and {lessThan6} <> [true] and {employeeRepresentativeBody} <> ['apply'] and {dataProtectionOfficer} <> ['apply'] and {maternityParentalLeave} <> ['apply'] and {handicapped} <> ['apply'] and {extraordinaryDismissal} <> ['apply'] and {formalPriorWarnings} <> ['apply']",
          title: {
            default: "Mutual termination or dismissal",
            de: "Einvernehmliche Aufhebungsvereinbarung oder Kündigung",
          },
          startWithNewLine: false,
          width: "initial",
          minWidth: "initial",
        },
        {
          type: "panel",
          name: "panel19",
          elements: [
            {
              type: "image",
              name: "imgTable",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: table,
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "mutTermAgrNoSeverance",
              minWidth: "200px",
              startWithNewLine: false,
              html: {
                default:
                  "You could try to obtain a <b>mutual termination agreement</b> by offering a termination without severance as a starting point. Alternatively, you might proceed to a <b>dismissal</b>. As the employee is working in a small establishment, he/she has only very limited chances to contest the effectiveness of the dismissal (e. g. if it is very clear that the employment is terminated arbitrarily or due to discrimination). However, in order to avoid dispute about the termination or compensate the loss of employment, some employers offer a small severance.",
                de: "Sie könnten versuchen eine <b>einvernehmliche Beendigung</b> des Arbeitsverhältnisses zu erreichen, indem Sie zunächst einen Aufhebungsvertrag ohne Abfindung anbieten. Alternativ können Sie auch eine <b>Kündigung</b> aussprechen. Da der/die Arbeitnehmer*in in einem Kleinbetrieb arbeitet, hat er/sie nur sehr begrenzte Möglichkeiten, die Wirksamkeit der Kündigung anzufechten (z. B. wenn erkennbar ist, dass die Kündigung willkürlich oder diskriminierend erfolgte). Dennoch bieten manche Arbeitgeber eine kleine Abfindung an, um Streit um die Beendigung zu vermeiden oder um den Verlust des Arbeitsplatzes auszugleichen.",
              },
            },
          ],
          visibleIf:
            "{noMoreThan10Empl} = ['apply'] and {lessThan6} <> [true] and {extraordinaryDismissal} <> ['apply'] and {maternityParentalLeave} <> ['apply'] and {handicapped} <> ['apply'] and {dataProtectionOfficer} <> ['apply'] and {employeeRepresentativeBody} <> ['apply']",
          title: {
            default: "Mutual termination or dismissal",
            de: "Einvernehmliche Aufhebungsvereinbarung oder Kündigung",
          },
          startWithNewLine: false,
          width: "initial",
          minWidth: "initial",
        },
        {
          type: "panel",
          name: "panel3",
          elements: [
            {
              type: "image",
              name: "question30",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: table,
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "question73",
              visibleIf:
                "{handicapped} = ['apply'] or {maternityParentalLeave} = ['apply']",
              minWidth: "200px",
              startWithNewLine: false,
              html: {
                default:
                  "In spite of the special protection against dismissal, you could still try to obtain a <b>mutual termination agreement</b> with the employee. Proceeding to a dismissal would require to seek approval of a public authority. Otherwise, the dismissal would be invalid, and the employment relationship would continue.",
                de: "Trotz des besonderen Sonderkündigungsschutzes könnten Sie versuchen, eine <b>einvernehmliche Beendigung</b> zu erreichen. Um eine Kündigung auszusprechen, müssten Sie zunächst die Zustimmung einer Behörde einholen. Andernfalls wäre die Kündigung unwirksam und das Arbeitsverhältnis bestünde fort.",
              },
            },
            {
              type: "html",
              name: "question74",
              visibleIf: "{employeeRepresentativeBody} = ['apply']",
              minWidth: "200px",
              startWithNewLine: false,
              html: {
                default:
                  "In spite of the special protection against dismissal, you could still try to obtain a <b>mutual termination agreement</b> with the employee. A dismissal would generally only be possible as extraordinary dismissal based on an important reason and after obtaining the works council's approval. Otherwise, the dismissal would be invalid, and the employment relationship would continue.",
                de: "Trotz des besonderen Sonderkündigungsschutzes könnten Sie versuchen, eine <b>einvernehmliche Beendigung</b> zu erreichen. Eine Kündigung wäre grundsätzlich nur als außerordentliche Kündigung aus wichtigem Grund nach Zustimmung des Betriebsrats möglich. Andernfalls wäre die Kündigung unwirksam und das Arbeitsverhältnis bestünde fort.",
              },
            },
            {
              type: "html",
              name: "dataProtOffRes",
              visibleIf: "{dataProtectionOfficer} = ['apply']",
              minWidth: "200px",
              startWithNewLine: false,
              html: {
                default:
                  "In spite of the special protection against dismissal, you could still try to obtain a <b>mutual termination agreement</b> with the employee. The dismissal of a legally required internal data protection officer would generally only be possible as extraordinary dismissal based on an important reason.",
                de: "Trotz des besonderen Sonderkündigungsschutzes könnten Sie versuchen, eine <b>einvernehmliche Beendigung</b> zu erreichen. Eine Kündigung eines gesetzlich erforderlichen internen Datenschutzbeauftragten ist grundsätzlich nur als außerordentliche Kündigung aus wichtigem Grund möglich.",
              },
            },
          ],
          visibleIf:
            "{handicapped} = ['apply'] and {lessThan6} <> [true] or {maternityParentalLeave} = ['apply'] and {lessThan6} <> [true] or {employeeRepresentativeBody} = ['apply'] and {lessThan6} <> [true] or {dataProtectionOfficer} = ['apply'] and {lessThan6} <> [true]",
          title: {
            default: "Mutual termination or dismissal",
            de: "Einvernehmliche Aufhebungsvereinbarung oder Kündigung",
          },
          startWithNewLine: false,
          width: "initial",
          minWidth: "initial",
        },
        {
          type: "panel",
          name: "panel16",
          elements: [
            {
              type: "image",
              name: "question5",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: table,
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "question62",
              minWidth: "200px",
              startWithNewLine: false,
              html: {
                default:
                  "You could try to obtain a <b>mutual termination agreement</b> with the employee by offering a mutual termination observing the notice period and eventually only a small severance as a starting point. Alternatively, you might proceed to an extraordinary and, by precaution, also ordinary <b>dismissal</b>, but be prepared to pay an amount within the outlined corridor in case of a court settlement. In particular, the legal requirement for extraordinary dismissals are high and the employee might still find arguments on his/her side to win the court case. Usually employees will have the expectation to agree on the ordinary termination of the employment under observation of the notice period and obtain a severance based on their overall remuneration (amount indicated above).",
                de: "Sie könnten versuchen, eine <b>einvernehmliche Aufhebungsvereinbarung</b> zu erreichen, indem Sie zunächst eine Beendigung unter Einhaltung der Kündigungsfrist und eventuell eine geringe Abfindung anbieten. Alternativ könnten Sie eine außerordentliche und vorsorglich hilfsweise ordentliche <b>Kündigung</b> aussprechen, aber bereit sein, im Falle eines gerichtlichen Vergleichs einen Betrag innerhalb des skizzierten Rahmens zu zahlen. Insbesondere bei außerordentlichen Kündigungen sind die gesetzlichen Anforderungen hoch und der/die Arbeitnehmer*in könnte noch Argumente auf seiner Seite finden, um den Prozess zu gewinnen. In der Regel haben Arbeitnehmer*innen die Erwartung, dass das Arbeitsverhältnis auf Basis einer ordentlichen Kündigung unter Wahrung der Kündigungsfrist beendet wird und die Abfindung auf Basis ihrer Gesamtvergütung berechnet wird (Betrag siehe oben).",
              },
            },
          ],
          visibleIf:
            "{extraordinaryDismissal} = ['apply'] and {employeeRepresentativeBody} <> ['apply'] and {dataProtectionOfficer} <> ['apply'] and {handicapped} <> ['apply'] and {maternityParentalLeave} <> ['apply'] or {formalPriorWarnings} = ['apply'] and {employeeRepresentativeBody} <> ['apply'] and {dataProtectionOfficer} <> ['apply'] and {handicapped} <> ['apply'] and {maternityParentalLeave} <> ['apply'] and {noReason} <> ['apply']",
          title: {
            default: "Mutual termination or dismissal",
            de: "Einvernehmliche Aufhebungsvereinbarung oder Kündigung",
          },
          startWithNewLine: false,
          width: "initial",
          minWidth: "initial",
        },
        {
          type: "panel",
          name: "panel17",
          elements: [
            {
              type: "image",
              name: "question57",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: table,
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "question64",
              minWidth: "200px",
              startWithNewLine: false,
              html: {
                default:
                  "You could try to obtain a <b>mutual termination agreement</b> with the employee by offering a higher severance according to the indicated corridor. Alternatively, if you proceeded to a <b>dismissal</b>, you would accept a considerably increased litigation risk and would have to be prepared to pay a higher severance amount within the outlined corridor in case of a court settlement or even accept a defeat in court and continued employment. In both cases, the employee’s claims are likely to be higher if he/she sees good arguments on his/her side to win the court case. Usually employees will have the expectation to obtain a severance based on their overall remuneration (amount indicated above). ",
                de: "Sie könnten versuchen, eine <b>einvernehmliche Beendigung</b> des Arbeitsverhältnisses zu erreichen, indem Sie einen höheren Abfindungsbetrag entsprechend des angegebenen Korridors anbieten. Alternativ würden Sie bei Ausspruch einer <b>Kündigung</b> ein erheblich erhöhtes Prozessrisiko in Kauf nehmen und müssten bereit sein, im Falle eines gerichtlichen Vergleichs einen höheren Abfindungsbetrag innerhalb des skizzierten Rahmens zu zahlen oder sogar eine Prozessniederlage und die Weiterbeschäftigung zu akzeptieren. In beiden Fällen werden die Abfindungsforderungen des Arbeitnehmers wahrscheinlich höher sein, wenn er gute Argumente auf seiner Seite sieht, um den Prozess zu gewinnen. In der Regel haben Arbeitnehmer*innen die Erwartung, dass die Abfindung auf Basis ihrer Gesamtvergütung berechnet wird (Betrag siehe oben).",
              },
            },
          ],
          visibleIf:
            "{noReason} = ['apply'] and {lessThan6} <> [true] and {dataProtectionOfficer} <> ['apply'] and {employeeRepresentativeBody} <> ['apply'] and {handicapped} <> ['apply'] and {maternityParentalLeave} <> ['apply'] and {noMoreThan10Empl} <> ['apply'] or {noReason} = ['apply'] and {formalPriorWarnings} = ['apply'] and {lessThan6} <> [true] and {dataProtectionOfficer} <> ['apply'] and {employeeRepresentativeBody} <> ['apply'] and {handicapped} <> ['apply'] and {maternityParentalLeave} <> ['apply'] and {noMoreThan10Empl} <> ['apply'] or {noReason} = ['apply'] and {lessThan6} <> [true] and {dataProtectionOfficer} <> ['apply'] and {employeeRepresentativeBody} <> ['apply'] and {handicapped} <> ['apply'] and {maternityParentalLeave} <> ['apply'] and {noMoreThan10Empl} <> ['apply'] and {employee50} = ['apply']",
          title: {
            default: "Mutual termination or dismissal",
            de: "Einvernehmliche Aufhebungsvereinbarung oder Kündigung",
          },
          startWithNewLine: false,
          width: "initial",
          minWidth: "initial",
        },
        {
          type: "panel",
          name: "panel18",
          elements: [
            {
              type: "image",
              name: "question31",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: false,
              imageLink: person,
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "question69",
              startWithNewLine: false,
              html: {
                default:
                  "If you take the route of a mutual termination agreement, we recommend to <b>include all other open matters</b>, in particular: <br><br><ul><li> the effective date of termination,</li><li> full salary for the remaining employment period,</li><li> due amount for variable payments,</li><li> possibly release from work,</li><li> remaining holiday entitlement, </li><li> issuance of a favourable qualified employer's reference, if appropriate with agreement on the grade level,</li><li> settlement of all mutual claims etc.</li></ul>",
                de: "Wenn Sie eine einvernehmliche Aufhebungsvereinbarung anstreben, empfehlen wir, <b>alle anderen offenen Punkte mit aufzunehmen</b>, insbesondere<br><br> <ul><li>den Zeitpunkt des Wirksamwerdens der Kündigung,</li><li> die Gehaltszahlung für die Restlaufzeit des Arbeitsverhältnisses,</li><li> die Höhe der variablen Bezüge,</li><li> eine eventuelle Freistellung,</li> <li> den Resturlaubsanspruch,</li> <li> Erteilung eines wohlwollenden qualifizierten Arbeitszeugnisses, ggf. mit Vereinbarung der Notenstufe,</li> <li> die Erledigung aller gegenseitigen Ansprüche usw.</li></ul>",
              },
            },
            {
              type: "image",
              name: "imgWrittenForm",
              minWidth: "50px",
              maxWidth: "250px",
              startWithNewLine: true,
              imageLink: pen,
              imageHeight: 200,
              // imageWidth: 120,
            },
            {
              type: "html",
              name: "question67",
              startWithNewLine: false,
              html: {
                default:
                  "<h3>Written-form requirement </h3>\nThe agreement needs to be <b>originally signed by both parties</b> (strict statutory written-form requirement). ",
                de: "<h3>Schriftformerfordernis</h3>\nDie Vereinbarung muss im Original <b>von beiden Parteien</b> unterzeichnet werden (strenges gesetzliches Schriftformerfordernis).",
              },
            },
            {
              type: "html",
              name: "question68",
              startWithNewLine: true,
              html: {
                default:
                  "<h3>Note</h3>\nKindly note that this preliminary automated assessment does not replace individual legal advice. We will be glad to get in touch with you if you wish.\n",
                de: "<h3>Hinweis</h3>\nBitte beachten Sie, dass diese automatisierte Vorprüfung eine individuelle Rechtsberatung nicht ersetzt. Wir setzen uns gerne mit Ihnen in Verbindung, wenn Sie dies wünschen.",
              },
            },
          ],
          visibleIf: "{lessThan6} <> [true]",
          title: {
            default: "Settle all open matters",
            de: "Andere offene Punkte",
          },
        },
      ],
    },
    {
      name: "finish",
      elements: [
        {
          type: "panel",
          name: "panel4",
          elements: [
            {
              type: "image",
              name: "contactOC",
              maxWidth: "100px",
              imageLink: finishLine,
              altText: "imgFinish",
              contentMode: "image",
              imageHeight: 200,
              imageWidth: 250,
            },
            {
              type: "html",
              name: "question1",
              startWithNewLine: false,
              html: {
                default:
                  "\n\n<br /><br /><h2>Thank you for using our severance calculation tool.</h2>\n\n<h2>Please choose the next steps.</h2>",
                de: "<br /><br /><h2>Vielen Dank für die Nutzung unseres Abfindungsrechners.</h2>\n\n<br><br> <h2>Was möchten Sie als nächstes tun?</h2>\n",
              },
            },
          ],
        },
        {
          type: "panel",
          name: "panel19",
          elements: [
            {
              type: "image",
              name: "contactOC",
              minWidth: "50px",
              altText: "imageContactOC",
              imageLink: contactOC,
              contentMode: "image",
              imageHeight: 200,
              imageWidth: 250,
            },
            {
              type: "image",
              name: "imgNewsletter",
              minWidth: "100px",
              startWithNewLine: false,
              imageLink: laptop,
              altText: "imgNewsletter",
              contentMode: "image",
              imageHeight: 200,
              imageWidth: 250,
            },
            {
              type: "panel",
              name: "panelNewsletter",
              elements: [
                {
                  type: "html",
                  minWidth: "200px",
                  name: "anyQuestions",
                  html: {
                    default:
                      "<h3>Do you have any questions regarding the result or any other topic? <br><br><b>Contact our experts!</b></h3>",
                    de: "<h3>Haben Sie Fragen zu diesem Ergebnis oder zu einem anderen Thema? <br><br><b>Kontaktieren Sie unsere Experten!</b></h3>",
                  },
                },
                {
                  type: "html",
                  name: "empNewsletter",
                  startWithNewLine: false,

                  minWidth: "100px",
                  html: {
                    default: "<h3>Employment Newsletter & Events</h3>",
                    de: "<h3>Arbeitsrechtlicher Newsletter & </br>Veranstaltungen</h3><br><br>",
                  },
                },
                {
                  type: "html",
                  name: "cont",
                  minWidth: "100px",

                  html: {
                    default:
                      '<a class="manualButton" href=mailto:severance-calculator@osborneclarke.com?subject=Anfrage%20Abfindungsrechner/Inquiry%20severance%20calculator>Email the OC Employment team!</a>',
                    de: '<a class="manualButton" href=mailto:severance-calculator@osborneclarke.com?subject=Anfrage%20Abfindungsrechner/Inquiry%20severance%20calculator>Osborne Clarke kontaktieren!</a>',
                  },
                },

                {
                  type: "html",
                  name: "question12",
                  minWidth: "100px",
                  startWithNewLine: false,
                  html: {
                    default:
                      "<a class='manualButton' href='https://sites-osborneclarke.vuturevx.com/10/7683/english/employment-blank.asp' target='_blank' rel='noreferrer'> Register Now!</a>",
                    de: "<a class='manualButton' href='https://sites-osborneclarke.vuturevx.com/10/7683/deutsch/arbeitsrecht-blank.asp' target='_blank' rel='noreferrer'> Jetzt anmelden! </a>",
                  },
                },
              ],
              maxWidth: "fit-content",
              minWidth: "100px",
            },
          ],
        },
        {
          type: "panel",
          name: "panelNewsletter",
          minWidth: "100px",
          elements: [],
          startWithNewLine: false,
        },
      ],
    },

    {
      name: "page1",
    },
  ],
  showQuestionNumbers: "off",
  progressBarType: "requiredQuestions",
  clearInvisibleValues: "onHidden",
  // focusFirstQuestionAutomatic: false,
  pagePrevText: {
    default: "Back",
    de: "Zurück",
  },
  pageNextText: {
    default: "Next",
    de: "Weiter",
  },
  completeText: {
    default: "New calculation",
    de: "Neue Berechnung",
  },
};
