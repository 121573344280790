import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import OcsLogo from "../SurveySection/images/bluebgrdOCS.png";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { Trans, useTranslation } from "react-i18next";
import i18next from "../../glossary";
import { HashLink } from "react-router-hash-link";

// import { Dropdown } from "./../../dropdown";

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  Hashlink,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";

const Navbar = ({ toggle, items, navSettings }) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [navColorDark, setnavColorDark] = useState(navSettings.navBarDark);

  //when scroll past 80px, trigger header background
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setnavColorDark(true);
    } else {
      setnavColorDark(navSettings.navBarDark);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  });

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav navColorDark={navColorDark}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              <img src={OcsLogo} height="50%" alt="OC Solutions" />
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <HashLink
                  class="navBarItem"
                  to="/#home"
                  onClick={
                    onclick
                      ? function () {
                          this.onclick();
                        }
                      : ""
                  }
                  smooth={navSettings.smooth}
                  duration={navSettings.duration}
                  spy={navSettings.spy}
                  exact={navSettings.exact}
                  offset={navSettings.offset}
                >
                  {i18next.t("navbarItem0")}
                </HashLink>

                <HashLink
                  class="navBarItem"
                  to="/#newsletter"
                  onClick={
                    onclick
                      ? function () {
                          this.onclick();
                        }
                      : ""
                  }
                  smooth={navSettings.smooth}
                  duration={navSettings.duration}
                  spy={navSettings.spy}
                  exact={navSettings.exact}
                  offset={navSettings.offset}
                >
                  {i18next.t("navbarItem1")}
                </HashLink>
                <HashLink
                  class="navBarItem"
                  to="/#surveys"
                  onClick={
                    onclick
                      ? function () {
                          this.onclick();
                        }
                      : ""
                  }
                  smooth={navSettings.smooth}
                  duration={navSettings.duration}
                  spy={navSettings.spy}
                  exact={navSettings.exact}
                  offset={navSettings.offset}
                >
                  {i18next.t("navbarItem2")}
                </HashLink>

                <HashLink
                  class="navBarItem"
                  to="/#discover"
                  onClick={
                    onclick
                      ? function () {
                          this.onclick();
                        }
                      : ""
                  }
                  smooth={navSettings.smooth}
                  duration={navSettings.duration}
                  spy={navSettings.spy}
                  exact={navSettings.exact}
                  offset={navSettings.offset}
                >
                  {i18next.t("navbarItem4")}
                </HashLink>

                <HashLink
                  class="navBarItem"
                  to="/#contacts"
                  onClick={
                    onclick
                      ? function () {
                          this.onclick();
                        }
                      : ""
                  }
                  smooth={navSettings.smooth}
                  duration={navSettings.duration}
                  spy={navSettings.spy}
                  exact={navSettings.exact}
                  offset={navSettings.offset}
                >
                  {i18next.t("navbarItem3")}
                </HashLink>
              </NavItem>

              <LanguageSwitcher />
            </NavMenu>
            <NavBtn>
              {navSettings.signinHidden ? (
                <></>
              ) : (
                <NavBtnLink to="/signin">Sign In</NavBtnLink>
              )}
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
