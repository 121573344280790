export default function EL(tagName, props = {}, children) {
  if (typeof tagName !== "string") {
    throw new Error(
      "Expected tagName to be a string. Found: " + typeof tagName
    );
  }
  const newTag = document.createElement(tagName);
  const attributeList = Object.entries(props);
  // attributeList.forEach(([attribute, value]) => (newTag[attribute] = value));
  attributeList.forEach(([attribute, value]) =>
    newTag.setAttribute(attribute, value)
  );
  if (Array.isArray(children)) {
    const validChildren = children.filter((child) => child);
    newTag.append(...validChildren);
  } else if (children) {
    newTag.append(children);
  }
  return newTag;
}
