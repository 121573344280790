// Initialize a connection to Application Insights
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    connectionString:
      "InstrumentationKey=713a3198-77a8-443d-9c44-87acc6970d5a;IngestionEndpoint=https://germanywestcentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://germanywestcentral.livediagnostics.monitor.azure.com/",
    // since router history isn't exposed here (react-router v6), Application Insights configuration enableAutoRouteTracking is used to auto-track router changes
    enableAutoRouteTracking: true,
    isCookieUseDisabled: true,
    isStorageUseDisabled: true,
    enableSessionStorageBuffer: true,
    extensions: [reactPlugin],
    // extensionConfig: {
    //   [reactPlugin.identifier]: { history: browserHistory },
    // },
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
