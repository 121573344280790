import convertHtmlToOoxml from "./ooxmlConverter";

function fromHtml(input) {
  if (!fromHtml.domParser)
    throw new Error(
      "No Dom Parser specified prior to using the fromHtml filter"
    );

  return convertHtmlToOoxml(input, fromHtml.domParser);
}

fromHtml.setDomParser = setDomParser.bind(fromHtml);

function setDomParser(newParser) {
  this.domParser = newParser;
}

export default fromHtml;
