import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import { Suspense } from "react";
import { HashLink } from "react-router-hash-link";

import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";
import HeroSection from "../components/HeroSection/Hero";
import InfoSection from "../components/InfoSection/InfoSection";
import ContactSection from "../components/ContactSection/ContactSection";
import FullText from "../components/FullTextSection/FullTextSection";
import Services from "../components/CardsSection/CardsSection";
import SurveySection from "../components/SurveySection/SurveySection";
import Footer from "../components/Footer/Footer";
import docAutoTemplate from "../wordTemplates/results.docx";

import ocRecruitment from "../components/SurveySection/images/ocRecruitment.gif";
import ocManBulb from "../components/SurveySection/images/ocManBulb.gif";
import ocFinancial from "../components/SurveySection/images/ocFinancial.gif";
import ocPhone from "../components/SurveySection/images/ocPhone.gif";
//import ocEnergyNEW from "../components/SurveySection/images/ocEnergyNEW.gif";
import ocCheck from "../components/SurveySection/images/ocCheck.gif";
import ocRetail from "../components/SurveySection/images/ocRetail.gif";
import { Button } from "../components/ButtonElement";
import { surveyJson } from "./../surveyJson";
import genDoc from "../components/DocXTemplater/index";
import { Trans, useTranslation } from "react-i18next";
import i18next from "../glossary";
import LanguageDetector from "i18next-browser-languagedetector";
import fallbackLng from "i18next";

const Home = () => {
  const { t, i18n } = useTranslation();

  let surveyData = {};
  const surveySectionOnChangeCallback = (data) => {
    surveyData = data;
  };
  let template = docAutoTemplate;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Suspense fallback="...is loading">
      <>
        <Sidebar
          toggle={toggle}
          isOpen={isOpen}
          items={navbarItems}
          navSettings={navSettings}
        />
        <Navbar toggle={toggle} items={navbarItems} navSettings={navSettings} />
        <HeroSection />

        <InfoSection />
        <SurveySection
          surveyJson={surveyJson}
          surveySectionOnChangeCallback={surveySectionOnChangeCallback}
        />
        {/* <div
          id="generateButton"
          Style={
            "display: flex; justify-content: flex-start; background:#f3f3f3;      padding-bottom: 10px;  padding-top: 10px; justify-content: center;"
          }
        >
          <Button
            primary="true"
            dark="true"
            class="manualButton"
            onClick={() => {
              genDoc(surveyData, template, "generatedDocument.docx");
              //alert("The document will be downloaded shortly");
            }}
          >
            📝 Generate Document
          </Button>
        </div> */}
        <Services />
        <ContactSection />
        <Footer />
        {/* <CookieConsent
          style={cookieConsentContents.style}
          buttonStyle={cookieConsentContents.buttonStyle}
          expires={cookieConsentContents.expires}
          enableDeclineButton={cookieConsentContents.enableDeclineButton}
          overlay={cookieConsentContents.overlay}
          setDeclineCookie={cookieConsentContents.setDeclineCookie}
          contentStyle={cookieConsentContents.contentStyle}
          onDecline={cookieConsentContents.onDecline}
        >
          <p>{cookieConsentContents.text}</p>
          <br></br>
          <p id="onetrust-policy-text">
            <a
              href={cookieConsentContents.link}
              aria-label={cookieConsentContents.linkLabel}
            >
              {cookieConsentContents.label}
            </a>
          </p>
        </CookieConsent> */}
      </>
    </Suspense>
  );
};

export default Home;

// page content objects

const cookieConsentContents = {
  style: { background: "#2B373B" },
  buttonStyle: { color: "#4e503b", fontSize: "13px" },
  expires: 150,
  enableDeclineButton: "true",
  overlay: "true",
  setDeclineCookie: "false",
  contentStyle: { "font-size": "large" },
  onDecline: () => {
    window.location.href = "https://www.osborneclarke.com";
  },
  label: i18next.t("cookieLabel"),
  link: "https://www.osborneclarke.com/cookie-policy",
  linkLabel: i18next.t("cookieLinkLabel"),
  text: i18next.t("cookieText"),
};
const cardSectionContent = {
  heading: i18next.t("cardsHeading"),
  cards: [
    {
      id: "dismProtectionProceedingsCard",
      heading: (
        <p>
          <Trans i18nKey="card1heading">
            <HashLink
              class="contactName"
              to="/knowledge#dismissalProtectionProceedings"
            >
              Kündigungsschutzverfahren NEU
            </HashLink>
          </Trans>
        </p>
      ),

      text: "",
      image: ocCheck,
    },
    {
      id: "severanceNegotiationCard",
      heading: (
        <p>
          <Trans i18nKey="card2heading">
            <HashLink class="contactName" to="/knowledge#severanceNegotiation">
              Abfindungsverhandlung
            </HashLink>
          </Trans>
        </p>
      ),

      text: "",
      image: ocRecruitment,
    },
    {
      id: "videoCard",
      heading: (
        <p>
          <Trans i18nKey="card3heading">
            <HashLink class="contactName" to="/knowledge#videosKnowledge">
              Kündigungsschutzverfahren + Abfindungsverhandlung
            </HashLink>
          </Trans>
        </p>
      ),
      text: "Kündigungsschutzverfahren + Abfindungsverhandlung",
      image: ocFinancial,
    },
    {
      id: "reasonsForDismissalCard",
      heading: (
        <p>
          <Trans i18nKey="card4heading">
            <HashLink class="contactName" to="/knowledge#reasonsForDismissal">
              Kündigungsgründe
            </HashLink>
          </Trans>
        </p>
      ),
      text: "",
      image: ocManBulb,
    },
    {
      id: "dismissalProcessCard",
      heading: (
        <p>
          <Trans i18nKey="card5heading">
            <HashLink class="contactName" to="/knowledge#dismissalProcess">
              Kündigungsausspruch
            </HashLink>
          </Trans>
        </p>
      ),
      text: "",
      image: ocManBulb,
    },

    /** OC CONTACTS TEMPLATE
     * 
     * {
      heading: (
        <a
          class="contactName"
          target="blank"
          href="https://www.osborneclarke.com/lawyers/martin-soppe"
        >
          Dr. Martin Soppe
        </a>
      ),
      text: "Rechtsanwalt/Partner",
      link: "https://www.osborneclarke.com/",
      email: (
        <a class="contactEmail" href="mailto:martin.soppe@osborneclarke.com">
          E-Mail an Martin senden
        </a>
      ),
      image: ocMartinSoppe,
    },
     */
  ],
};
const navbarItems = [
  {
    to: "about",
    title: i18next.t("navbarItem1"),
  },
  {
    to: "surveys",
    title: i18next.t("navbarItem2"),
  },
  {
    to: "contacts",
    title: i18next.t("navbarItem4"),
  },
  {
    to: "discover",
    title: i18next.t("navbarItem3"),
  },

  // {
  //   to: "signup",
  //   title: i18next.t("navbarItem5"),
  // },
];
const navSettings = {
  smooth: true,
  duration: 500,
  spy: true,
  exact: "true",
  offset: -80,
  signinHidden: true,
};

/************ SurveyJson in separate Datei ausgelagert ****************/

const infoSectionContents = [
  {
    id: "newsletter",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: i18next.t("infoTopline1"),
    headline: i18next.t("infoHeadline1"),
    description: (
      <p>
        <Trans i18nKey="infoDescription1">
          {" "}
          Would you like to receive employment law insights, news and events?
          Follow this link to register for the Osborne Clarke Employment
          Newsletter!
        </Trans>
      </p>
    ),
    externalLink: (
      <p>
        <Trans i18nKey="infoExternalLink1">
          <a
            class="manualButton"
            href="https://sites-osborneclarke.vuturevx.com/10/7683/english/employment-blank.asp"
          >
            Register Now
          </a>
        </Trans>
        <br />
      </p>
    ),
    buttonLabel: i18next.t("infoButton1"),
    buttonTo: "home",
    imgStart: false,
    img: ocCheck,
    alt: "ocCheck",
    dark: true,
    primary: true,
    darkText: true,
  },
  {
    id: "discover",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: i18next.t("infoTopline2"),
    headline: i18next.t("infoHeadline2"),
    description: i18next.t("infoDescription2"),
    buttonLabel: i18next.t("infoButton2"),
    buttonTo: "home",

    // extLink: (
    //   <p>
    //     <Trans i18nKey="extLink">
    //       <a
    //         target="blank"
    //         class="manualButton"
    //         href="https://www.osborneclarke.com/lawyers?title=&location=6392&field_job_role_target_id=All&sector=All&service=6452&digital_transformation=All"
    //       >
    //         Zum OC-Arbeitsrechtsteam
    //       </a>
    //     </Trans>
    //   </p>
    // ),
    externalLink: (
      <p>
        <Trans i18nKey="infoExternalLink2">
          <a
            target="_blank"
            rel="noreferrer"
            class="manualButton"
            href="https://www.osborneclarke.com/lawyers?title=&location=6392&field_job_role_target_id=All&sector=All&service=6452&digital_transformation=All"
          >
            Zum OC-Arbeitsrechtsteam
          </a>
        </Trans>
      </p>
    ),
    emailLink: (
      <p>
        <Trans i18nKey="emailLink">
          <a
            class="manualButton"
            href="mailto:severance-calculator@osborneclarke.com?subject=Anfrage Abfindungsrechner/Inquiry severance calculator"
          >
            Schreiben Sie uns!
          </a>
          ,
        </Trans>
      </p>
    ),

    imgStart: true,
    img: ocManBulb,
    alt: "ocManBulb",
    dark: false,
    primary: false,
    darkText: true,
  },
];
