import React, { useState } from "react";
import Video from "../../videos/ocWebsiteVideo.mp4";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";
import { Button } from "../ButtonElement";
import { Trans, useTranslation } from "react-i18next";
import i18next from "../../glossary";

//import { animateScroll as scroll } from "react-scroll";

const HeroSection = (heroSectionContent) => {
  const { t, i18n } = useTranslation();
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  /*   const toggleBottom = () => {
    scroll.scrollToBottom();
  }; */
  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg
          playsInline
          autoPlay={true}
          loop
          controls={false}
          muted={true}
          src={Video}
          type="video/mp4"
        />
      </HeroBg>
      <HeroContent>
        {/* <HeroH1>{heroSectionContent.header}</HeroH1> */}
        <HeroH1>{t("header")}</HeroH1>
        <HeroH1>{t("header2")}</HeroH1>
        {/* <HeroP>{heroSectionContent.description}</HeroP> */}
        <HeroP>{t("intro1")}</HeroP>
        {/* <HeroP>{t("intro2")}</HeroP> */}
        <HeroBtnWrapper>
          <Button
            to="surveys"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            //onClick={toggleBottom}
          >
            {t("toSurveyButton")} {hover ? <ArrowRight /> : <ArrowForward />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
