export const surveySchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  title: "SurveyJS Library json schema",
  type: "object",
  properties: {
    locale: {
      type: "string",
      enum: [""],
    },
    title: {
      type: "string",
    },
    description: {
      type: "string",
    },
    logo: {
      type: "string",
    },
    logoWidth: {
      type: "string",
    },
    logoHeight: {
      type: "string",
    },
    logoFit: {
      type: "string",
      enum: ["none", "contain", "cover", "fill"],
    },
    logoPosition: {
      type: "string",
      enum: ["none", "left", "right", "top", "bottom"],
    },
    focusFirstQuestionAutomatic: {
      type: "boolean",
    },
    focusOnFirstError: {
      type: "boolean",
    },
    completedHtml: {
      type: "string",
    },
    completedBeforeHtml: {
      type: "string",
    },
    completedHtmlOnCondition: {
      type: "array",
      items: {
        $ref: "#htmlconditionitem",
      },
    },
    loadingHtml: {
      type: "string",
    },
    pages: {
      type: "array",
      items: {
        $ref: "#page",
      },
    },
    questions: {
      type: "array",
      items: [
        {
          $ref: "#matrixdropdownbase",
        },
        {
          $ref: "#matrixdropdown",
        },
        {
          $ref: "#matrixdynamic",
        },
        {
          $ref: "#matrix",
        },
        {
          $ref: "#expression",
        },
        {
          $ref: "#checkbox",
        },
        {
          $ref: "#tagbox",
        },
        {
          $ref: "#ranking",
        },
        {
          $ref: "#radiogroup",
        },
        {
          $ref: "#imagepicker",
        },
        {
          $ref: "#buttongroup",
        },
        {
          $ref: "#dropdown",
        },
        {
          $ref: "#textbase",
        },
        {
          $ref: "#text",
        },
        {
          $ref: "#comment",
        },
        {
          $ref: "#multipletext",
        },
        {
          $ref: "#nonvalue",
        },
        {
          $ref: "#html",
        },
        {
          $ref: "#image",
        },
        {
          $ref: "#empty",
        },
        {
          $ref: "#file",
        },
        {
          $ref: "#rating",
        },
        {
          $ref: "#boolean",
        },
        {
          $ref: "#signaturepad",
        },
        {
          $ref: "#paneldynamic",
        },
        {
          $ref: "#panel",
        },
      ],
    },
    triggers: {
      type: "array",
      items: [
        {
          $ref: "#visibletrigger",
        },
        {
          $ref: "#completetrigger",
        },
        {
          $ref: "#setvaluetrigger",
        },
        {
          $ref: "#copyvaluetrigger",
        },
        {
          $ref: "#skiptrigger",
        },
        {
          $ref: "#runexpressiontrigger",
        },
      ],
    },
    calculatedValues: {
      type: "array",
      items: {
        $ref: "#calculatedvalue",
      },
    },
    surveyId: {
      type: "string",
    },
    surveyPostId: {
      type: "string",
    },
    surveyShowDataSaving: {
      type: "boolean",
    },
    cookieName: {
      type: "string",
    },
    sendResultOnPageNext: {
      type: "boolean",
    },
    showNavigationButtons: {
      type: "string",
      enum: ["none", "top", "bottom", "both"],
    },
    showPrevButton: {
      type: "boolean",
    },
    showTitle: {
      type: "boolean",
    },
    showPageTitles: {
      type: "boolean",
    },
    showCompletedPage: {
      type: "boolean",
    },
    navigateToUrl: {
      type: "string",
    },
    navigateToUrlOnCondition: {
      type: "array",
      items: {
        $ref: "#urlconditionitem",
      },
    },
    questionsOrder: {
      type: "string",
      enum: ["initial", "random"],
    },
    showPageNumbers: {
      type: "boolean",
    },
    showQuestionNumbers: {
      type: "string",
      enum: ["on", "onPage", "off"],
    },
    questionTitleLocation: {
      type: "string",
      enum: ["top", "bottom", "left"],
    },
    questionDescriptionLocation: {
      type: "string",
      enum: ["underInput", "underTitle"],
    },
    questionErrorLocation: {
      type: "string",
      enum: ["top", "bottom"],
    },
    showProgressBar: {
      type: "string",
      enum: ["off", "top", "bottom", "both"],
    },
    progressBarType: {
      type: "string",
      enum: [
        "pages",
        "questions",
        "requiredQuestions",
        "correctQuestions",
        "buttons",
      ],
    },
    mode: {
      type: "string",
      enum: ["edit", "display"],
    },
    storeOthersAsComment: {
      type: "boolean",
    },
    maxTextLength: {
      type: "number",
    },
    maxOthersLength: {
      type: "number",
    },
    goNextPageAutomatic: {
      type: "boolean",
    },
    clearInvisibleValues: {
      type: "string",
      enum: ["none", "onComplete", "onHidden", "onHiddenContainer"],
    },
    checkErrorsMode: {
      type: "string",
      enum: ["onNextPage", "onValueChanged", "onValueChanging", "onComplete"],
    },
    textUpdateMode: {
      type: "string",
      enum: ["onBlur", "onTyping"],
    },
    autoGrowComment: {
      type: "boolean",
    },
    startSurveyText: {
      type: "string",
    },
    pagePrevText: {
      type: "string",
    },
    pageNextText: {
      type: "string",
    },
    completeText: {
      type: "string",
    },
    previewText: {
      type: "string",
    },
    editText: {
      type: "string",
    },
    requiredText: {
      type: "string",
    },
    questionStartIndex: {
      type: "string",
    },
    questionTitlePattern: {
      type: "string",
      enum: [],
    },
    questionTitleTemplate: {
      type: "string",
    },
    firstPageIsStarted: {
      type: "boolean",
    },
    isSinglePage: {
      type: "boolean",
    },
    questionsOnPageMode: {
      type: "string",
      enum: ["singlePage", "standard", "questionPerPage"],
    },
    showPreviewBeforeComplete: {
      type: "string",
      enum: ["noPreview", "showAllQuestions", "showAnsweredQuestions"],
    },
    maxTimeToFinish: {
      type: "number",
    },
    maxTimeToFinishPage: {
      type: "number",
    },
    showTimerPanel: {
      type: "string",
      enum: ["none", "top", "bottom"],
    },
    showTimerPanelMode: {
      type: "string",
      enum: ["all", "page", "survey"],
    },
    widthMode: {
      type: "string",
      enum: ["auto", "static", "responsive"],
    },
    width: {
      type: "string",
    },
    showBrandInfo: {
      type: "boolean",
    },
  },
  definitions: {
    expressionitem: {
      type: "object",
      $id: "#expressionitem",
      properties: {
        expression: {
          type: "string",
        },
      },
    },
    htmlconditionitem: {
      type: "object",
      $id: "#htmlconditionitem",
      allOff: [
        {
          $ref: "#expressionitem",
        },
        {
          properties: {
            html: {
              type: "string",
            },
          },
        },
      ],
    },
    panelbase: {
      type: "object",
      $id: "#panelbase",
      properties: {
        name: {
          type: "string",
        },
        elements: {
          type: "array",
          items: [
            {
              $ref: "#matrixdropdownbase",
            },
            {
              $ref: "#matrixdropdown",
            },
            {
              $ref: "#matrixdynamic",
            },
            {
              $ref: "#matrix",
            },
            {
              $ref: "#expression",
            },
            {
              $ref: "#checkbox",
            },
            {
              $ref: "#tagbox",
            },
            {
              $ref: "#ranking",
            },
            {
              $ref: "#radiogroup",
            },
            {
              $ref: "#imagepicker",
            },
            {
              $ref: "#buttongroup",
            },
            {
              $ref: "#dropdown",
            },
            {
              $ref: "#textbase",
            },
            {
              $ref: "#text",
            },
            {
              $ref: "#comment",
            },
            {
              $ref: "#multipletext",
            },
            {
              $ref: "#nonvalue",
            },
            {
              $ref: "#html",
            },
            {
              $ref: "#image",
            },
            {
              $ref: "#empty",
            },
            {
              $ref: "#file",
            },
            {
              $ref: "#rating",
            },
            {
              $ref: "#boolean",
            },
            {
              $ref: "#signaturepad",
            },
            {
              $ref: "#paneldynamic",
            },
            {
              $ref: "#panel",
            },
          ],
        },
        visible: {
          type: "string",
        },
        visibleIf: {
          type: "string",
        },
        enableIf: {
          type: "string",
        },
        requiredIf: {
          type: "string",
        },
        readOnly: {
          type: "boolean",
        },
        questionTitleLocation: {
          type: "string",
          enum: ["default", "top", "bottom", "left", "hidden"],
        },
        title: {
          type: "string",
        },
        description: {
          type: "string",
        },
        questionsOrder: {
          type: "string",
          enum: ["default", "initial", "random"],
        },
      },
    },
    question: {
      type: "object",
      $id: "#question",
      properties: {
        name: {
          type: "string",
        },
        state: {
          type: "string",
          enum: ["default", "collapsed", "expanded"],
        },
        visible: {
          type: "string",
        },
        useDisplayValuesInDynamicTexts: {
          type: "boolean",
        },
        visibleIf: {
          type: "string",
        },
        width: {
          type: "string",
        },
        minWidth: {
          type: "string",
        },
        maxWidth: {
          type: "string",
        },
        startWithNewLine: {
          type: "boolean",
        },
        indent: {
          type: "number",
          enum: [0, 1, 2, 3],
        },
        page: {
          type: "string",
          enum: [],
        },
        title: {
          type: "string",
        },
        titleLocation: {
          type: "string",
          enum: ["default", "top", "bottom", "left", "hidden"],
        },
        description: {
          type: "string",
        },
        descriptionLocation: {
          type: "string",
          enum: ["default", "underInput", "underTitle"],
        },
        hideNumber: {
          type: "boolean",
        },
        valueName: {
          type: "string",
        },
        enableIf: {
          type: "string",
        },
        defaultValue: {
          type: "string",
        },
        defaultValueExpression: {
          type: "string",
        },
        correctAnswer: {
          type: "string",
        },
        clearIfInvisible: {
          type: "string",
          enum: ["default", "none", "onComplete", "onHidden"],
        },
        isRequired: {
          type: "string",
        },
        requiredIf: {
          type: "string",
        },
        requiredErrorText: {
          type: "string",
        },
        readOnly: {
          type: "string",
        },
        validators: {
          type: "array",
          items: [
            {
              $ref: "#numericvalidator",
            },
            {
              $ref: "#textvalidator",
            },
            {
              $ref: "#answercountvalidator",
            },
            {
              $ref: "#regexvalidator",
            },
            {
              $ref: "#emailvalidator",
            },
            {
              $ref: "#expressionvalidator",
            },
          ],
        },
        bindings: {
          type: "string",
        },
        renderAs: {
          type: "string",
        },
        showCommentArea: {
          type: "string",
        },
        commentText: {
          type: "string",
        },
        commentPlaceholder: {
          type: "string",
        },
        popupdescription: {
          type: "string",
        },
      },
    },
    surveyvalidator: {
      type: "object",
      $id: "#surveyvalidator",
      properties: {
        text: {
          type: "string",
        },
      },
    },
    numericvalidator: {
      type: "object",
      $id: "#numericvalidator",
      allOff: [
        {
          $ref: "#surveyvalidator",
        },
        {
          properties: {
            minValue: {
              type: "number",
            },
            maxValue: {
              type: "number",
            },
          },
        },
      ],
    },
    textvalidator: {
      type: "object",
      $id: "#textvalidator",
      allOff: [
        {
          $ref: "#surveyvalidator",
        },
        {
          properties: {
            minLength: {
              type: "number",
            },
            maxLength: {
              type: "number",
            },
            allowDigits: {
              type: "boolean",
            },
          },
        },
      ],
    },
    answercountvalidator: {
      type: "object",
      $id: "#answercountvalidator",
      allOff: [
        {
          $ref: "#surveyvalidator",
        },
        {
          properties: {
            minCount: {
              type: "number",
            },
            maxCount: {
              type: "number",
            },
          },
        },
      ],
    },
    regexvalidator: {
      type: "object",
      $id: "#regexvalidator",
      allOff: [
        {
          $ref: "#surveyvalidator",
        },
        {
          properties: {
            regex: {
              type: "string",
            },
          },
        },
      ],
    },
    emailvalidator: {
      type: "object",
      $id: "#emailvalidator",
      allOff: [
        {
          $ref: "#surveyvalidator",
        },
        {
          properties: {},
        },
      ],
    },
    expressionvalidator: {
      type: "object",
      $id: "#expressionvalidator",
      allOff: [
        {
          $ref: "#surveyvalidator",
        },
        {
          properties: {
            expression: {
              type: "string",
            },
          },
        },
      ],
    },
    matrixbase: {
      type: "object",
      $id: "#matrixbase",
      allOff: [
        {
          $ref: "#question",
        },
        {
          properties: {
            showCommentArea: {
              type: "string",
            },
            columnsVisibleIf: {
              type: "string",
            },
            rowsVisibleIf: {
              type: "string",
            },
            columnMinWidth: {
              type: "string",
            },
            showHeader: {
              type: "boolean",
            },
            verticalAlign: {
              type: "string",
              enum: ["top", "middle"],
            },
            alternateRows: {
              type: "boolean",
            },
          },
        },
      ],
    },
    matrixdropdownbase: {
      type: "object",
      $id: "#matrixdropdownbase",
      allOff: [
        {
          $ref: "#matrixbase",
        },
        {
          properties: {
            columns: {
              type: "array",
              items: {
                $ref: "#matrixdropdowncolumn",
              },
            },
            columnLayout: {
              type: "string",
              enum: ["horizontal", "vertical"],
            },
            detailElements: {
              type: "string",
            },
            detailPanelMode: {
              type: "string",
              enum: ["none", "underRow", "underRowSingle"],
            },
            horizontalScroll: {
              type: "boolean",
            },
            choices: {
              type: "array",
              items: {
                $ref: "#itemvalue",
              },
            },
            placeholder: {
              type: "string",
            },
            keyDuplicationError: {
              type: "string",
            },
            cellType: {
              type: "string",
              enum: [
                "dropdown",
                "checkbox",
                "radiogroup",
                "tagbox",
                "text",
                "comment",
                "boolean",
                "expression",
                "rating",
              ],
            },
            columnColCount: {
              type: "string",
              enum: [0, 1, 2, 3, 4],
            },
            columnMinWidth: {
              type: "string",
            },
            allowAdaptiveActions: {
              type: "boolean",
            },
          },
        },
      ],
    },
    matrixdropdowncolumn: {
      type: "object",
      $id: "#matrixdropdowncolumn",
      properties: {
        name: {
          type: "string",
        },
        title: {
          type: "string",
        },
        cellHint: {
          type: "string",
        },
        cellType: {
          type: "string",
          enum: [
            "default",
            "dropdown",
            "checkbox",
            "radiogroup",
            "tagbox",
            "text",
            "comment",
            "boolean",
            "expression",
            "rating",
          ],
        },
        colCount: {
          type: "string",
          enum: [-1, 0, 1, 2, 3, 4],
        },
        isRequired: {
          type: "boolean",
        },
        isUnique: {
          type: "boolean",
        },
        requiredErrorText: {
          type: "string",
        },
        readOnly: {
          type: "boolean",
        },
        minWidth: {
          type: "string",
        },
        width: {
          type: "string",
        },
        visibleIf: {
          type: "string",
        },
        enableIf: {
          type: "string",
        },
        requiredIf: {
          type: "string",
        },
        showInMultipleColumns: {
          type: "boolean",
        },
        validators: {
          type: "array",
          items: [
            {
              $ref: "#numericvalidator",
            },
            {
              $ref: "#textvalidator",
            },
            {
              $ref: "#answercountvalidator",
            },
            {
              $ref: "#regexvalidator",
            },
            {
              $ref: "#emailvalidator",
            },
            {
              $ref: "#expressionvalidator",
            },
          ],
        },
        totalType: {
          type: "string",
          enum: ["none", "sum", "count", "min", "max", "avg"],
        },
        totalExpression: {
          type: "string",
        },
        totalFormat: {
          type: "string",
        },
        totalDisplayStyle: {
          type: "string",
          enum: ["none", "decimal", "currency", "percent"],
        },
        totalCurrency: {
          type: "string",
          enum: [
            "AED",
            "AFN",
            "ALL",
            "AMD",
            "ANG",
            "AOA",
            "ARS",
            "AUD",
            "AWG",
            "AZN",
            "BAM",
            "BBD",
            "BDT",
            "BGN",
            "BHD",
            "BIF",
            "BMD",
            "BND",
            "BOB",
            "BOV",
            "BRL",
            "BSD",
            "BTN",
            "BWP",
            "BYN",
            "BZD",
            "CAD",
            "CDF",
            "CHE",
            "CHF",
            "CHW",
            "CLF",
            "CLP",
            "CNY",
            "COP",
            "COU",
            "CRC",
            "CUC",
            "CUP",
            "CVE",
            "CZK",
            "DJF",
            "DKK",
            "DOP",
            "DZD",
            "EGP",
            "ERN",
            "ETB",
            "EUR",
            "FJD",
            "FKP",
            "GBP",
            "GEL",
            "GHS",
            "GIP",
            "GMD",
            "GNF",
            "GTQ",
            "GYD",
            "HKD",
            "HNL",
            "HRK",
            "HTG",
            "HUF",
            "IDR",
            "ILS",
            "INR",
            "IQD",
            "IRR",
            "ISK",
            "JMD",
            "JOD",
            "JPY",
            "KES",
            "KGS",
            "KHR",
            "KMF",
            "KPW",
            "KRW",
            "KWD",
            "KYD",
            "KZT",
            "LAK",
            "LBP",
            "LKR",
            "LRD",
            "LSL",
            "LYD",
            "MAD",
            "MDL",
            "MGA",
            "MKD",
            "MMK",
            "MNT",
            "MOP",
            "MRO",
            "MUR",
            "MVR",
            "MWK",
            "MXN",
            "MXV",
            "MYR",
            "MZN",
            "NAD",
            "NGN",
            "NIO",
            "NOK",
            "NPR",
            "NZD",
            "OMR",
            "PAB",
            "PEN",
            "PGK",
            "PHP",
            "PKR",
            "PLN",
            "PYG",
            "QAR",
            "RON",
            "RSD",
            "RUB",
            "RWF",
            "SAR",
            "SBD",
            "SCR",
            "SDG",
            "SEK",
            "SGD",
            "SHP",
            "SLL",
            "SOS",
            "SRD",
            "SSP",
            "STD",
            "SVC",
            "SYP",
            "SZL",
            "THB",
            "TJS",
            "TMT",
            "TND",
            "TOP",
            "TRY",
            "TTD",
            "TWD",
            "TZS",
            "UAH",
            "UGX",
            "USD",
            "USN",
            "UYI",
            "UYU",
            "UZS",
            "VEF",
            "VND",
            "VUV",
            "WST",
            "XAF",
            "XAG",
            "XAU",
            "XBA",
            "XBB",
            "XBC",
            "XBD",
            "XCD",
            "XDR",
            "XOF",
            "XPD",
            "XPF",
            "XPT",
            "XSU",
            "XTS",
            "XUA",
            "XXX",
            "YER",
            "ZAR",
            "ZMW",
            "ZWL",
          ],
        },
        totalMaximumFractionDigits: {
          type: "number",
        },
        totalMinimumFractionDigits: {
          type: "number",
        },
        renderAs: {
          type: "string",
        },
      },
    },
    itemvalue: {
      type: "object",
      $id: "#itemvalue",
      properties: {
        value: {
          type: "string",
        },
        text: {
          type: "string",
        },
        visibleIf: {
          type: "string",
        },
        enableIf: {
          type: "string",
        },
      },
    },
    matrixdropdown: {
      type: "object",
      $id: "#matrixdropdown",
      allOff: [
        {
          $ref: "#matrixdropdownbase",
        },
        {
          properties: {
            rows: {
              type: "array",
              items: {
                $ref: "#itemvalue",
              },
            },
            rowsVisibleIf: {
              type: "string",
            },
            rowTitleWidth: {
              type: "string",
            },
            totalText: {
              type: "string",
            },
            hideIfRowsEmpty: {
              type: "boolean",
            },
          },
        },
      ],
    },
    matrixdynamic: {
      type: "object",
      $id: "#matrixdynamic",
      allOff: [
        {
          $ref: "#matrixdropdownbase",
        },
        {
          properties: {
            rowsVisibleIf: {
              type: "string",
            },
            allowAddRows: {
              type: "boolean",
            },
            allowRemoveRows: {
              type: "boolean",
            },
            rowCount: {
              type: "number",
            },
            minRowCount: {
              type: "number",
            },
            maxRowCount: {
              type: "number",
            },
            keyName: {
              type: "string",
            },
            defaultRowValue: {
              type: "string",
            },
            defaultValueFromLastRow: {
              type: "boolean",
            },
            confirmDelete: {
              type: "boolean",
            },
            confirmDeleteText: {
              type: "string",
            },
            addRowLocation: {
              type: "string",
              enum: ["default", "top", "bottom", "topBottom"],
            },
            addRowText: {
              type: "string",
            },
            removeRowText: {
              type: "string",
            },
            hideColumnsIfEmpty: {
              type: "boolean",
            },
            emptyRowsText: {
              type: "string",
            },
            detailPanelShowOnAdding: {
              type: "boolean",
            },
            allowRowsDragAndDrop: {
              type: "string",
            },
          },
        },
      ],
    },
    matrix: {
      type: "object",
      $id: "#matrix",
      allOff: [
        {
          $ref: "#matrixbase",
        },
        {
          properties: {
            rowTitleWidth: {
              type: "string",
            },
            columns: {
              type: "array",
              items: {
                $ref: "#itemvalue",
              },
            },
            rows: {
              type: "array",
              items: {
                $ref: "#itemvalue",
              },
            },
            cells: {
              type: "string",
            },
            rowsOrder: {
              type: "string",
              enum: ["initial", "random"],
            },
            isAllRowRequired: {
              type: "boolean",
            },
            hideIfRowsEmpty: {
              type: "boolean",
            },
          },
        },
      ],
    },
    expression: {
      type: "object",
      $id: "#expression",
      allOff: [
        {
          $ref: "#question",
        },
        {
          properties: {
            expression: {
              type: "string",
            },
            format: {
              type: "string",
            },
            displayStyle: {
              type: "string",
              enum: ["none", "decimal", "currency", "percent", "date"],
            },
            currency: {
              type: "string",
              enum: [
                "AED",
                "AFN",
                "ALL",
                "AMD",
                "ANG",
                "AOA",
                "ARS",
                "AUD",
                "AWG",
                "AZN",
                "BAM",
                "BBD",
                "BDT",
                "BGN",
                "BHD",
                "BIF",
                "BMD",
                "BND",
                "BOB",
                "BOV",
                "BRL",
                "BSD",
                "BTN",
                "BWP",
                "BYN",
                "BZD",
                "CAD",
                "CDF",
                "CHE",
                "CHF",
                "CHW",
                "CLF",
                "CLP",
                "CNY",
                "COP",
                "COU",
                "CRC",
                "CUC",
                "CUP",
                "CVE",
                "CZK",
                "DJF",
                "DKK",
                "DOP",
                "DZD",
                "EGP",
                "ERN",
                "ETB",
                "EUR",
                "FJD",
                "FKP",
                "GBP",
                "GEL",
                "GHS",
                "GIP",
                "GMD",
                "GNF",
                "GTQ",
                "GYD",
                "HKD",
                "HNL",
                "HRK",
                "HTG",
                "HUF",
                "IDR",
                "ILS",
                "INR",
                "IQD",
                "IRR",
                "ISK",
                "JMD",
                "JOD",
                "JPY",
                "KES",
                "KGS",
                "KHR",
                "KMF",
                "KPW",
                "KRW",
                "KWD",
                "KYD",
                "KZT",
                "LAK",
                "LBP",
                "LKR",
                "LRD",
                "LSL",
                "LYD",
                "MAD",
                "MDL",
                "MGA",
                "MKD",
                "MMK",
                "MNT",
                "MOP",
                "MRO",
                "MUR",
                "MVR",
                "MWK",
                "MXN",
                "MXV",
                "MYR",
                "MZN",
                "NAD",
                "NGN",
                "NIO",
                "NOK",
                "NPR",
                "NZD",
                "OMR",
                "PAB",
                "PEN",
                "PGK",
                "PHP",
                "PKR",
                "PLN",
                "PYG",
                "QAR",
                "RON",
                "RSD",
                "RUB",
                "RWF",
                "SAR",
                "SBD",
                "SCR",
                "SDG",
                "SEK",
                "SGD",
                "SHP",
                "SLL",
                "SOS",
                "SRD",
                "SSP",
                "STD",
                "SVC",
                "SYP",
                "SZL",
                "THB",
                "TJS",
                "TMT",
                "TND",
                "TOP",
                "TRY",
                "TTD",
                "TWD",
                "TZS",
                "UAH",
                "UGX",
                "USD",
                "USN",
                "UYI",
                "UYU",
                "UZS",
                "VEF",
                "VND",
                "VUV",
                "WST",
                "XAF",
                "XAG",
                "XAU",
                "XBA",
                "XBB",
                "XBC",
                "XBD",
                "XCD",
                "XDR",
                "XOF",
                "XPD",
                "XPF",
                "XPT",
                "XSU",
                "XTS",
                "XUA",
                "XXX",
                "YER",
                "ZAR",
                "ZMW",
                "ZWL",
              ],
            },
            maximumFractionDigits: {
              type: "number",
            },
            minimumFractionDigits: {
              type: "number",
            },
            useGrouping: {
              type: "boolean",
            },
            enableIf: {
              type: "string",
            },
            isRequired: {
              type: "string",
            },
            readOnly: {
              type: "string",
            },
            requiredErrorText: {
              type: "string",
            },
            defaultValueExpression: {
              type: "string",
            },
            defaultValue: {
              type: "string",
            },
            correctAnswer: {
              type: "string",
            },
            requiredIf: {
              type: "string",
            },
          },
        },
      ],
    },
    selectbase: {
      type: "object",
      $id: "#selectbase",
      allOff: [
        {
          $ref: "#question",
        },
        {
          properties: {
            showCommentArea: {
              type: "string",
            },
            choicesFromQuestion: {
              type: "string",
            },
            choices: {
              type: "array",
              items: {
                $ref: "#itemvalue",
              },
            },
            choicesFromQuestionMode: {
              type: "string",
              enum: ["all", "selected", "unselected"],
            },
            choicesOrder: {
              type: "string",
              enum: ["none", "asc", "desc", "random"],
            },
            choicesByUrl: {
              type: "array",
              items: {
                $ref: "#ChoicesRestful",
              },
            },
            hideIfChoicesEmpty: {
              type: "boolean",
            },
            choicesVisibleIf: {
              type: "string",
            },
            choicesEnableIf: {
              type: "string",
            },
            separateSpecialChoices: {
              type: "boolean",
            },
            showOtherItem: {
              type: "boolean",
            },
            showNoneItem: {
              type: "boolean",
            },
            otherPlaceholder: {
              type: "string",
            },
            noneText: {
              type: "string",
            },
            otherText: {
              type: "string",
            },
            otherErrorText: {
              type: "string",
            },
            storeOthersAsComment: {
              type: "string",
              enum: ["default", true, false],
            },
          },
        },
      ],
    },
    checkboxbase: {
      type: "object",
      $id: "#checkboxbase",
      allOff: [
        {
          $ref: "#selectbase",
        },
        {
          properties: {
            colCount: {
              type: "number",
              enum: [0, 1, 2, 3, 4, 5],
            },
          },
        },
      ],
    },
    checkbox: {
      type: "object",
      $id: "#checkbox",
      allOff: [
        {
          $ref: "#checkboxbase",
        },
        {
          properties: {
            showSelectAllItem: {
              type: "boolean",
            },
            separateSpecialChoices: {
              type: "boolean",
            },
            maxSelectedChoices: {
              type: "number",
            },
            selectAllText: {
              type: "string",
            },
            valuePropertyName: {
              type: "string",
            },
            itemComponent: {
              type: "string",
            },
          },
        },
      ],
    },
    tagbox: {
      type: "object",
      $id: "#tagbox",
      allOff: [
        {
          $ref: "#checkbox",
        },
        {
          properties: {
            placeholder: {
              type: "string",
            },
            allowClear: {
              type: "boolean",
            },
            searchEnabled: {
              type: "boolean",
            },
            choicesLazyLoadEnabled: {
              type: "boolean",
            },
            choicesLazyLoadPageSize: {
              type: "number",
            },
            hideSelectedItems: {
              type: "boolean",
            },
            closeOnSelect: {
              type: "boolean",
            },
            itemComponent: {
              type: "string",
            },
          },
        },
      ],
    },
    ranking: {
      type: "object",
      $id: "#ranking",
      allOff: [
        {
          $ref: "#checkbox",
        },
        {
          properties: {
            showOtherItem: {
              type: "string",
            },
            otherText: {
              type: "string",
            },
            otherErrorText: {
              type: "string",
            },
            storeOthersAsComment: {
              type: "string",
            },
            showNoneItem: {
              type: "string",
            },
            noneText: {
              type: "string",
            },
            showSelectAllItem: {
              type: "string",
            },
            selectAllText: {
              type: "string",
            },
            colCount: {
              type: "number",
            },
            maxSelectedChoices: {
              type: "string",
            },
            longTap: {
              type: "string",
            },
            itemComponent: {
              type: "string",
            },
          },
        },
      ],
    },
    radiogroup: {
      type: "object",
      $id: "#radiogroup",
      allOff: [
        {
          $ref: "#checkboxbase",
        },
        {
          properties: {
            showClearButton: {
              type: "boolean",
            },
            separateSpecialChoices: {
              type: "string",
            },
            itemComponent: {
              type: "string",
            },
          },
        },
      ],
    },
    imagepicker: {
      type: "object",
      $id: "#imagepicker",
      allOff: [
        {
          $ref: "#checkboxbase",
        },
        {
          properties: {
            showOtherItem: {
              type: "boolean",
            },
            otherText: {
              type: "string",
            },
            showNoneItem: {
              type: "boolean",
            },
            noneText: {
              type: "string",
            },
            optionsCaption: {
              type: "string",
            },
            otherErrorText: {
              type: "string",
            },
            storeOthersAsComment: {
              type: "string",
              enum: ["default", true, false],
            },
            contentMode: {
              type: "string",
              enum: ["image", "video"],
            },
            imageFit: {
              type: "string",
              enum: ["none", "contain", "cover", "fill"],
            },
            imageHeight: {
              type: "number",
            },
            imageWidth: {
              type: "number",
            },
            minImageWidth: {
              type: "string",
            },
            minImageHeight: {
              type: "string",
            },
            maxImageWidth: {
              type: "string",
            },
            maxImageHeight: {
              type: "string",
            },
            showLabel: {
              type: "boolean",
            },
            colCount: {
              type: "number",
              enum: [0, 1, 2, 3, 4, 5],
            },
            multiSelect: {
              type: "boolean",
            },
            choices: {
              type: "array",
              items: {
                $ref: "#imageitemvalue",
              },
            },
          },
        },
      ],
    },
    imageitemvalue: {
      type: "object",
      $id: "#imageitemvalue",
      allOff: [
        {
          $ref: "#itemvalue",
        },
        {
          properties: {
            imageLink: {
              type: "string",
            },
          },
        },
      ],
    },
    buttongroup: {
      type: "object",
      $id: "#buttongroup",
      allOff: [
        {
          $ref: "#checkboxbase",
        },
        {
          properties: {
            choices: {
              type: "array",
              items: {
                $ref: "#buttongroupitemvalue",
              },
            },
          },
        },
      ],
    },
    buttongroupitemvalue: {
      type: "object",
      $id: "#buttongroupitemvalue",
      allOff: [
        {
          $ref: "#itemvalue",
        },
        {
          properties: {
            showCaption: {
              type: "boolean",
            },
            iconName: {
              type: "string",
            },
            iconSize: {
              type: "number",
            },
          },
        },
      ],
    },
    dropdown: {
      type: "object",
      $id: "#dropdown",
      allOff: [
        {
          $ref: "#selectbase",
        },
        {
          properties: {
            placeholder: {
              type: "string",
            },
            allowClear: {
              type: "boolean",
            },
            choicesMin: {
              type: "number",
            },
            choicesMax: {
              type: "number",
            },
            choicesStep: {
              type: "number",
            },
            autocomplete: {
              type: "string",
              enum: [
                "name",
                "honorific-prefix",
                "given-name",
                "additional-name",
                "family-name",
                "honorific-suffix",
                "nickname",
                "organization-title",
                "username",
                "new-password",
                "current-password",
                "organization",
                "street-address",
                "address-line1",
                "address-line2",
                "address-line3",
                "address-level4",
                "address-level3",
                "address-level2",
                "address-level1",
                "country",
                "country-name",
                "postal-code",
                "cc-name",
                "cc-given-name",
                "cc-additional-name",
                "cc-family-name",
                "cc-number",
                "cc-exp",
                "cc-exp-month",
                "cc-exp-year",
                "cc-csc",
                "cc-type",
                "transaction-currency",
                "transaction-amount",
                "language",
                "bday",
                "bday-day",
                "bday-month",
                "bday-year",
                "sex",
                "url",
                "photo",
                "tel",
                "tel-country-code",
                "tel-national",
                "tel-area-code",
                "tel-local",
                "tel-local-prefix",
                "tel-local-suffix",
                "tel-extension",
                "email",
                "impp",
              ],
            },
            renderAs: {
              type: "string",
            },
            searchEnabled: {
              type: "boolean",
            },
            choicesLazyLoadEnabled: {
              type: "boolean",
            },
            choicesLazyLoadPageSize: {
              type: "number",
            },
            inputFieldComponent: {
              type: "string",
            },
            itemComponent: {
              type: "string",
            },
          },
        },
      ],
    },
    textbase: {
      type: "object",
      $id: "#textbase",
      allOff: [
        {
          $ref: "#question",
        },
        {
          properties: {},
        },
      ],
    },
    text: {
      type: "object",
      $id: "#text",
      allOff: [
        {
          $ref: "#textbase",
        },
        {
          properties: {
            inputType: {
              type: "string",
              enum: [
                "color",
                "date",
                "datetime",
                "datetime-local",
                "email",
                "month",
                "number",
                "password",
                "range",
                "tel",
                "text",
                "time",
                "url",
                "week",
              ],
            },
            size: {
              type: "number",
            },
            textUpdateMode: {
              type: "string",
              enum: ["default", "onBlur", "onTyping"],
            },
            autocomplete: {
              type: "string",
              enum: [
                "name",
                "honorific-prefix",
                "given-name",
                "additional-name",
                "family-name",
                "honorific-suffix",
                "nickname",
                "organization-title",
                "username",
                "new-password",
                "current-password",
                "organization",
                "street-address",
                "address-line1",
                "address-line2",
                "address-line3",
                "address-level4",
                "address-level3",
                "address-level2",
                "address-level1",
                "country",
                "country-name",
                "postal-code",
                "cc-name",
                "cc-given-name",
                "cc-additional-name",
                "cc-family-name",
                "cc-number",
                "cc-exp",
                "cc-exp-month",
                "cc-exp-year",
                "cc-csc",
                "cc-type",
                "transaction-currency",
                "transaction-amount",
                "language",
                "bday",
                "bday-day",
                "bday-month",
                "bday-year",
                "sex",
                "url",
                "photo",
                "tel",
                "tel-country-code",
                "tel-national",
                "tel-area-code",
                "tel-local",
                "tel-local-prefix",
                "tel-local-suffix",
                "tel-extension",
                "email",
                "impp",
              ],
            },
            min: {
              type: "string",
            },
            max: {
              type: "string",
            },
            minValueExpression: {
              type: "string",
            },
            maxValueExpression: {
              type: "string",
            },
            minErrorText: {
              type: "string",
            },
            maxErrorText: {
              type: "string",
            },
            step: {
              type: "number",
            },
            maxLength: {
              type: "number",
            },
            placeholder: {
              type: "string",
            },
            dataList: {
              type: "array",
              items: {
                $ref: "#string",
              },
            },
          },
        },
      ],
    },
    comment: {
      type: "object",
      $id: "#comment",
      allOff: [
        {
          $ref: "#textbase",
        },
        {
          properties: {
            maxLength: {
              type: "number",
            },
            cols: {
              type: "number",
            },
            rows: {
              type: "number",
            },
            placeholder: {
              type: "string",
            },
            textUpdateMode: {
              type: "string",
              enum: ["default", "onBlur", "onTyping"],
            },
            autoGrow: {
              type: "boolean",
            },
            acceptCarriageReturn: {
              type: "boolean",
            },
          },
        },
      ],
    },
    multipletext: {
      type: "object",
      $id: "#multipletext",
      allOff: [
        {
          $ref: "#question",
        },
        {
          properties: {
            items: {
              type: "array",
              items: {
                $ref: "#multipletextitem",
              },
            },
            itemSize: {
              type: "number",
            },
            colCount: {
              type: "number",
              enum: [1, 2, 3, 4, 5],
            },
          },
        },
      ],
    },
    multipletextitem: {
      type: "object",
      $id: "#multipletextitem",
      properties: {
        name: {
          type: "string",
        },
        isRequired: {
          type: "boolean",
        },
        placeholder: {
          type: "string",
        },
        inputType: {
          type: "string",
          enum: [
            "color",
            "date",
            "datetime",
            "datetime-local",
            "email",
            "month",
            "number",
            "password",
            "range",
            "tel",
            "text",
            "time",
            "url",
            "week",
          ],
        },
        title: {
          type: "string",
        },
        maxLength: {
          type: "number",
        },
        size: {
          type: "number",
        },
        requiredErrorText: {
          type: "string",
        },
        validators: {
          type: "array",
          items: [
            {
              $ref: "#numericvalidator",
            },
            {
              $ref: "#textvalidator",
            },
            {
              $ref: "#answercountvalidator",
            },
            {
              $ref: "#regexvalidator",
            },
            {
              $ref: "#emailvalidator",
            },
            {
              $ref: "#expressionvalidator",
            },
          ],
        },
      },
    },
    nonvalue: {
      type: "object",
      $id: "#nonvalue",
      allOff: [
        {
          $ref: "#question",
        },
        {
          properties: {
            title: {
              type: "string",
            },
            description: {
              type: "string",
            },
            valueName: {
              type: "string",
            },
            enableIf: {
              type: "string",
            },
            defaultValue: {
              type: "string",
            },
            correctAnswer: {
              type: "string",
            },
            clearIfInvisible: {
              type: "string",
              enum: ["default", "none", "onComplete", "onHidden"],
            },
            isRequired: {
              type: "string",
            },
            requiredErrorText: {
              type: "string",
            },
            readOnly: {
              type: "string",
            },
            requiredIf: {
              type: "string",
            },
            validators: {
              type: "array",
              items: [
                {
                  $ref: "#numericvalidator",
                },
                {
                  $ref: "#textvalidator",
                },
                {
                  $ref: "#answercountvalidator",
                },
                {
                  $ref: "#regexvalidator",
                },
                {
                  $ref: "#emailvalidator",
                },
                {
                  $ref: "#expressionvalidator",
                },
              ],
            },
            titleLocation: {
              type: "string",
              enum: ["default", "top", "bottom", "left", "hidden"],
            },
            showCommentArea: {
              type: "string",
            },
            useDisplayValuesInDynamicTexts: {
              type: "boolean",
            },
          },
        },
      ],
    },
    html: {
      type: "object",
      $id: "#html",
      allOff: [
        {
          $ref: "#nonvalue",
        },
        {
          properties: {
            html: {
              type: "string",
            },
          },
        },
      ],
    },
    image: {
      type: "object",
      $id: "#image",
      allOff: [
        {
          $ref: "#nonvalue",
        },
        {
          properties: {
            imageLink: {
              type: "string",
            },
            altText: {
              type: "string",
            },
            contentMode: {
              type: "string",
              enum: ["auto", "image", "video", "youtube"],
            },
            imageFit: {
              type: "string",
              enum: ["none", "contain", "cover", "fill"],
            },
            imageHeight: {
              type: "number",
            },
            imageWidth: {
              type: "number",
            },
          },
        },
      ],
    },
    empty: {
      type: "object",
      $id: "#empty",
      allOff: [
        {
          $ref: "#question",
        },
        {
          properties: {},
        },
      ],
    },
    file: {
      type: "object",
      $id: "#file",
      allOff: [
        {
          $ref: "#question",
        },
        {
          properties: {
            showCommentArea: {
              type: "string",
            },
            showPreview: {
              type: "boolean",
            },
            allowMultiple: {
              type: "boolean",
            },
            allowImagesPreview: {
              type: "boolean",
            },
            imageHeight: {
              type: "string",
            },
            imageWidth: {
              type: "string",
            },
            acceptedTypes: {
              type: "string",
            },
            storeDataAsText: {
              type: "boolean",
            },
            waitForUpload: {
              type: "boolean",
            },
            maxSize: {
              type: "number",
            },
            defaultValue: {
              type: "string",
            },
            correctAnswer: {
              type: "string",
            },
            validators: {
              type: "string",
            },
            needConfirmRemoveFile: {
              type: "boolean",
            },
          },
        },
      ],
    },
    rating: {
      type: "object",
      $id: "#rating",
      allOff: [
        {
          $ref: "#question",
        },
        {
          properties: {
            showCommentArea: {
              type: "string",
            },
            rateValues: {
              type: "array",
              items: {
                $ref: "#itemvalue",
              },
            },
            rateMin: {
              type: "number",
            },
            rateMax: {
              type: "number",
            },
            rateStep: {
              type: "number",
            },
            minRateDescription: {
              type: "string",
            },
            maxRateDescription: {
              type: "string",
            },
            displayRateDescriptionsAsExtremeItems: {
              type: "boolean",
            },
            displayMode: {
              type: "string",
              enum: ["auto", "buttons", "dropdown"],
            },
          },
        },
      ],
    },
    boolean: {
      type: "object",
      $id: "#boolean",
      allOff: [
        {
          $ref: "#question",
        },
        {
          properties: {
            showCommentArea: {
              type: "string",
            },
            label: {
              type: "string",
            },
            labelTrue: {
              type: "string",
            },
            labelFalse: {
              type: "string",
            },
            valueTrue: {
              type: "string",
            },
            valueFalse: {
              type: "string",
            },
            renderAs: {
              type: "string",
            },
          },
        },
      ],
    },
    signaturepad: {
      type: "object",
      $id: "#signaturepad",
      allOff: [
        {
          $ref: "#question",
        },
        {
          properties: {
            signatureWidth: {
              type: "number",
            },
            signatureHeight: {
              type: "number",
            },
            height: {
              type: "number",
            },
            allowClear: {
              type: "boolean",
            },
            penColor: {
              type: "string",
            },
            backgroundColor: {
              type: "string",
            },
            dataFormat: {
              type: "string",
              enum: [
                {
                  value: "",
                  text: "PNG",
                },
                {
                  value: "image/jpeg",
                  text: "JPEG",
                },
                {
                  value: "image/svg+xml",
                  text: "SVG",
                },
              ],
            },
            defaultValue: {
              type: "string",
            },
            correctAnswer: {
              type: "string",
            },
          },
        },
      ],
    },
    paneldynamic: {
      type: "object",
      $id: "#paneldynamic",
      allOff: [
        {
          $ref: "#question",
        },
        {
          properties: {
            showCommentArea: {
              type: "string",
            },
            templateElements: {
              type: "array",
              items: [
                {
                  $ref: "#matrixdropdownbase",
                },
                {
                  $ref: "#matrixdropdown",
                },
                {
                  $ref: "#matrixdynamic",
                },
                {
                  $ref: "#matrix",
                },
                {
                  $ref: "#expression",
                },
                {
                  $ref: "#checkbox",
                },
                {
                  $ref: "#tagbox",
                },
                {
                  $ref: "#ranking",
                },
                {
                  $ref: "#radiogroup",
                },
                {
                  $ref: "#imagepicker",
                },
                {
                  $ref: "#buttongroup",
                },
                {
                  $ref: "#dropdown",
                },
                {
                  $ref: "#textbase",
                },
                {
                  $ref: "#text",
                },
                {
                  $ref: "#comment",
                },
                {
                  $ref: "#multipletext",
                },
                {
                  $ref: "#nonvalue",
                },
                {
                  $ref: "#html",
                },
                {
                  $ref: "#image",
                },
                {
                  $ref: "#empty",
                },
                {
                  $ref: "#file",
                },
                {
                  $ref: "#rating",
                },
                {
                  $ref: "#boolean",
                },
                {
                  $ref: "#signaturepad",
                },
                {
                  $ref: "#paneldynamic",
                },
                {
                  $ref: "#panel",
                },
              ],
            },
            templateTitle: {
              type: "string",
            },
            templateDescription: {
              type: "string",
            },
            minWidth: {
              type: "string",
            },
            noEntriesText: {
              type: "string",
            },
            allowAddPanel: {
              type: "boolean",
            },
            allowRemovePanel: {
              type: "boolean",
            },
            panelCount: {
              type: "number",
              enum: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            },
            minPanelCount: {
              type: "number",
            },
            maxPanelCount: {
              type: "number",
            },
            defaultPanelValue: {
              type: "string",
            },
            defaultValueFromLastPanel: {
              type: "boolean",
            },
            panelsState: {
              type: "string",
              enum: ["default", "collapsed", "expanded", "firstExpanded"],
            },
            keyName: {
              type: "string",
            },
            keyDuplicationError: {
              type: "string",
            },
            confirmDelete: {
              type: "boolean",
            },
            confirmDeleteText: {
              type: "string",
            },
            panelAddText: {
              type: "string",
            },
            panelRemoveText: {
              type: "string",
            },
            panelPrevText: {
              type: "string",
            },
            panelNextText: {
              type: "string",
            },
            showQuestionNumbers: {
              type: "string",
              enum: ["off", "onPanel", "onSurvey"],
            },
            showRangeInProgress: {
              type: "boolean",
            },
            renderMode: {
              type: "string",
              enum: [
                "list",
                "progressTop",
                "progressBottom",
                "progressTopBottom",
              ],
            },
            templateTitleLocation: {
              type: "string",
              enum: ["default", "top", "bottom", "left"],
            },
            panelRemoveButtonLocation: {
              type: "string",
              enum: ["bottom", "right"],
            },
          },
        },
      ],
    },
    panel: {
      type: "object",
      $id: "#panel",
      allOff: [
        {
          $ref: "#panelbase",
        },
        {
          properties: {
            state: {
              type: "string",
              enum: ["default", "collapsed", "expanded"],
            },
            isRequired: {
              type: "string",
            },
            requiredErrorText: {
              type: "string",
            },
            startWithNewLine: {
              type: "boolean",
            },
            width: {
              type: "string",
            },
            minWidth: {
              type: "string",
            },
            maxWidth: {
              type: "string",
            },
            innerIndent: {
              type: "number",
              enum: [0, 1, 2, 3],
            },
            indent: {
              type: "number",
              enum: [0, 1, 2, 3],
            },
            page: {
              type: "string",
              enum: [],
            },
            showNumber: {
              type: "boolean",
            },
            showQuestionNumbers: {
              type: "string",
              enum: ["default", "onpanel", "off"],
            },
            questionStartIndex: {
              type: "string",
            },
            allowAdaptiveActions: {
              type: "boolean",
            },
          },
        },
      ],
    },
    page: {
      type: "object",
      $id: "#page",
      allOff: [
        {
          $ref: "#panelbase",
        },
        {
          properties: {
            navigationButtonsVisibility: {
              type: "string",
              enum: ["inherit", "show", "hide"],
            },
            maxTimeToFinish: {
              type: "number",
            },
            navigationTitle: {
              type: "string",
            },
            navigationDescription: {
              type: "string",
            },
            title: {
              type: "string",
            },
            description: {
              type: "string",
            },
          },
        },
      ],
    },
    trigger: {
      type: "object",
      $id: "#trigger",
      properties: {
        operator: {
          type: "string",
        },
        value: {
          type: "string",
        },
        expression: {
          type: "string",
        },
      },
    },
    surveytrigger: {
      type: "object",
      $id: "#surveytrigger",
      allOff: [
        {
          $ref: "#trigger",
        },
        {
          properties: {
            name: {
              type: "string",
            },
          },
        },
      ],
    },
    visibletrigger: {
      type: "object",
      $id: "#visibletrigger",
      allOff: [
        {
          $ref: "#surveytrigger",
        },
        {
          properties: {
            pages: {
              type: "string",
            },
            questions: {
              type: "string",
            },
          },
        },
      ],
    },
    completetrigger: {
      type: "object",
      $id: "#completetrigger",
      allOff: [
        {
          $ref: "#surveytrigger",
        },
        {
          properties: {},
        },
      ],
    },
    setvaluetrigger: {
      type: "object",
      $id: "#setvaluetrigger",
      allOff: [
        {
          $ref: "#surveytrigger",
        },
        {
          properties: {
            setToName: {
              type: "string",
            },
            setValue: {
              type: "string",
            },
            isVariable: {
              type: "boolean",
            },
          },
        },
      ],
    },
    copyvaluetrigger: {
      type: "object",
      $id: "#copyvaluetrigger",
      allOff: [
        {
          $ref: "#surveytrigger",
        },
        {
          properties: {
            fromName: {
              type: "string",
            },
            setToName: {
              type: "string",
            },
          },
        },
      ],
    },
    skiptrigger: {
      type: "object",
      $id: "#skiptrigger",
      allOff: [
        {
          $ref: "#surveytrigger",
        },
        {
          properties: {
            gotoName: {
              type: "string",
            },
          },
        },
      ],
    },
    runexpressiontrigger: {
      type: "object",
      $id: "#runexpressiontrigger",
      allOff: [
        {
          $ref: "#surveytrigger",
        },
        {
          properties: {
            setToName: {
              type: "string",
            },
            runExpression: {
              type: "string",
            },
          },
        },
      ],
    },
    calculatedvalue: {
      type: "object",
      $id: "#calculatedvalue",
      properties: {
        name: {
          type: "string",
        },
        expression: {
          type: "string",
        },
        includeIntoResult: {
          type: "boolean",
        },
      },
    },
    urlconditionitem: {
      type: "object",
      $id: "#urlconditionitem",
      allOff: [
        {
          $ref: "#expressionitem",
        },
        {
          properties: {
            url: {
              type: "string",
            },
          },
        },
      ],
    },
  },
};
