import React, { useState } from "react";
import Footer from "../components/Footer/Footer";
// import FullTextHTMLplaceholderSection from "../components/FullTextSection/FullTextHTMLplaceholderSection";
import WikiSection from "../components/WikiSection/WikiSection";
import NavbarKnowledge from "../components/Navbar/NavbarKnowledge";
import ScrollToTop from "../components/ScrollToTop";
import Sidebar from "../components/Sidebar/Sidebar";
import { settings } from "./settings";

import { Trans, useTranslation } from "react-i18next";
import i18next from "../glossary";

const Knowledge = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const { t, i18n } = useTranslation();

  return (
    <>
      <ScrollToTop />
      <NavbarKnowledge
        toggle={toggle}
        items={navbarItems}
        navSettings={navSettings}
      />
      <Sidebar
        isOpen={isOpen}
        items={navbarItems}
        navSettings={navSettings}
        toggle={toggle}
      />
      <WikiSection />

      <Footer />
    </>
  );
};

export default Knowledge;

//page contents
const navSettings = settings.navBar;

const navbarItems = [
  {
    to: "dismissalProtectionProceedings",
    title: i18next.t("wikiNavbarItem1"),
  },
  {
    to: "severanceNegotiation",
    title: i18next.t("wikiNavbarItem2"),
  },
  {
    to: "videosKnowledge",
    title: i18next.t("wikiNavbarItem3"),
  },
  {
    to: "reasonsForDismissal",
    title: i18next.t("wikiNavbarItem4"),
  },
  {
    to: "dismissalProcess",
    title: i18next.t("wikiNavbarItem5"),
  },
];

const knowledgeSectionContents = [
  {
    id: "dismissalProtectionProceedings",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "",
    headline: i18next.t("wikiHeadline1"),
    description: (
      <p>
        <Trans i18nKey="wikiDescription1"></Trans>
      </p>
    ),
    buttonLabel: i18next.t("backToTopBtn"),
    buttonTo: "dismissalProtectionProceedings",
    imgStart: false,
    img: i18next.t("wikiImg1"),
    alt: "dismissalProtectionProcess",
    dark: true,
    primary: true,
    darkText: true,
  },
  {
    id: "severanceNegotiation",
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: "",
    headline: i18next.t("wikiHeadline2"),
    description: (
      <p>
        <Trans i18nKey="wikiDescription2"></Trans>
      </p>
    ),
    buttonLabel: i18next.t("backToTopBtn"),
    buttonTo: "dismissalProtectionProceedings",
    imgStart: true,
    img: i18next.t("wikiImg2"),
    alt: "severanceNegotiation",
    dark: false,
    primary: false,
    darkText: false,
  },
  {
    id: "videosKnowledge",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: i18next.t("wikiTopline3"),
    headline: i18next.t("wikiHeadline3"),
    description: (
      <p>
        <Trans i18nKey="wikiDescription3"></Trans>
      </p>
    ),
    buttonLabel: i18next.t("backToTopBtn"),
    buttonTo: "dismissalProtectionProceedings",
    imgStart: false,
    img: i18next.t("wikiImg3"),
    alt: "",
    dark: true,
    primary: true,
    darkText: true,
  },
  {
    id: "reasonsForDismissal",
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: "",
    headline: i18next.t("wikiHeadline4"),
    description: (
      <p>
        <Trans i18nKey="wikiDescription4"></Trans>
      </p>
    ),
    buttonLabel: i18next.t("backToTopBtn"),
    buttonTo: "dismissalProtectionProceedings",
    imgStart: true,
    img: i18next.t("wikiImg4"),
    img2: i18next.t("wikiImg4_2"),
    alt: "reasonsForDismissal",
    dark: false,
    primary: false,
    darkText: false,
  },
  {
    id: "dismissalProcess",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "",
    headline: i18next.t("wikiHeadline5"),
    description: (
      <p>
        <Trans i18nKey="wikiDescription5"></Trans>
      </p>
    ),
    buttonLabel: i18next.t("backToTopBtn"),
    buttonTo: "dismissalProtectionProceedings",
    imgStart: false,
    img: i18next.t("wikiImg5"),
    alt: "dismissalProcess",
    dark: true,
    primary: true,
    darkText: true,
  },
];
