import severanceGuidance from ".././components/SurveySection/images/SeveranceGuidance.jpg";
import abfindungBeratung from ".././components/SurveySection/images/AbfindungBeratung.jpg";
import abfindungBerechnung from ".././components/SurveySection/images/AbfindungBerechnung.jpg";
import calculationImage from ".././components/SurveySection/images/calculation.png";
import lawyerAdvice from ".././components/SurveySection/images/LawyerAdvice.jpg";
import person from ".././components/SurveySection/images/person.png";
import pen from ".././components/SurveySection/images/pen.png";
import table from ".././components/SurveySection/images/table.png";
import finishLine from ".././components/SurveySection/images/OCFinishLine.png";
import contactOC from ".././components/SurveySection/images/contactOC.png";
import imgDownloadResult from ".././components/SurveySection/images/downloadResult.png";
import dismissalProtectionProcess from ".././components/SurveySection/images/dismissalProtectionProcess.png";
import dismissalProtectionProcessEN from ".././components/SurveySection/images/dismissalProtectionProcessEN.png";
import severanceNegotiation from ".././components/SurveySection/images/severanceNegotiation.png";
import severanceNegotiationEN from ".././components/SurveySection/images/severanceNegotiationEN.png";
import reasonsForDismissal1 from ".././components/SurveySection/images/reasonsForDismissal1.png";
import reasonsForDismissal1EN from ".././components/SurveySection/images/reasonsForDismissal1EN.png";
import reasonsForDismissal2 from ".././components/SurveySection/images/reasonsForDismissal2.png";
import reasonsForDismissal2EN from ".././components/SurveySection/images/reasonsForDismissal2EN.png";
import dismissalProcess from ".././components/SurveySection/images/dismissalProcess.png";
import dismissalProcessEN from ".././components/SurveySection/images/dismissalProcessEN.png";
import genDoc from "../components/DocXTemplater/index";

export const deTranslation = {
  translation: {
    // Navigation
    navbarItem0: "Intro",
    navbarItem1: "Newsletter",
    navbarItem2: "Abfindungsrechner",
    navbarItem3: "Kontakt",
    navbarItem4: "Knowledge",
    navbarItem5: "Section 3",
    wikiNavbarItem0: "Home",
    wikiNavbarItem1: "Kündigungsschutzverfahren",
    wikiNavbarItem2: "Abfindungsverhandlung",
    wikiNavbarItem3: "Videos",
    wikiNavbarItem4: "Kündigungsgründe",
    wikiNavbarItem5: "Kündigungsausspruch",
    backToTopBtn: "Zum Seitenanfang",

    //Hero
    toSurveyButton: "Jetzt berechnen",

    // Intro
    header: "Osborne Clarke Solutions",
    header2: "Abfindungsrechner",
    intro1:
      "Wie schätzen Arbeitgeber die Höhe von Abfindungen schnell und realistisch ein? Der OC Solutions Abfindungsrechner hilft dabei! Zudem enthält er weiterführende Informationen, wie Aufhebungsangebote ausgestaltet werden sollten und welche Rolle Abfindungen im Kündigungsschutzprozess spielen.",
    // intro2:
    //   "Dieser Abfindungsrechner hilft dabei, ein realistisches Abfindungsangebot zu ermitteln, um eine solche einvernehmliche Beendigung auszuhandeln. Dabei berücksichtigt er auch Sonderfälle wie Sonderkündigungsschutz oder vorherige Abmahnungen. Die Berechnung gilt jedoch nicht für größere betriebliche Veränderungen, die ggf. Verhandlungen mit dem Betriebsrat erfordern (in solchen Fällen wird die Abfindungsberechnung mit dem Betriebsrat vereinbart).",
    // // "Sie erwägen, den Vertrag eines Mitarbeiters mit Ihrem Unternehmen zu kündigen? Sie möchten wissen, wie viel Abfindung Sie dafür erfahrungsgemäß einplanen sollten?",

    // Cards Section

    cardsHeading: "Knowledge",
    cardsDescription:
      "Hintergrundinformationen zu Kündigungen und Abfindungsverhandlung inkl. Schaubildern und Videos",
    // card1heading: "Kündigungsschutzverfahren",
    card1heading: "<0>Kündigungsschutzverfahren</0>",
    card2heading: "<0>Abfindungsverhandlung</0>",
    card3heading: "<0>Videos</0>",
    card4heading: "<0>Kündigungsgründe</0>",
    card5heading: "<0>Kündigungsausspruch</0>",

    // Info Section 1
    infoTopline1: "Informationen",
    infoHeadline1: "Arbeitsrechtlicher Newsletter & Veranstaltungen",
    infoExternalLink1: "<0>Jetzt anmelden!</0>",
    infoDescription1:
      "Sie möchten Neuigkeiten, Insights und Informationen zu Veranstaltungen rund um das Thema Arbeitsrecht erhalten? <br/><br/>Dann melden Sie sich für den Osborne Clarke Employment Newsletter an! <br/><br/><br/>",
    infoContentLink1: "<0>Jetzt anmelden!</0>",
    infoButton1: "nach oben",

    // Info Section 2
    infoTopline2: "Haben Sie weitere Fragen?",
    infoHeadline2: "Nehmen Sie Kontakt auf!",
    infoDescription2: "Wir stehen Ihnen gerne jederzeit zur Verfügung.",
    infoExternalLink2: "<0>Zum OC-Arbeitsrechtsteam</0>",
    emailLink: "<0>Schreiben Sie uns!</0>",
    infoButton2: "nach oben",

    //Knowledge
    wikiHeadline1: "Kündigungsschutzverfahren",
    wikiHeadline2: "Abfindungsverhandlung",
    wikiHeadline3: "Videos:",
    wikiHeadline4: "Kündigungsgründe",
    wikiHeadline5: "Kündigungsausspruch",
    wikiTopline3: "Kündigungsschutzverfahren +  Abfindungsverhandlung",
    wikiDescription1:
      "Nach deutschem Recht besteht <strong>kein allgemeiner gesetzlicher Anspruch</strong> auf eine Abfindung. Arbeitnehmer*innen können jedoch die Wirksamkeit einer Kündigung in einem Gerichtsverfahren anfechten und eine <strong>Weiterbeschäftigung</strong> erwirken, wenn die Kündigung für unwirksam befunden wird. Dies führt in der Regel dazu, dass die Parteien über eine Abfindung <strong>verhandeln</strong>, die den/die Arbeitnehmer*in dazu veranlasst, die Beendigung zu akzeptieren. Darüber hinaus haben die Gerichte die Aufgabe, die Parteien zu einer Einigung zu bewegen und können Abfindungsbeträge vorschlagen. Dieses Verfahren wirkt sich auch auf außergerichtliche Verhandlungen über einvernehmliche Beendigungen aus. Lediglich in Kleinbetrieben mit maximal 10 Beschäftigten finden die Vorschriften des Kündigungsschutzgesetzes keine Anwendung.",
    wikiDescription2:
      "Die folgende Berechnung basiert auf <strong>der Annahme, dass die Chancen für beide Parteien, vor Gericht zu gewinnen, in etwa gleich groß sind</strong>. Auf der einen Seite hat das Unternehmen berechtigte Gründe für die geplante Kündigung (diese können dringende betriebsbedingte, verhaltensbedingte oder personenbedingte Gründe nach dem Kündigungsschutzgesetz sein). Andererseits hat der/die Arbeitnehmer*in vielfältige Möglichkeiten, die Wirksamkeit einer Kündigung anzugreifen und die Beweislast auf Arbeitgeberseite ist sehr hoch.<br><br>Die folgende Abfindungsformel ist eine „<strong>Faustformel</strong>“ für Kündigungsszenarien:<br><br> 0,5 Bruttomonatsgehältern <br> pro Jahr der Beschäftigung <br><br>Wenn der/die Arbeitnehmer*in starke Argumente hat, ist er/sie möglicherweise nur dann zu einem Aufhebungsvertrag bereit, wenn das Unternehmen eine höhere Abfindung oder andere Vorteile (finanziell, Freistellung von der Arbeit, gutes Zeugnis usw.) bietet. Das Gleiche gilt für Arbeitnehmer*innen über 50, für die es schwieriger ist, einen neuen Arbeitsplatz zu finden. Auch die wirtschaftliche Lage und die Gepflogenheiten in der Branche des Unternehmens wirken sich auf die Höhe der Abfindungen aus, die in der Praxis vereinbart werden.",
    wikiDescription3: "",
    wikiDescription4: "",
    wikiDescription5: "",
    wikiImg1: dismissalProtectionProcess,
    wikiImg2: severanceNegotiation,
    wikiImg3: "",
    wikiImg4: reasonsForDismissal1,
    wikiImg4_2: reasonsForDismissal2,
    wikiImg5: dismissalProcess,

    // Impressum, Datenschutz, Cookies etc.

    cookieLabel: "Cookie-Richtlinie anzeigen",
    cookieLinkLabel: "Cookie-Richtlinie anzeigen",
    cookieText:
      " 🍪Einige Cookies sind unerlässlich, damit unsere Website wie beabsichtigt funktioniert. Andere sind nicht unbedingt erforderlich, helfen aber dabei, unsere Website und Ihre Erfahrung zu verbessern. Sie können auswählen, welche optionalen Cookies Sie zulassen möchten, indem Sie die Schaltfläche „Cookie-Einstellungen“ unten verwenden. Das Blockieren einiger Arten von Cookies kann jedoch Ihre Erfahrung mit der Website und den Diensten, die wir anbieten können, beeinträchtigen. Durch die Verwendung dieses Tools wird ein Cookie auf Ihrem Gerät gesetzt, um Ihre Präferenzen zu speichern.",
    licenses: "Lizenzen",
    dataPrivacy: "Datenschutz",

    // *** SURVEY SECTION ***
    selectLanguage: "Survey-Sprache auswählen:",
    popupknowledge:
      "*Ausnahmsweise gilt das Kündigungsschutzgesetz auch dann, wenn bereits vor dem 31. Dezember 2003 mehr als fünf Arbeitnehmer beschäftigt wurden. Teilzeitbeschäftigte mit bis zu 20 Wochenstunden zählen mit 0,5, solche mit bis zu 30 Wochenstunden mit 0,75 für die Berechnung der Kopfzahl.",

    popupDescriptionNoMore10:
      "*Ausnahmsweise gilt das Kündigungsschutzgesetz auch dann, wenn bereits vor dem 31. Dezember 2003 mehr als fünf Arbeitnehmer beschäftigt wurden. Teilzeitbeschäftigte mit bis zu 20 Wochenstunden zählen mit 0,5, solche mit bis zu 30 Wochenstunden mit 0,75 für die Berechnung der Kopfzahl.",

    surveyHeading: "Abfindungsrechner",
    surveySubtitle: "",
    disclaimer:
      "Das Abfindungsrechner-Tool („Tool“) wurde von der OC Solutions (OC Services GmbH) zu Informationszwecken erstellt. Es stellt keine individuelle rechtliche oder steuerliche Auskunft dar und ist nicht geeignet, eine individuelle Beratung durch fachkundige Personen zu ersetzen. User sollten nicht allein auf der Grundlage der Informationen des Tools handeln. Die Informationen stellen einen unverbindlichen kursorischen Überblick über die Rechtslage am 1. Februar 2023 anhand der vom User gewählten Optionen dar und dürfen ohne die vorherige schriftliche Zustimmung der OC Services GmbH von niemand anderem außer dem User herangezogen, zitiert oder anderweitig verwendet werden. Es besteht keine Verpflichtung zu einer Beibehaltung oder Aktualisierung des Tools. <br><br> Mir ist bekannt, dass die folgende Abfindungsberechnung nur eine erste Einschätzung gibt und der Betrag letztlich mit dem/der Arbeitnehmer*in ausgehandelt werden muss. Der Abfindungsrechner bietet dabei eine Einschätzungshilfe, stellt jedoch keine individuelle Rechtsberatung dar.",
    introImage: abfindungBeratung,
    introTitel: "",
    // introBody:
    //   "<br><br><br>Unser neuer Abfindungsrechner ermöglicht Ihnen eine <b>schnelle und realistische Einschätzung</b>. <br/><br/>Auf Basis der wesentlichen Eckpunkte erhalten Sie eine darauf abgestimmte <b>Abfindungsempfehlung</b>.",

    disclaimerRequiredErrorText:
      "Bitte akzeptieren Sie den Disclaimer, um fortzufahren.",
    disclaimerChoiceText: "Zustimmen <br><br><hr><br>",
    surveyStart: "Anfangen",
    surveyBack: "Zurück",
    surveyNext: "Weiter",
    surveyNew: "Neu starten",
    surveyPreview: "Vorschau",
    surveyEdit: "Bearbeiten",

    detailsAGTitle: "Bitte geben Sie das Jahresfixgehalt ein.",
    detailsAGDescription: "Bruttogehalt in EUR ",
    detailsAGRequiredErrorText: "*Bitte beantworten Sie diese Frage.",
    detailsAGMinErrorText: "Bitte geben Sie einen Wert an.",
    detailsVPTitle: "Variable Bezüge, falls vorhanden.",
    detailsVPDescription:
      "Zielbetrag oder Durchschnitt der Zahlungen der letzten 3 Jahre falls höher.",
    detailsSDTitle: "Datum des Beschäftigungsbeginns",
    detailsSDDescription:
      "Eventuell unter Berücksichtigung eines früheren Anfangsdatums, wenn die Anerkennung obligatorisch ist, d. h. im Falle eines Betriebsübergangs von einem früheren Arbeitgeber",
    detailsSDRequiredErrorText: "*Bitte beantworten Sie diese Frage.",
    detailsDurTitle: "Dauer der Beschäftigung",
    detailsDurDescription: "Dezimalzahl unter Berücksichtigung der Monate.",
    //Details Panel
    detailsTitle: "Sonderfälle",
    detailsDescription:
      "<span>Liegt einer der folgenden Sonderfälle vor? Wenn kein Sonderfall vorliegt, wird die Berechnung auf Basis der üblichen <u><b><a href='https://solutions.osborneclarke.com/osborneclarke/viewWikiPage.action?metaData.siteID=28257&metaData.wikiID=20128' target='_blank'>“Faustformel”</a></b></u> durchgeführt. Falls doch, werden die besonderen Gegebenheiten bei der Empfehlung zum weiteren Vorgehen berücksichtigt.</span>",

    //Details Panel Arguments for Employee
    detailsSpecCaseProEmpTitle: "Günstige Argumente von Arbeitnehmerseite",
    detailsSpecCase50Title: "Arbeitnehmer 50+",
    detailsSpecCase50Description: "Arbeitnehmer*in ist älter als 50 Jahre",
    detailsSpecCase50Choice: "zutreffend",
    detailsSpecCaseNoReasonTitle: "Keinen berechtigten Kündigungsgrund?",
    detailsSpecCaseNoReasonDescription:
      "Wir haben (noch) keinen Kündigungsgrund nach den engen Vorgaben des Kündigungsschutzgesetzes (dringende betriebliche, personenbezogene oder verhaltensbezogene Kündigungsgründe), wollen aber dennoch das Arbeitsverhältnis beenden.",
    detailsSpecCaseNoReasonChoice: "zutreffend",
    detailsSpecCaseHandicappedTitle: "Schwerbehindert oder gleichgestellt",
    detailsSpecCaseHandicappedDescription:
      "Arbeitnehmer*in ist schwerbehindert oder gleichgestellt. ",
    detailsSpecCaseHandicappedChoice: "zutreffend",
    detailsSpecCaseMatPatLeaveTitle:
      "Mutterschutz, Elternzeit oder Elternteilzeit",
    detailsSpecCaseMatPatLeaveDescription:
      "Arbeitnehmer*in befindet sich in Mutterschutz, Elternzeit oder Elternteilzeit.",
    detailsSpecCaseMatPatLeaveChoice: "zutreffend",
    detailsSpecCaseRepBodyTitle: "Arbeitnehmervertretung",
    detailsSpecCaseRepBodyDescription:
      "Arbeitnehmer*in ist Betriebsratsmitglied, Mitglied der Auszubildendenvertretung, der Schwerbehindertenvertretung oder einer vergleichbaren Arbeitnehmervertretung bzw. Wahlorganisator oder Kandidat",
    detailsSpecCaseRepBodyChoice: "zutreffend",
    detailsSpecCaseDataProtTitle: "Datenschutzbeauftragte/r",
    detailsSpecCaseDataProtDescription:
      "Arbeitnehmer*in ist Datenschutzbeauftragte/r des Unternehmens.",
    detailsSpecCaseDataProtChoice: "zutreffend",
    //Details Panel Arguments for Employer
    detailsSpecCaseProEmployerTitle: "Günstige Argumente für den Arbeitgeber",
    detailsSpecCaseLessThan6Title: "Probezeit/Wartezeit",
    detailsSpecCaseLessThan6Description:
      "Die Beschäftigung bestand weniger als 6 Monate (Wartezeit nach dem Kündigungsschutzgesetz)",
    detailsSpecCaseLessThan6Choice: "zutreffend",
    detailsSpecCaseLessThan6Valid:
      "Bitte überprüfen Sie das Datum des Beschäftigungsbeginns.",
    detailsSpecCaseNoMore10Title: "Kleinbetrieb",
    detailsSpecCaseNoMore10Description:
      "Der Betrieb hat nicht mehr als 10 Arbeitnehmer (daher gilt er als Kleinbetrieb, in dem kein rechtfertigender Grund für eine Kündigung nach dem Kündigungsschutzgesetz erforderlich ist).",
    detailsSpecCaseNoMore10Choice: "zutreffend",
    detailsSpecCaseNoMore10Popup:
      "*Ausnahmsweise gilt das Kündigungsschutzgesetz auch dann, wenn bereits vor dem 31. Dezember 2003 mehr als fünf Arbeitnehmer beschäftigt wurden. Teilzeitbeschäftigte mit bis zu 20 Wochenstunden zählen mit 0,5, solche mit bis zu 30 Wochenstunden mit 0,75 für die Berechnung der Kopfzahl.",
    detailsSpecCaseExtraDismTitle: "Außerordentliche Kündigung ",
    detailsSpecCaseExtraDismDescription:
      "Wir haben Gründe für eine außerordentliche fristlose Kündigung wegen einer schweren Vertragsverletzung.",
    detailsSpecCaseExtraDismChoice: "zutreffend",
    detailsSpecCasePriorWarnTitle: "Abmahnung",
    detailsSpecCasePriorWarnDescription:
      "Wir haben vorher bereits Abmahnungen ausgesprochen",
    detailsSpecCasePriorWarnChoice: "zutreffend",
    //Result screens
    variante1amountText:
      "<span>Auf der Grundlage der von Ihnen eingegebenen Daten und unter Berücksichtigung der  <b> Gesamtvergütung</b>, errechnet sich nach der erläuterten  <u><b><a href='https://solutions.osborneclarke.com/osborneclarke/viewWikiPage.action?metaData.siteID=28257&metaData.wikiID=20128' target='_blank'>“Faustformel”</a></b></u> ein Abfindungsbetrag in Höhe von: \n<br>\n</span>",
    resultVariante1Description:
      "(Formel: Monatsverdienst x Dauer der Beschäftigung x Abfindungsfaktor 0,5)",
    // Corridor panel

    likelySeverance:
      "Es ist davon auszugehen, dass eine Abfindung im folgenden Rahmen eine angemessene Grundlage für einen Aufhebungsvertrag darstellt:",

    // GENERAL & REPEATS

    currencyGross: "(EUR brutto)",
    fromTitle: "von",
    toTitle: "bis",
    calculationImage: calculationImage,
    imageLawyerAdvice: lawyerAdvice,
    imageSeveranceGuidance: abfindungBeratung,
    imageSeveranceCalculation: abfindungBerechnung,
    imagePerson: person,
    imagePen: pen,
    imageTable: table,
    imageFinish: finishLine,
    imageContactOC: contactOC,
    imgDownloadResult: imgDownloadResult,

    corridorTitle: "Empfohlener Verhandlungsrahmen",
    corridorTitleH3: "<h3>Empfohlener Verhandlungsrahmen</h3>",
    noSeverance: "Keine Abfindung",
    severanceFactorManual: 'Abfindungsfaktor (z. B. "0.6")',
    manualCalcMaxErrorText: "Der Wert darf nicht höher sein als 3.",
    fixOrOverallTitle: "Fixgehalt oder Gesamtgehalt?",
    fixOverallLabelTrue: "Fixgehalt",
    fixOverallLabelFalse: "Gesamtgehalt",
    severanceAmount: "Abfindungsbetrag",
    fixAndOverall: "Fixgehalt/Gesamtgehalt",
    manualCalcTitle: "Faktor für die Abfindungsberechnung selbst auswählen",
    pleaseNote: "Bitte beachten",
    likelySeveranceHence:
      "Es erscheint daher wahrscheinlich, dass eine Abfindung im folgenden Rahmen eine vertretbare Grundlage für einen Aufhebungsvertrag darstellt:",
    hintNoLegalAdvice:
      "<h3>Hinweis</h3>\nBitte beachten Sie, dass diese automatisierte Vorprüfung eine individuelle Rechtsberatung nicht ersetzt. Wir setzen uns gerne mit Ihnen in Verbindung, wenn Sie dies wünschen.",
    allOpenMatters: "Andere offene Punkte",

    seekLawyersAdvice: "Anwaltliche Beratung empfohlen",
    severanceRuleOfThumb:
      "Abfindungsbetrag nach der <u><b><a href='https://solutions.osborneclarke.com/osborneclarke/viewWikiPage.action?metaData.siteID=28257&metaData.wikiID=20128' target='_blank'>Faustformel</a></u></b>",
    writtenFormReq:
      "<h3>Schriftformerfordernis</h3>\nDie Vereinbarung muss im Original <b>von beiden Parteien</b> unterzeichnet werden (strenges gesetzliches Schriftformerfordernis).",

    minFactor025:
      "(Minimum = Faktor 0,25 nur auf der Grundlage des Festgehalts)",
    minFactor05: "(Minimum = Faktor 0,5 nur auf der Grundlage des Festgehalts)",
    minFactor075:
      "(Minimum = Faktor 0,75 nur auf der Grundlage des Festgehalts)",
    maxFactor07: "(Maximum = Faktor 0,7 auf Grundlage der Gesamtvergütung)",
    maxFactor1: "(Maximum = Faktor 1,0 auf Grundlage der Gesamtvergütung)",
    maxFactor03: "(Maximum = Faktor 0,3 auf Grundlage der Gesamtvergütung)",

    maxFactor035: "(Maximum = Faktor 0,35 auf Grundlage der Gesamtvergütung)",

    maxFactor15: "(Maximum = Faktor 1,5 auf der Grundlage der Gesamtvergütung)",

    // 50+ panel
    emp50PlusTitle: "Arbeitnehmer 50+",
    emp50PlusSuggestion:
      "Für Arbeitnehmer*innen 50+ empfehlen einige Gerichte einen höheren Abfindungsfaktor von 0,75. Es erscheint daher wahrscheinlich, dass eine Abfindung im folgenden Rahmen eine vertretbare Grundlage für einen Aufhebungsvertrag darstellt:",
    // 50+ & no reason panel
    emp50PlusNoReasonTitle:
      "Arbeitnehmer 50+ & kein berechtigter Kündigungsgrund",
    emp50PlusNoReasonSuggestion:
      "Für Arbeitnehmer*innen 50+ empfehlen einige Gerichte einen höheren Abfindungsfaktor von 0,75. Da die Kündigung jedoch offensichtlich durch andere Gründe motiviert ist und Sie angeben, dass es keine ausreichenden Gründe für eine Kündigung aus rechtlicher Sicht gibt (dringende betriebliche, personenbezogene oder verhaltensbedingte Gründe), hat hat der/die Arbeitnehmer*in sehr gute Chancen, vor Gericht erfolgreich zu sein und eine Weiterbeschäftigung zu erreichen. Daher ist wahrscheinlich ein höherer Abfindungsfaktor erforderlich, um eine einvernehmliche Beendigung des Arbeitsverhältnisses zu erreichen, wenn der/die Arbeitnehmer*in überhaupt bereit ist, das Arbeitsverhältnis zu beenden. Eine Abfindung in folgendem Rahmen wäre in einem solchen Fall regelmäßig eine angemessene Basis für eine Aufhebungsvereinbarung:",

    //No reason & no warning

    noReasonNoWarning:
      "Da die Kündigung jedoch offensichtlich durch andere Gründe motiviert ist und Sie angeben, dass es keine ausreichenden Gründe für eine Kündigung aus rechtlicher Sicht gibt (dringende betriebliche, personenbezogene oder verhaltensbedingte Gründe), hat der/die Arbeitnehmer*in sehr gute Chancen, vor Gericht erfolgreich zu sein und eine Weiterbeschäftigung zu erreichen. Daher ist wahrscheinlich ein höherer Abfindungsfaktor erforderlich, um eine einvernehmliche Beendigung des Arbeitsverhältnisses zu erreichen, wenn der/die Arbeitnehmer*in überhaupt bereit ist, das Arbeitsverhältnis zu beenden. Eine Abfindung in folgendem Rahmen wäre in einem solchen Fall regelmäßig eine angemessene Basis für eine Aufhebungsvereinbarung:",
    noReasonTitle: "Kein berechtigter Kündigungsgrund",
    // Short employment

    shortemploymentrelationshipsTitle: "Kurze Arbeitsverhältnisse",
    shortemploymentrelationshipsDescription:
      "Bei kurzen Arbeitsverhältnissen kann eine Abfindung in Höhe von mindestens drei Monatsgehältern erforderlich werden, was hier zu einem höheren Betrag führen würde: ",
    //No reason & warning panel
    noReasonWithWarning:
      "Da die Kündigung jedoch offensichtlich durch andere Gründe motiviert ist und Sie angeben, dass es trotz vorheriger Abmahnung(en) keine ausreichenden Gründe für eine Kündigung aus rechtlicher Sicht gibt (dringende betriebliche, personenbezogene oder verhaltensbedingte Gründe), hat der/die Arbeitnehmer*in sehr gute Chancen, vor Gericht erfolgreich zu sein und eine Weiterbeschäftigung zu erreichen. Daher ist wahrscheinlich ein höherer Abfindungsfaktor erforderlich, um eine einvernehmliche Beendigung des Arbeitsverhältnisses zu erreichen, wenn der/die Arbeitnehmer*in überhaupt bereit ist, das Arbeitsverhältnis zu beenden. Eine Abfindung in folgendem Rahmen wäre in einem solchen Fall regelmäßig eine angemessene Basis für eine Aufhebungsvereinbarung:",

    // Extraordinary dismissal panel
    extraordinaryDismissalLawyerAdvice:
      "Aufgrund der von Ihnen gemachten Angaben sehen Sie Gründe für eine außerordentliche fristlose Kündigung aufgrund einer schwerwiegenden Vertragsverletzung. Wir empfehlen Ihnen dringend, sich <b>anwaltlich beraten zu lassen</b>, um die Situation, die rechtlichen Voraussetzungen für eine Kündigung und die möglichen nächsten Schritte gemeinsam zu bewerten. Unter Umständen ist es sogar möglich, eine Aufhebungsvereinbarung nur auf Basis der ordentlichen Kündigungsfrist, jedoch ohne Zahlung einer Abfindung zu erreichen.",
    priorWarningLawyerAdvice:
      "Aufgrund der von Ihnen gemachten Angaben haben Sie den/die Arbeitnehmer*in bereits zuvor abgemahnt, sehen jedoch noch keine ausreichenden Gründe für eine außerordentliche fristlose Kündigung aufgrund einer schwerwiegenden Vertragsverletzung. Wir empfehlen Ihnen dringend, sich <b>anwaltlich beraten zu lassen</b>, um die Situation, die rechtlichen Voraussetzungen für eine Kündigung und die möglichen nächsten Schritte gemeinsam zu bewerten. Unter Umständen ist es sogar möglich, eine Aufhebungsvereinbarung gegen Zahlung einer geringeren Abfindung zu erreichen.",

    // Panel 7 rule of thumb
    ruleOfThumbCalc:
      "<span>Dennoch haben wir zu Ihrer Information den Abfindungsbetrag nach der erläuterten <u><b><a href='https://solutions.osborneclarke.com/osborneclarke/viewWikiPage.action?metaData.siteID=28257&metaData.wikiID=20128' target='_blank'>“Faustformel”</a></b></u> berechnet. Auf der Grundlage der von Ihnen eingegebenen Daten und unter Berücksichtigung der Gesamtvergütung ergibt sich ein Betrag von\n</span>",
    resultVariante2Description:
      "(Formel: Monatsverdienst x Dauer der Beschäftigung x Abfindungsfaktor 0,5)",
    panel7Title:
      "Abfindungsbetrag nach der <u><b><a href='https://solutions.osborneclarke.com/osborneclarke/viewWikiPage.action?metaData.siteID=28257&metaData.wikiID=20128' target='_blank'>Faustformel</a></u></b>",

    // Panel 8 estimate

    panel8SeveranceEstimate:
      "Je nach Gewicht der Kündigungsgründe dürfte eine Abfindung im folgenden Rahmen eine angemessene Grundlage für einen Aufhebungsvertrag sein:",

    // Employment for less than 6 months

    lessThan6Title: "Beschäftigungsdauer unter 6 Monate",
    lessThan6:
      'Nach den von Ihnen gemachten Angaben hat das Arbeitsverhältnis weniger als 6 Monate bestanden. Der/Die Arbeitnehmer*in befindet sich noch in der so genannten "Wartezeit" und genießt keinen Schutz durch das Kündigungsschutzgesetz. Daher erhalten Arbeitnehmer*innen in der Wartezeit in der Regel keine Abfindung, wenn sie entlassen werden. Allerdings muss die Kündigung <b>im Original unterschrieben</b> (strenges gesetzliches Schriftformerfordernis) und vor Ablauf der Wartezeit mit Nachweis des Zugangs übermittelt werden. Auch die geltende Kündigungsfrist muss eingehalten werden.',

    // Maternity leave

    maternityLeave:
      "Allerdings haben Sie angegeben, dass Mutterschutz bzw. Elternzeit besteht. Dies begründet einen Sonderkündigungsschutz, der auch während der Wartezeit gilt und wir empfehlen Ihnen dringend, sich dazu <b>anwaltlich beraten</b> zu lassen.",
    maternityLeaveParticularities:
      "Wenn es Besonderheiten gibt, die Sie besprechen möchten, oder wenn Sie möchten, dass Ihr OC-Anwalt die Kündigung vorbereitet, können Sie uns dies mitteilen.",

    // Special protection & Not less than 6
    //handicapped
    specialProtHandicapped:
      "Aufgrund der von Ihnen gemachten Angaben genießt der/die Arbeitnehmer*in einen besonderen Kündigungsschutz, da er/sie schwerbehindert ist. Aufgrund dieses besonderen Schutzes empfehlen wir Ihnen dringend, sich anwaltlich beraten zu lassen. ",

    specialProtRepBody:
      "Aufgrund der von Ihnen gemachten Angaben genießt der Arbeitnehmer einen besonderen Kündigungsschutz, da er/sie Mitglied einer Arbeitnehmervertretung ist. Aufgrund dieses besonderen Schutzes empfehlen wir Ihnen dringend, sich anwaltlich beraten zu lassen. ",
    specialProtDataOfficer:
      "Aufgrund der von Ihnen gemachten Angaben genießt der Arbeitnehmer einen besonderen Kündigungsschutz, da er/sie Datenschutzbeauftragte/r ist. Aufgrund dieses besonderen Schutzes empfehlen wir Ihnen dringend, sich anwaltlich beraten zu lassen. ",
    specialProtMatLeave:
      "Aufgrund der von Ihnen gemachten Angaben genießt der Arbeitnehmer einen besonderen Kündigungsschutz, da er/sie in Mutterschutz, Elternzeit oder Elternteilzeit ist. Aufgrund dieses besonderen Schutzes empfehlen wir Ihnen dringend, sich anwaltlich beraten zu lassen. ",
    specialProtExtrordDism:
      "Bei der Beratung kann dann das Vorliegen von Gründen für eine außerordentliche Kündigung berücksichtigt werden.",
    specialProtRuleOfThumb:
      "<span>In Fällen mit Sonderkündigungsschutz gehen die Abfindungen in der Regel über die erläuterte <u><b><a href='https://solutions.osborneclarke.com/osborneclarke/viewWikiPage.action?metaData.siteID=28257&metaData.wikiID=20128' target='_blank'>“Faustformel”</a></b></u> hinaus und können sich eher in folgendem Rahmen bewegen:</span>",
    minimumMonthlyEarnings:
      "(Minimum = Monatliche Vergütung inkl. Variable x Dauer der Beschäftigung x Abfindungsfaktor 1,0)",
    maximumMonthlyEarnings:
      "(Maximum = Monatliche Vergütung inkl. Variable x Dauer der Beschäftigung x Abfindungsfaktor 2,0)",

    // Max 10 employees

    smallBusiness10Emp:
      "<h3>\nKleinbetrieb bis 10 Arbeitnehmer</h3>\nNach den von Ihnen eingegebenen Daten hat der Betrieb, in dem der Arbeitnehmer arbeitet, höchstens 10 Arbeitnehmer. Daher wird er als Kleinbetrieb betrachtet, für den kein rechtfertigender Grund für eine Kündigung nach dem Kündigungsschutzgesetz erforderlich ist. Dementsprechend wird oftmals gar keine Abfindung gezahlt.\n<br><br>\nLiegt die Zahl der Beschäftigten nahe 10, beachten Sie bitte die Einzelheiten der Berechnung: Wurden bereits vor dem 31. Dezember 2003 mehr als fünf Arbeitnehmer dauerhaft beschäftigt, gilt für sie ausnahmsweise trotzdem das Kündigungsschutzgesetz. Teilzeitbeschäftigte mit bis zu 20 Wochenstunden zählen mit 0,5, solche mit bis zu 30 Wochenstunden mit 0,75 für die Berechnung der Mitarbeiterzahl.\n\n",
    smallBusiness10EmpImpactSeverance:
      "<span>Die Einstufung als Kleinbetrieb wirkt sich auch auf die übliche Abfindungsberechnung aus für den Fall, dass die Gesellschaft sich entschließt trotz Kleinbetriebstatus eine Abfindung anzubieten. Diese beträgt in der Regel nur etwa die Hälfte der erläuterten <u><b><a href='https://solutions.osborneclarke.com/osborneclarke/viewWikiPage.action?metaData.siteID=28257&metaData.wikiID=20128' target='_blank'>“Faustformel”</a></b></u>, falls überhaupt eine Abfindung gezahlt wird. Unter Berücksichtigung der <b>Gesamtvergütung</b> würde ein solcher Abfindungsbetrag betragen:\n</span>",
    monthlyEarnings25:
      "(Formel: Monatsverdienst x Dauer der Beschäftigung x Abfindungsfaktor 0,25)",

    // Results Options
    resultMutualTermDismTitle:
      "Einvernehmliche Aufhebungsvereinbarung oder Kündigung",
    resultOption1:
      "Sie könnten versuchen, eine <b>einvernehmliche Beendigung</b> des Arbeitsverhältnisses zu erreichen, indem Sie zunächst den berechneten Mindestbetrag anbieten. Alternativ könnten Sie eine <b>Kündigung</b> aussprechen, müssen aber bereit sein, im Falle eines gerichtlichen Vergleichs einen Betrag innerhalb des skizzierten Rahmens zu zahlen. In beiden Fällen werden die Abfindungsforderungen wahrscheinlich höher sein, wenn der/die Arbeitnehmer*in gute Argumente auf seiner/ihrer Seite sieht, um den Prozess zu gewinnen. In der Regel haben Arbeitnehmer die Erwartung, dass die Abfindung auf Basis ihrer <b>Gesamtvergütung</b> berechnet wird (Betrag siehe oben).",
    resultOption2NoSeverance:
      "Sie könnten versuchen eine <b>einvernehmliche Beendigung</b> des Arbeitsverhältnisses zu erreichen, indem Sie zunächst einen Aufhebungsvertrag ohne Abfindung anbieten. Alternativ können Sie auch eine <b>Kündigung</b> aussprechen. Da der/die Arbeitnehmer*in in einem Kleinbetrieb arbeitet, hat er/sie nur sehr begrenzte Möglichkeiten, die Wirksamkeit der Kündigung anzufechten (z. B. wenn erkennbar ist, dass die Kündigung willkürlich oder diskriminierend erfolgte). Dennoch bieten manche Arbeitgeber eine kleine Abfindung an, um Streit um die Beendigung zu vermeiden oder um den Verlust des Arbeitsplatzes auszugleichen.",
    resultHandicappedMatLeave:
      "Trotz des besonderen Sonderkündigungsschutzes könnten Sie versuchen, eine <b>einvernehmliche Beendigung</b> zu erreichen. Um eine Kündigung auszusprechen, müssten Sie zunächst die Zustimmung einer Behörde einholen.",
    resultRepBody:
      "Trotz des besonderen Sonderkündigungsschutzes könnten Sie versuchen, eine <b>einvernehmliche Beendigung</b> zu erreichen. Eine Kündigung wäre grundsätzlich nur als außerordentliche Kündigung aus wichtigem Grund nach Zustimmung des Betriebsrats möglich. Andernfalls wäre die Kündigung unwirksam und das Arbeitsverhältnis bestünde fort.",
    resultDataOfficer:
      "Trotz des besonderen Sonderkündigungsschutzes könnten Sie versuchen, eine <b>einvernehmliche Beendigung</b> zu erreichen.",
    resultNoticeSmallSeverance:
      "Sie könnten versuchen, eine <b>einvernehmliche Aufhebungsvereinbarung</b> zu erreichen, indem Sie zunächst eine Beendigung unter Einhaltung der Kündigungsfrist und eventuell eine geringe Abfindung anbieten. Alternativ könnten Sie eine außerordentliche und vorsorglich hilfsweise ordentliche <b>Kündigung</b> aussprechen, aber bereit sein, im Falle eines gerichtlichen Vergleichs einen Betrag innerhalb des skizzierten Rahmens zu zahlen. Insbesondere bei außerordentlichen Kündigungen sind die gesetzlichen Anforderungen hoch und der/die Arbeitnehmer*in könnte noch Argumente auf seiner/ihrer Seite finden, um den Prozess zu gewinnen. In der Regel haben Arbeitnehmer*innen die Erwartung, dass das Arbeitsverhältnis auf Basis einer ordentlichen Kündigung unter Wahrung der Kündigungsfrist beendet wird und die Abfindung auf Basis ihrer Gesamtvergütung berechnet wird (Betrag siehe oben).",
    resultMinSeverance:
      "Sie könnten versuchen, eine <b>einvernehmliche Beendigung</b> des Arbeitsverhältnisses zu erreichen, indem Sie zunächst den berechneten Mindestbetrag anbieten. Alternativ könnten Sie eine <b>Kündigung</b> aussprechen, müssen aber bereit sein, im Falle eines gerichtlichen Vergleichs einen Betrag innerhalb des skizzierten Rahmens zu zahlen oder sogar eine Weiterbeschäftigung zu akzeptieren. In beiden Fällen werden die Abfindungsforderungen des Arbeitnehmers wahrscheinlich höher sein, wenn er gute Argumente auf seiner Seite sieht, um den Prozess zu gewinnen. In der Regel haben Arbeitnehmer die Erwartung, dass die Abfindung auf Basis ihrer Gesamtvergütung berechnet wird (Betrag siehe oben).",
    resultRouteTermAgreement:
      "Wenn Sie eine einvernehmliche Aufhebungsvereinbarung anstreben, empfehlen wir, <b>alle anderen offenen Punkte mit aufzunehmen</b>, insbesondere<ul><li>den Zeitpunkt des Wirksamwerdens der Kündigung,</li><li> die Gehaltszahlung für die Restlaufzeit des Arbeitsverhältnisses,</li><li> die Höhe der variablen Bezüge,</li><li> eine eventuelle Freistellung,</li> <li> den Resturlaubsanspruch,</li> <li> die vereinbarte Note für das Arbeitszeugnis,</li> <li> die Erledigung aller gegenseitigen Ansprüche usw.</li></ul>",

    // Final site
    thanksForUsing:
      "<br /><br /><h3>Vielen Dank für die Nutzung unseres Abfindungsrechners.</h3>\n",
    howToProceed: "Wie soll es weitergehen?",
    downloadResult:
      "<a href='#' onClick='return genDoc(surveyData, template, \"generatedDocument.docx\")'>Dokument generieren</a>",
    // "<Button   type='button' onClick='genDoc(surveyData, template, 'generatedDocument.docx');'>Generate Document</Button>",
    contactOC:
      '<a class="contactLink"\n href="mailto:severance-calculator@osborneclarke.com?subject=Anfrage Abfindungsrechner/Inquiry severance calculator">Kontaktieren Sie Osborne Clarke!</a>\n',
  },
};
