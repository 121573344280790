/* global  */

function errorMessageCompiler(stage, error) {
  if (process.env.NODE_ENV === "development") {
    console.error(`Error in ${stage}`, error);
  }

  if (error.properties && error.properties.errors instanceof Array) {
    const errorMessages = error.properties.errors.map((singleError) => {
      const { properties } = singleError;

      const errorMessage = properties.rootError
        ? "Tag:{" + properties.tag + "}:\n-  " + properties.rootError
        : properties.explanation;

      return "\n" + errorMessage;
    });

    return `Error (docxtemplater) in ${stage}: ${errorMessages}`;
  } else {
    return `Error (unspecified) in ${stage}: ${error}`;
  }
}

export default errorMessageCompiler;
