/* global */

/**
 * Will register a filter to the expressions.filters
 * @param {Function} filterFunction
 * @param {String} [filterName]
 */
function registerFilter(filterFunction, filterName = filterFunction.name) {
  if (!filterName || filterName === "")
    throw new Error(`expected a filter name got: "${filterName}"`);

  this.expressions.filters[filterName] = filterFunction;
}

function registerAllFilters(filters) {
  Object.values(filters).forEach((filter) => this.registerFilter(filter));
}

function clearFilters() {
  this.expressions.filters = {};
}

function getFilters() {
  return this.expressions.filters;
}

export { registerFilter, registerAllFilters, clearFilters, getFilters };
