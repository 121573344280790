import React from "react";
import { Trans, useTranslation } from "react-i18next";
import i18next from "../../glossary";
// import ocCheck from "../SurveySection/images/ocCheck.gif";
// import person from "../SurveySection/images/person.png";
import checklist from "../SurveySection/images/checklist.gif";
import table from "../SurveySection/images/table.png";
// import calculation from "../SurveySection/images/calculation.png";
// import peopleCircle from "../SurveySection/images/peopleCircle.png";
// import ocManBulb from "../SurveySection/images/ocManBulb.gif";
import womanLightBulb from "../SurveySection/images/womanLightbulb.gif";
import letter from "../SurveySection/images/letter.png";
import scales from "../SurveySection/images/scales.png";
import { HashLink } from "react-router-hash-link";

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./CardsElements";

const Services = () => {
  const { t, i18n } = useTranslation();

  return (
    <ServicesContainer id="discover">
      <ServicesH1>{t("cardsHeading")}</ServicesH1>
      <ServicesP>{t("cardsDescription")}</ServicesP>
      <ServicesWrapper>
        <ServicesCard
          id="dismProtectionProceedingsCard"
          onClick={() =>
            window.open("/knowledge#dismissalProtectionProceedings", "_self")
          }
        >
          <ServicesIcon src={scales} alt="ocIcon" />
          <ServicesH2>
            {" "}
            <p>
              <Trans i18nKey="card1heading">
                <HashLink
                  class="contactName"
                  to={"/knowledge#dismissalProtectionProceedings"}
                >
                  Kündigungsschutzverfahren
                </HashLink>
              </Trans>
            </p>
          </ServicesH2>
        </ServicesCard>

        <ServicesCard
          id="severanceNegotiationCard"
          onClick={() =>
            window.open("/knowledge#knowledge#severanceNegotiation", "_self")
          }
        >
          <ServicesIcon src={table} alt="ocIcon" />
          <ServicesH2>
            {" "}
            <p>
              <Trans i18nKey="card2heading">
                <HashLink
                  class="contactName"
                  to={"/knowledge#severanceNegotiation"}
                >
                  Abfindungsverhandlung
                </HashLink>
              </Trans>
            </p>
          </ServicesH2>
        </ServicesCard>

        <ServicesCard
          id="videoCard"
          onClick={() => window.open("/knowledge#videosKnowledge", "_self")}
        >
          <ServicesIcon src={womanLightBulb} alt="ocIcon" />
          <ServicesH2>
            {" "}
            <p>
              <Trans i18nKey="card3heading">
                <HashLink class="contactName" to="/knowledge#videosKnowledge">
                  Kündigungsschutzverfahren + Abfindungsverhandlung
                </HashLink>
              </Trans>
            </p>
          </ServicesH2>
        </ServicesCard>

        <ServicesCard
          id="reasonsForDismissalCard"
          onClick={() => window.open("/knowledge#reasonsForDismissal", "_self")}
        >
          <ServicesIcon src={checklist} alt="ocIcon" />
          <ServicesH2>
            {" "}
            <p>
              <Trans i18nKey="card4heading">
                <HashLink
                  class="contactName"
                  to="/knowledge#reasonsForDismissal"
                >
                  Kündigungsgründe
                </HashLink>
              </Trans>
            </p>
          </ServicesH2>
        </ServicesCard>

        <ServicesCard
          id="dismissalProcessCard"
          onClick={() => window.open("/knowledge#dismissalProcess", "_self")}
        >
          <ServicesIcon src={letter} alt="ocIcon" />
          <ServicesH2>
            {" "}
            <p>
              <Trans i18nKey="card5heading">
                <HashLink class="contactName" to="/knowledge#dismissalProcess">
                  Kündigungsausspruch
                </HashLink>
              </Trans>
            </p>
          </ServicesH2>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
