import styled from "styled-components";

export const FullTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;

  padding: 100px 0;
`;
export const FullTextContainerHTML = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
`;

export const FullTextH1 = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#001017")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const FullTextP = styled.p`
  font-size: 1rem;
  text-align: justify;
  color: #161616;
  max-width: calc(84 * var(--base-unit, 8px));
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 480px) {
    padding: 15px;
  }
`;

export const FullTextHTMLDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 24px;
  max-width: calc(84 * var(--base-unit, 8px));
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
export const FullTextSpan = styled.span`
  font-size: 1rem;
  text-align: center;
  color: #161616;
  max-width: calc(84 * var(--base-unit, 8px));
  margin-left: auto;
  margin-right: auto;
`;

export const TopLine = styled.p`
  color: #1796bc;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#001017" : "#fff")};
`;
