import React from "react";
import { Button } from "../ButtonElement";
import { Trans, useTranslation } from "react-i18next";
// import ocCheck from "../SurveySection/images/ocCheck.gif";
// import contactOC from "../SurveySection/images/contactOC.png";
// import LawyerAdvice from "../SurveySection/images/LawyerAdvice.jpg";
import ocRecruitmentLight from "../SurveySection/images/ocRecruitmentLight.gif";
import goodLuck from "../SurveySection/images/goodLuck.gif";

import i18next from "../../glossary";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
} from "./ContactElements";

const ContactSection = (
  lightBg,
  darkBg,
  imgStart,
  id,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  externalLink,
  emailLink,
  buttonLabel,
  buttonTo,
  extLink,
  img,
  alt,
  primary,
  dark,
  dark2
) => {
  const { t, i18n } = useTranslation();
  const lng = i18n.language;

  return (
    <>
      <InfoContainer darkBg={lightBg} id="contacts">
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                {/* <TopLine>{topLine}</TopLine> */}
                <TopLine>{t("infoTopline2")}</TopLine>
                <Heading darkText={lightText}>{t("infoHeadline2")}</Heading>
                <Subtitle lightText={lightText}>
                  {t("infoDescription2")}
                </Subtitle>
                <Subtitle lightText={lightText}>
                  <p>
                    <Trans i18nKey="emailLink">
                      <a
                        class="manualButtonContact"
                        href="mailto:severance-calculator@osborneclarke.com?subject=Anfrage Abfindungsrechner/Inquiry severance calculator"
                      >
                        Schreiben Sie uns!
                      </a>
                      ,
                    </Trans>
                  </p>
                </Subtitle>
                <Subtitle lightText={lightText}>
                  <p>
                    <Trans i18nKey="infoExternalLink2">
                      {(() => {
                        switch (lng) {
                          case "de":
                            return (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                class="manualButtonContact"
                                href="https://www.osborneclarke.com/de/lawyers?title=&location=6392&field_job_role_target_id=All&sector=All&service=6452&digital_transformation=All"
                              >
                                Zum OC-Arbeitsrechtsteam
                              </a>
                            );
                          case "en":
                            return (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                class="manualButtonContact"
                                href="https://www.osborneclarke.com/lawyers?title=&location=6392&field_job_role_target_id=All&sector=All&service=6452&digital_transformation=All"
                              >
                                Zum OC-Arbeitsrechtsteam
                              </a>
                            );
                          default:
                            return (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                class="manualButtonContact"
                                href="https://www.osborneclarke.com/lawyers?title=&location=6392&field_job_role_target_id=All&sector=All&service=6452&digital_transformation=All"
                              >
                                Zum OC-Arbeitsrechtsteam
                              </a>
                            );
                        }
                      })()}
                    </Trans>
                  </p>
                </Subtitle>

                <BtnWrap>
                  <Button
                    to="home"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {t("infoButton2")}
                  </Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={ocRecruitmentLight} alt="contactOC" />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default ContactSection;
