import React from "react";

import {
  SurveyApp,
  SurveyContainer,
  SurveyH1,
  SurveyP,
} from "./SurveyElements";
import { Trans, useTranslation } from "react-i18next";
import i18next from "../../glossary";

const SurveySection = ({ surveyJson, surveySectionOnChangeCallback }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const surveyOnChangeCallback = (data) => {
    surveySectionOnChangeCallback(data);
  };

  return (
    <SurveyContainer id="surveys">
      <SurveyH1> {t("surveyHeading")} </SurveyH1>
      <SurveyP></SurveyP>
      <SurveyApp
        currentLang={currentLang}
        surveyJson={surveyJson}
        surveyOnChangeCallback={surveyOnChangeCallback}
      ></SurveyApp>
    </SurveyContainer>
  );
};

export default SurveySection;
