const invalidCharacters = /[ |.|?]/g;

/**
 * Removes invalid characters from the JSON keys recursively
 * @param {Object} json
 * @returns {Object}
 */
function processJsonKeys(json) {
  if (Array.isArray(json)) {
    return json.map((singleElement) => processJsonKeys(singleElement));
  } else if (json && typeof json === "object") {
    const keys = Object.keys(json);
    const values = Object.values(json);

    // recursively check every value
    const newValues = values.map((singleValue) => processJsonKeys(singleValue));

    return keys.reduce((acc, cur, index) => {
      const newKey = cur.replace(invalidCharacters, "");
      acc[newKey] = newValues[index];
      return acc;
    }, {});
  } else return json;
}

export default processJsonKeys;
