/* global */

import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";

import errorMessageCompiler from "./helper/errorMessageCompiler";
import angularParser from "./angularParser";
import removeInvalidCharactersFromKeys from "./helper/jsonRemoveInvalidCharacters";

const standardOptions = {
  parser: angularParser,
  linebreaks: true,
  paragraphLoop: true,
};
/**
 * Generates a document and returns it as blob
 * @param {Binary} templateBinary
 * @param {Object} templateData
 * @param {Object} [options] docxtemplater options. will override standard options, if respective keys are specified
 * @returns {Promise<Blob>} A blob which can be downloaded in the browser
 */
function generateDocument(templateBinary, templateData, options = {}) {
  const combinedOptions = { ...standardOptions, ...options };

  return new Promise((resolve, reject) => {
    let generationStage = "Initializing";

    try {
      const zip = new PizZip(templateBinary);

      generationStage = "Template Compilation";
      const genDoc = new Docxtemplater(zip, combinedOptions);

      generationStage = "Set Template Data";
      genDoc.setData(removeInvalidCharactersFromKeys(templateData));

      generationStage = "Rendering Document";
      genDoc.render();

      generationStage = "Done";

      resolve(genDoc);
    } catch (error) {
      reject(errorMessageCompiler(generationStage, error));
    }
  });
}

generateDocument.angularParser = angularParser;

export default generateDocument;
