import severanceGuidance from ".././components/SurveySection/images/SeveranceGuidance.jpg";
import abfindungBeratung from ".././components/SurveySection/images/AbfindungBeratung.jpg";
import abfindungBerechnung from ".././components/SurveySection/images/AbfindungBerechnung.jpg";
import calculationImage from ".././components/SurveySection/images/calculation.png";
import lawyerAdvice from ".././components/SurveySection/images/LawyerAdvice.jpg";
import person from ".././components/SurveySection/images/person.png";
import pen from ".././components/SurveySection/images/pen.png";
import table from ".././components/SurveySection/images/table.png";
import finishLine from ".././components/SurveySection/images/OCFinishLine.png";
import contactOC from ".././components/SurveySection/images/contactOC.png";
import imgDownloadResult from ".././components/SurveySection/images/downloadResult.png";
import dismissalProtectionProcess from ".././components/SurveySection/images/dismissalProtectionProcess.png";
import dismissalProtectionProcessEN from ".././components/SurveySection/images/dismissalProtectionProcessEN.png";
import severanceNegotiation from ".././components/SurveySection/images/severanceNegotiation.png";
import severanceNegotiationEN from ".././components/SurveySection/images/severanceNegotiationEN.png";
import reasonsForDismissal1 from ".././components/SurveySection/images/reasonsForDismissal1.png";
import reasonsForDismissal1EN from ".././components/SurveySection/images/reasonsForDismissal1EN.png";
import reasonsForDismissal2 from ".././components/SurveySection/images/reasonsForDismissal2.png";
import reasonsForDismissal2EN from ".././components/SurveySection/images/reasonsForDismissal2EN.png";
import dismissalProcess from ".././components/SurveySection/images/dismissalProcess.png";
import dismissalProcessEN from ".././components/SurveySection/images/dismissalProcessEN.png";
import genDoc from "../components/DocXTemplater/index";

export const enTranslation = {
  translation: {
    // Navigation
    navbarItem0: "Intro",
    navbarItem1: "Newsletter",
    navbarItem2: "Calculator",
    navbarItem3: "Contact",
    navbarItem4: "Knowledge",
    navbarItem5: "Section 3",
    wikiNavbarItem0: "Home",
    wikiNavbarItem1: "Dismissal protection proceedings",
    wikiNavbarItem2: "Severance negotiation",
    wikiNavbarItem3: "Videos",
    wikiNavbarItem4: "Reasons for dismissal",
    wikiNavbarItem5: "Dismissal process",
    backToTopBtn: "Back to top",

    //Hero

    toSurveyButton: "Calculate now",

    // Intro
    header: "Osborne Clarke Solutions",
    header2: "Severance Calculator",
    intro1:
      "How do employers estimate the amount of severance pay quickly and realistically? The OC Solutions severance calculator can help! It also contains further information on matters to be covered in a termination offer and what role severance payments play in dismissal protection proceedings in Germany.",
    // "Considering to terminate an employee's contract with your company? Want to know how much severance this might require?",
    // intro2:
    //   "This severance calculator helps to determine a realistic severance offer in order to negotiate such an amicable termination. It also takes into account special cases such as special protection against dismissal or previous warnings given to the employee. However, the calculation does not apply for larger operational changes requiring negotiations with the works council, if any exists (in such cases the severance calculation will be agreed with the works council).",

    // Info Section 1
    infoTopline1: "Information",
    infoHeadline1: "Employment law newsletter & events",
    infoDescription1:
      "Would you like to receive employment law insights, news and events? <br/><br/>Follow this link to register for the Osborne Clarke Employment Newsletter! <br/><br/><br/>  ",
    infoExternalLink1: "<0>Register Now </0>",
    infoButton1: "Back to top",

    // Info Section 2
    infoTopline2: "Got any questions?",
    infoHeadline2: "Contact us!",
    infoDescription2:
      "Our team is happy to assist with any questions you might have.",
    infoExternalLink2: "<0>View OC Employment team</0>",
    emailLink: "<0>Email us!</0>",
    infoButton2: "Back to top",

    // Cards Section

    cardsHeading: "Knowledge",
    cardsDescription:
      "Background knowledge on dismissals and severance negotiation including graphs and videos",
    // card1heading: "Dismissal Protection Proceedings",
    card1heading: "<0>Dismissal Protection Proceedings</0>",
    card2heading: "<0>Severance Negotiation</0>",
    card3heading: "<0>Videos</0>",
    card4heading: "<0>Reasons for Dismissal</0>",
    card5heading: "<0>Dismissal Process</0>",

    //Knowledge
    wikiHeadline1: "Dismissal Protection Proceedings",
    wikiHeadline2: "Severance negotiation",
    wikiHeadline3: "Videos:",
    wikiHeadline4: "Reasons for dismissal",
    wikiHeadline5: "Dismissal process",
    wikiTopline3: "Dismissal protection proceedings + Severance negotiation",
    wikiDescription1:
      "Under German law, there is <strong>no general statutory entitlement to a severance payment</strong>. However, employees can challenge the effectiveness of a dismissal in a court procedure and obtain a <strong>reinstatement</strong> if it is found ineffective. This usually brings the parties to negotiate if there is a severance amount that would induce the employee to accept the termination. In addition, the courts have the task to try to bring the parties to a settlement and may suggest severance amounts. This process also affects preliminary out-of-court negotiations on mutual terminations. Only in small establishments with a maximum of 10 employees, the requirements of the Dismissal Protection Act  (<i>Kündigungsschutzgesetz</i>) do not apply.",
    wikiDescription2:
      "The following calculation is based on the <strong>assumption that the chances for both parties to win in court are approximately equal</strong>. On the one hand, the company has justifying reasons for the planned dismissal (these may rely on urgent operational, behavioural or person-related reasons under the Dismissal Protection Act, <i>Kündigungsschutzgesetz</i>. On the other hand, the employee has multiple possibilities to challenge the effectiveness of a dismissal and the burden of proof on the employer side is very high.<br><br>The following severance formula is a „<strong>rule of thumb</strong>“ for termination scenarios:<br><br> 0.5 monthly gross salaries<br> per year of employment <br><br> If the employee has strong arguments, he/she might only be willing to come to a termination agreement if the company offers a higher severance or other benefits (financially, release from work, good reference etc.). The same applies for employees 50+ who face more difficulties to find a new job. Also the economic situation and customs in the company’s business sector impact on the amounts of severance that are agreed in practice.",
    wikiDescription3: "",
    wikiDescription4: "",
    wikiDescription5: "",
    wikiImg1: dismissalProtectionProcessEN,
    wikiImg2: severanceNegotiationEN,
    wikiImg3: "",
    wikiImg4: [reasonsForDismissal1EN, reasonsForDismissal2EN],
    wikiImg5: dismissalProcessEN,

    // Impressum, Datenschutz, Cookies etc.

    cookieLabel: "Cookie-Richtlinie anzeigen",
    cookieLinkLabel: "Cookie-Richtlinie anzeigen",
    cookieText:
      " 🍪Einige Cookies sind unerlässlich, damit unsere Website wie beabsichtigt funktioniert. Andere sind nicht unbedingt erforderlich, helfen aber dabei, unsere Website und Ihre Erfahrung zu verbessern. Sie können auswählen, welche optionalen Cookies Sie zulassen möchten, indem Sie die Schaltfläche „Cookie-Einstellungen“ unten verwenden. Das Blockieren einiger Arten von Cookies kann jedoch Ihre Erfahrung mit der Website und den Diensten, die wir anbieten können, beeinträchtigen. Durch die Verwendung dieses Tools wird ein Cookie auf Ihrem Gerät gesetzt, um Ihre Präferenzen zu speichern.",
    licenses: "Licenses",
    dataPrivacy: "Data Privacy",

    // Survey Section
    selectLanguage: "Select survey language:",
    popupknowledge:
      "As an exception, if more than five employees have been employed already before 31 December 2003, the Dismissal Protection Act applies for them nevertheless. Part-time employees working up to 20 hours per week count as 0.5, those working up to 30 hours per week count as 0.75 for the calculation of the headcount.",

    popupDescriptionNoMore10:
      "As an exception, if more than five employees have been employed already before 31 December 2003, the Dismissal Protection Act applies for them nevertheless. Part-time employees working up to 20 hours per week count as 0.5, those working up to 30 hours per week count as 0.75 for the calculation of the headcount.",

    surveyHeading: "Severance Calculator",
    surveySubtitle: "",
    disclaimer:
      "I understand that the following severance calculation is an estimation and up to negotiations with the employee. This severance calculation tool provides an assessment aid, but does not constitute individual legal advice.",
    introImage: severanceGuidance,
    introTitel: "",
    introBody:
      "Our new severance calculation tool provides you with a quick and realistic assessment. <br/><br/>Based on the key figures of the case you receive a severance recommendation tailored to them.",
    disclaimerRequiredErrorText: "Please accept the disclaimer to continue.",
    disclaimerChoiceText:
      "Accept <br><br><hr><br><div style='font-size: 10pt, font-weight: bold'>Our new severance calculation tool provides you with a quick and realistic assessment. <br/><br/>Based on the key figures of the case you receive a severance recommendation tailored to them.</div><br>",
    surveyStart: "Start",
    surveyBack: "Back",
    surveyNext: "Next",
    surveyNew: "Restart",
    surveyPreview: "Preview",
    surveyEdit: "Edit",
    detailsAGTitle: "Please type in the annual fix salary.",
    detailsAGDescription: "Gross salary in EUR ",
    detailsAGRequiredErrorText: "*Response required.",
    detailsAGMinErrorText: "Can't be empty.",
    detailsVPTitle: "Variable payments, if any.",
    detailsVPDescription:
      "Annual variable or average of the last three years if higher.",
    detailsSDTitle: "Start date of employment",
    detailsSDDescription:
      "Eventually taking a prior start date into account if recognition is compulsory, i. e. in case of transfer of undertaking from a previous employer",
    detailsSDRequiredErrorText: "*Response required.",
    detailsDurTitle: "Duration of employment (calculated)",
    detailsDurDescription: "Decimal number taking months into account.",
    //Details Panel
    detailsTitle: "Special cases",
    detailsDescription:
      "<span>Do any of the following special cases apply? If not, the calculation will return the standard <u><b><a href='https://solutions.osborneclarke.com/osborneclarke/viewWikiPage.action?metaData.siteID=28257&metaData.wikiID=20130' target='_blank'>“rule of thumb”</a></b></u> amount. If yes, the special situation will be taken into account for the recommendation for further proceeding.</span>",

    //Details Panel Arguments for Employee
    detailsSpecCaseProEmpTitle: "Arguments in favour of the employee",
    detailsSpecCase50Title: "Employee 50+",
    detailsSpecCase50Description: "Employee is older than 50 years.",
    detailsSpecCase50Choice: "applicable",
    detailsSpecCaseNoReasonTitle: "No justified reason for dismissal",
    detailsSpecCaseNoReasonDescription:
      "We do not (yet) have a justified reason for dismissal under the Dismissal Protection Act / Kündigungsschutzgesetz (urgent operational, person-related or behaviour-related), but want to terminate nevertheless.",
    detailsSpecCaseNoReasonChoice: "applicable",
    detailsSpecCaseHandicappedTitle: "Severely handicapped or equivalent",
    detailsSpecCaseHandicappedDescription:
      "Employee is severely handicapped or equivalent. ",
    detailsSpecCaseHandicappedChoice: "applicable",
    detailsSpecCaseMatPatLeaveTitle:
      "Maternity or parental leave/ parental part-time",
    detailsSpecCaseMatPatLeaveDescription:
      "Employee is on maternity or parental leave or works parental part-time.",
    detailsSpecCaseMatPatLeaveChoice: "applicable",
    detailsSpecCaseRepBodyTitle: "Employee representative body",
    detailsSpecCaseRepBodyDescription:
      "Employee is a works council member, trainee representative, representative of severely disabled personnel or member of a comparable employee representative body, alternatively organizer of an election or a candidate.",
    detailsSpecCaseRepBodyChoice: "applicable",
    detailsSpecCaseDataProtTitle: "Data protection officer",
    detailsSpecCaseDataProtDescription:
      "Employee is the data protection officer of the company.",
    detailsSpecCaseDataProtChoice: "applicable",
    //Details Panel Arguments for Employer
    detailsSpecCaseProEmployerTitle: "Arguments in favour of the employer",
    detailsSpecCaseLessThan6Title: "Waiting period for dismissal protection.",
    detailsSpecCaseLessThan6Description:
      "The employee is employed less than 6 months.",
    detailsSpecCaseLessThan6Choice: "applicable",
    detailsSpecCaseLessThan6Valid: "Please check the employment start date.",
    detailsSpecCaseNoMore10Title:
      "The operation (Betrieb) has no more than 10 employees",
    detailsSpecCaseNoMore10Description:
      "Hence it will be considered as small establishment where no justifying reason for dismissal according to the Dismissal Protection Act, Kündigungsschutzgesetz, is required.",
    detailsSpecCaseNoMore10Choice: "applicable",
    detailsSpecCaseNoMore10Popup:
      "As an exception, if more than five employees have been employed already before 31 December 2003, the Dismissal Protection Act applies for them nevertheless. Part-time employees working up to 20 hours per week count as 0.5, those working up to 30 hours per week count as 0.75 for the calculation of the headcount.",
    detailsSpecCaseExtraDismTitle: "Reasons for an extraordinary dismissal. ",
    detailsSpecCaseExtraDismDescription:
      "We have reasons for an extraordinary dismissal without notice due to a severe breach of contract by the employee.",
    detailsSpecCaseExtraDismChoice: "applicable",
    detailsSpecCasePriorWarnTitle: "Formal prior warnings.",
    detailsSpecCasePriorWarnDescription:
      "We gave the employee formal prior warnings.",
    detailsSpecCasePriorWarnChoice: "applicable",
    // Results pages
    variante1amountText:
      "<span>Based on the data you entered and considering the <b> overall remuneration</b>, the calculated severance amount in line with the explained <u><b><a href='https://solutions.osborneclarke.com/osborneclarke/viewWikiPage.action?metaData.siteID=28257&metaData.wikiID=20130' target='_blank'>“rule of thumb”</a></b></u> is:</span>",
    resultVariante1Description:
      "(Formula: Monthly earnings x duration of employment x severance factor 0.5)",

    // GENERAL & REPEATS
    imageSeveranceGuidance: severanceGuidance,
    imageSeveranceCalculation: severanceGuidance,
    calculationImage: calculationImage,
    imageLawyerAdvice: lawyerAdvice,
    imagePerson: person,
    imagePen: pen,
    imageTable: table,
    imageFinish: finishLine,
    imageContactOC: contactOC,
    imgDownloadResult: imgDownloadResult,

    corridorTitle: "Negotiation Corridor",
    corridorTitleH3: "<h3>Negotiation Corridor</h3>",
    currencyGross: "(EUR gross)",
    fromTitle: "from",
    toTitle: "to",
    noSeverance: "No severance",
    severanceFactorManual: 'Severance factor (e.g. "0.6")',
    manualCalcMaxErrorText: "The value should not be greater than {3}",
    severanceAmount: "Severance amount",
    fixOrOverallTitle: "Fix salary or overall remuneration?",
    fixOverallLabelTrue: "Fix salary",
    fixOverallLabelFalse: "Overall remuneration",
    fixAndOverall: "Fix/overall remuneration",
    manualCalcTitle: "Choose factor for the severance calculation",
    pleaseNote: "Please note",
    hintNoLegalAdvice:
      "<h3>Note</h3>\nKindly note that this preliminary automated assessment does not replace individual legal advice. We will be glad to get in touch with you if you wish.\n",
    allOpenMatters: "Settle all open matters",
    seekLawyersAdvice: "Seek lawyers advice",
    severanceRuleOfThumb:
      "Severance Amount based on <u><b><a href='https://solutions.osborneclarke.com/osborneclarke/viewWikiPage.action?metaData.siteID=28257&metaData.wikiID=20130' target='_blank'>'Rule of thumb'</a></u></b>",
    writtenFormReq:
      "<h3>Written-form requirement </h3>\nThe agreement needs to be <b>originally signed by both parties</b> (strict statutory written-form requirement). ",
    minFactor025: "(Minimum = factor 0.25 based on the fix salary only)",
    minFactor05: "(Minimum = factor 0.5 based on the fix salary only)",
    minFactor075: "(Minimum = factor 0.75 based on the fix salary only)",
    maxFactor07: "(Maximum = factor 0.7 based on overall remuneration)",
    maxFactor1: "(Maximum = factor 1.0 based on overall remuneration)",
    maxFactor03: "(Maximum = factor 0.3 based on the overall remuneration)",
    maxFactor035: "(Maximum = factor 0.35 based on overall remuneration)",
    maxFactor15: "(Maximum = factor 1.5 based on overall remuneration)",

    // Corridor panel

    likelySeverance:
      "It seems likely that a severance in the following corridor would <br>be a reasonable basis for a termination agreement:",

    // 50+ panel
    emp50PlusTitle: "Employee older 50 years",
    emp50PlusSuggestion:
      "For employees 50+ some courts suggest a higher severance factor of 0.75. Hence, it seems likely that a severance in the following corridor would be a reasonable basis for a termination agreement:",
    // 50+ & no reason panel
    emp50PlusNoReasonTitle:
      "Employee older 50 years & no reasons for dismissal",
    emp50PlusNoReasonSuggestion:
      "For employees 50+ some courts suggest a higher severance factor of 0.75. However, as the termination is apparently motivated by other reasons while you indicate that there are no sufficient reasons for termination from a legal perspective (urgent operational, person-related or behaviour-related), the employee has very good chances to be successful at court and obtain reinstatement. Therefore, a higher severance factor is probably needed to obtain a settlement, if the employee is ready to terminate the employment at all. A severance in the following corridor would be a reasonable basis for a termination agreement in such case:",
    likelySeveranceHence:
      "Hence, it seems likely that a severance in the following corridor would be a reasonable basis for a termination agreement:",

    //No reason & no warning
    noReasonNoWarning:
      "However, as the termination is apparently motivated by other reasons while you indicate that there are no sufficient reasons for termination from a legal perspective (urgent operational, person-related or behaviour-related), the employee has very good chances to be successful at court and obtain reinstatement. Therefore, a higher severance factor is probably needed to obtain a settlement, if the employee is ready to terminate the employment at all. A severance in the following corridor would usually be a reasonable basis for a termination agreement in such case:",
    noReasonTitle: "No reasons for dismissal",
    // Short employment

    shortemploymentrelationshipsTitle: "Short employment relationships",
    shortemploymentrelationshipsDescription:
      "For short employment relationships, a severance in the amount of at least three monthly salaries might be required which would lead to a higher amount here: ",

    //No reason & warning panel
    noReasonWithWarning:
      "However, as the termination is apparently motivated by other reasons while you indicate that there are no sufficient reasons for termination from a legal perspective (urgent operational, person-related or behaviour-related) despite formal prior warning(s), the employee has very good chances to be successful at court and obtain reinstatement. Therefore, a higher severance factor is probably needed to obtain a settlement, if the employee is ready to terminate the employment at all. A severance in the following corridor would usually be a reasonable basis for a termination agreement in such case:",

    // Extraordinary dismissal panel

    extraordinaryDismissalLawyerAdvice:
      "<h3>Seek lawyer advice </h3>\n\nBased on the information you entered, you see reasons for an extraordinary dismissal without notice due to a severe breach of contract by the employee. We highly recommend to <b>seek lawyer advice</b> to evaluate the situation, the legal requirements for dismissal and the possible next steps together. It may even be possible to reach a termination agreement only on the basis of the ordinary notice period, but without paying a severance.",
    priorWarningLawyerAdvice:
      "<h3>Seek lawyer advice </h3>\n\nBased on the information you have provided, you have already given the employee a warning, but you do not yet see sufficient grounds for extraordinary termination without notice due to a serious breach of contract. We highly recommend to <b>seek lawyer advice</b> to evaluate the situation, the legal requirements for dismissal and the possible next steps together. It may even be possible to obtain a termination settlement against payment of a smaller severance.",
    // Panel 7 rule of thumb
    ruleOfThumbCalc:
      "<span>Nevertheless, for your information, we calculated the severance amount in line with the explained <u><b><a href='https://solutions.osborneclarke.com/osborneclarke/viewWikiPage.action?metaData.siteID=28257&metaData.wikiID=20130' target='_blank'>“rule of thumb”</a></b></u>. Based on the data you entered and considering <b><u>the overall remuneration</b></u>, it would be:</span>",
    resultVariante2Description:
      "(Formula: Monthly earnings x duration of employment x severance factor 0.5)",
    panel7Title:
      "Severance Amount based on <u><b><a href='https://solutions.osborneclarke.com/osborneclarke/viewWikiPage.action?metaData.siteID=28257&metaData.wikiID=20130' target='_blank'>'Rule of thumb'</a></u></b>",

    // Panel 8 estimate

    panel8SeveranceEstimate:
      "Depending on the strength of the reasons for dismissal, it seems likely that a severance in the following corridor would be a reasonable basis for a termination agreement:",

    // Employment for less than 6 months

    lessThan6Title: "Employment for less than 6 months",
    lessThan6:
      "Based on the information you entered, the employment only lasted less than 6 months. Therefore, the employee is still in the so-called “waiting period” and does not enjoy protection under the Dismissal Protection Act <i>(Kündigungsschutzgesetz)</i>. Therefore, employees in the waiting period usually do not get a severance payment when being dismissed. However, the dismissal needs to be <b> originally signed </b>(strict statutory written-form requirement) and transmitted with proof of receipt before the end of the waiting period. Also the applicable notice period needs to be observed. ",

    // Maternity leave

    maternityLeave:
      "However, you indicated that the employee is on maternity or parental leave. This triggers special protection against dismissal even during the waiting period and we <b>highly recommend to seek lawyer advice</b>.",
    maternityLeaveParticularities:
      "If there are any particularities you wish to discuss or if you want your OC lawyer to prepare the dismissal, you can let us know. ",

    // Special protection & Not less than 6
    //handicapped
    specialProtHandicapped:
      "Based on the information you entered, the employee enjoys <b>special protection against dismissal </b>as he/she is severely handicapped. Due to this special protection, we <b>highly recommend to seek lawyer advice</b>.",

    specialProtRepBody:
      "Based on the information you entered, the employee enjoys <b>special protection against dismissal </b>as he/she is part of an employee representative body. Due to this special protection, we <b>highly recommend to seek lawyer advice</b>.",
    specialProtDataOfficer:
      "Based on the information you entered, the employee enjoys <b>special protection against dismissal </b>as he/she is a data protection officer. Due to this special protection, we <b>highly recommend to seek lawyer advice</b>.",
    specialProtMatLeave:
      "Based on the information you entered, the employee enjoys <b>special protection against dismissal </b>as he/she is on maternity or parental leave or works parental part-time. Due to this special protection, we <b>highly recommend to seek lawyer advice</b>.",
    specialProtExtrordDism:
      "The existence of reasons for an extraordinary termination can then be taken into account during the consultation.",
    specialProtRuleOfThumb:
      "In cases of special protection against dismissal, the severance payments usually exceed the explained <u><b><a href='https://solutions.osborneclarke.com/osborneclarke/viewWikiPage.action?metaData.siteID=28257&metaData.wikiID=20130' target='_blank'>“rule of thumb”</a></b></u> and may rather be in the following corridor:</span>",
    minimumMonthlyEarnings:
      "(Minimum = Monthly earnings including variable x duration of employment x factor 1.0)",

    maximumMonthlyEarnings:
      "(Maximum = Monthly earnings including variable x duration of employment x factor 2.0)",

    // Max 10 employees
    smallBusiness10Emp:
      "<h3>\nSmall business up to 10 employees</h3><br>\nBased on the data you entered the establishment where the employee works has a maximum of 10 employees. Hence it will be considered as small establishment where no justifying reason for dismissal according to the Dismissal Protection Act, <i>Kündigungsschutzgesetz</i>, is required. Accordingly, often no severance is paid at all. <br><br>\nIf the headcount is close to 10, kindly note the details of the calculation: As an exception, if more than five employees have been employed already before 31 December 2003, the Dismissal Protection Act applies for them nevertheless. Part-time employees working up to 20 hours per week count as 0.5, those working up to 30 hours per week count as 0.75 for the calculation of the headcount.\n",
    smallBusiness10EmpImpactSeverance:
      "<span>The qualification as small establishment also impacts on the usual severance calculation if the Company decides to offer a severance nevertheless. Such severance is usually only about half of the explained <u><b><a href='https://solutions.osborneclarke.com/osborneclarke/viewWikiPage.action?metaData.siteID=28257&metaData.wikiID=20130' target='_blank'>“rule of thumb”</a></b></u>, if any. Considering the <b><u>overall remuneration</u></b>, such severance amount would be:</span>",
    monthlyEarnings25:
      "(Monthly earnings x duration of employment x severance factor 0.25)",

    // Results Options
    resultMutualTermDismTitle: "Mutual termination or dismissal",
    resultOption1:
      "You could try to obtain a <b>mutual termination agreement</b> with the employee by offering the minimum calculated amount as a starting point. Alternatively, you might proceed to a <b>dismissal</b>, but be prepared to pay an amount within the outlined corridor in case of a court settlement. In both cases, the employee’s claims are likely to be higher if he/she sees good arguments on his/her side to win the court case. Usually employees will have the expectation to obtain a severance based on their <b>overall</b> remuneration (amount indicated above). ",
    resultOption2NoSeverance:
      "You could try to obtain a <b>mutual termination agreement</b> by offering a termination without severance as a starting point. Alternatively, you might proceed to a <b>dismissal</b>. As the employee is working in a small establishment, he/she has only very limited chances to contest the effectiveness of the dismissal (e. g. if it is very clear that the employment is terminated arbitrarily or due to discrimination). However, in order to avoid dispute about the termination or compensate the loss of employment, some employers offer a small severance.",
    resultHandicappedMatLeave:
      "In spite of the special protection against dismissal, you could still try to obtain a <b>mutual termination agreement</b> with the employee. Proceeding to a dismissal would require to seek approval of a public authority.",
    resultRepBody:
      "In spite of the special protection against dismissal, you could still try to obtain a <b>mutual termination agreement</b> with the employee. A dismissal would generally only be possible as extraordinary dismissal based on an important reason and after obtaining the works council's approval. ",
    resultDataOfficer:
      "In spite of the special protection against dismissal, you could still try to obtain a <b>mutual termination agreement</b> with the employee.",
    resultNoticeSmallSeverance:
      "You could try to obtain a <b>mutual termination agreement</b> with the employee by offering a mutual termination observing the notice period and eventually only a small severance as a starting point. Alternatively, you might proceed to an extraordinary and, by precaution, also ordinary <b>dismissal</b>, but be prepared to pay an amount within the outlined corridor in case of a court settlement. In particular, the legal requirements for extraordinary dismissals are high and the employee might still find arguments on his/her side to win the court case. Usually employees will have the expectation to agree on the ordinary termination of the employment under observation of the notice period and obtain a severance based on their overall remuneration (amount indicated above).",
    resultMinSeverance:
      "You could try to obtain a <b>mutual termination agreement</b> with the employee by offering the minimum calculated amount as a starting point. Alternatively, you might proceed to a <b>dismissal</b>, but be prepared to pay an amount within the outlined corridor in case of a court settlement or even accept a <b>reinstatement</b>. In both cases, the employee’s claims are likely to be higher if he/she sees good arguments on his/her side to win the court case. Usually employees will have the expectation to obtain a severance based on their overall remuneration (amount indicated above). ",
    resultRouteTermAgreement:
      "If you take the route of a mutual termination agreement, we recommend to <b>include all other open matters</b>, in particular: <ul><li> the effective date of termination,</li><li> full salary for the remaining employment period,</li><li> due amount for variable payments,</li><li> possibly release from work,</li><li> remaining holiday entitlement, </li><li> agreed grade for the reference letter,</li><li> settlement of all mutual claims etc.</li></ul>",

    // Final site
    thanksForUsing:
      "\n\n<br /><br /><h3>Thank you for using our severance calculation tool.</h3>\n\n<h4>Please choose the next steps.</h4>",
    howToProceed: "How would you like to proceed?",
    downloadResult: "Download Summary",

    contactOC:
      '<a class="contactLink"\n href="mailto:severance-calculator@osborneclarke.com?subject=Anfrage Abfindungsrechner/Inquiry severance calculator">Email the OC Employment team!</a>\n',
  },
};
