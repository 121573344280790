import React from "react";
import { Button } from "../ButtonElement";
import { Trans, useTranslation } from "react-i18next";
import ocCheck from "../SurveySection/images/ocCheck.gif";
import laptop from "../SurveySection/images/laptop.png";

import i18next from "../../glossary";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
} from "./InfoElements";

const InfoSection = (
  lightBg,
  imgStart,
  id,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  externalLink,
  emailLink,
  buttonLabel,
  buttonTo,
  extLink,
  img,
  alt,
  primary,
  dark,
  dark2
) => {
  const { t, i18n } = useTranslation();
  const lng = i18n.language;

  return (
    <>
      <InfoContainer lightBg={lightBg} id="newsletter">
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                {/* <TopLine>{topLine}</TopLine> */}
                <TopLine>{t("infoTopline1")}</TopLine>
                <Heading lightText={lightText}>{t("infoHeadline1")}</Heading>
                <Subtitle darkText={darkText}>
                  {" "}
                  <p>
                    <Trans i18nKey="infoDescription1">
                      {" "}
                      Would you like to receive employment law insights, news
                      and events? Follow this link to register for the Osborne
                      Clarke Employment Newsletter!
                    </Trans>
                  </p>
                </Subtitle>

                {(() => {
                  switch (lng) {
                    case "de":
                      return (
                        <Subtitle darkText={darkText}>
                          {" "}
                          <Trans i18nKey="infoExternalLink1">
                            <a
                              class="manualButton"
                              href="https://sites-osborneclarke.vuturevx.com/10/7683/deutsch/arbeitsrecht-blank.asp"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Jetzt anmelden
                            </a>
                          </Trans>
                        </Subtitle>
                      );
                    case "en":
                      return (
                        <Subtitle darkText={darkText}>
                          {" "}
                          <Trans i18nKey="infoExternalLink1">
                            <a
                              class="manualButton"
                              href="https://sites-osborneclarke.vuturevx.com/10/7683/english/employment-blank.asp"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Register Now
                            </a>
                          </Trans>
                        </Subtitle>
                      );

                    default:
                      return (
                        <Subtitle darkText={darkText}>
                          {" "}
                          <Trans i18nKey="infoExternalLink1">
                            <a
                              class="manualButton"
                              href="https://sites-osborneclarke.vuturevx.com/10/7683/english/employment-blank.asp"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Register Now
                            </a>
                          </Trans>
                        </Subtitle>
                      );
                  }
                })()}

                <BtnWrap>
                  <Button
                    to="home"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {t("infoButton1")}
                  </Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={laptop} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
