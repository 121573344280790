// import React from "react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../../dropdown";
import i18next from "../../glossary";

//TODO: Styles anpacken

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  // const [locale, setLocale] = useState(value);
  // const handleChange = (event) => {
  //   setLocale(event.target.value);
  // };
  // useEffect(() => {
  //   changeLocale(locale);
  // }, [locale]);

  return (
    <div class="languageSelect">
      <select
        class="selectLng"
        value={i18n.language}
        onChange={(e) => [i18n.changeLanguage(e.target.value)]}
      >
        <option value="de">Deutsch</option>
        <option value="en">English</option>
      </select>
    </div>
  );

  // <label>
  //   {label}
  //   <select value={locale} onChange={handleChange}>
  //     {options.map((option) => (
  //       <option value={option.value}>{option.label}</option>
  //     ))}
  //   </select>
  // </label>
}

export default LanguageSwitcher;
