export const settings = {
  navBar: {
    smooth: true,
    duration: 500,
    spy: true,
    exact: "true",
    offset: -80,
    signinHidden: true,
    navBarDark: true,
  },
};
