/* global */

import expressions from "angular-expressions";
import assign from "lodash/assign";
import {
  registerAllFilters,
  registerFilter,
  getFilters,
  clearFilters,
} from "./registerFilter";

function angularParser(tag) {
  if (tag === ".") {
    return {
      get: function (s) {
        return s;
      },
    };
  }
  const expr = expressions.compile(
    tag.replace(/(’|‘|‚)/g, "'").replace(/(“|”|„)/g, '"')
  );
  return {
    get: function (scope, context) {
      let obj = {};
      const scopeList = context.scopeList;
      const num = context.num;
      for (let i = 0, len = num + 1; i < len; i++) {
        obj = assign(obj, scopeList[i]);
      }
      return expr(scope, obj);
    },
  };
}

angularParser.expressions = expressions;

angularParser.registerAllFilters = registerAllFilters.bind(angularParser);
angularParser.registerFilter = registerFilter.bind(angularParser);
angularParser.getFilters = getFilters.bind(angularParser);
angularParser.clearFilters = clearFilters.bind(angularParser);

export default angularParser;
