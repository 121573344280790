import React, { useState, useEffect } from "react";

import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { HashLink } from "react-router-hash-link";
import { Trans, useTranslation } from "react-i18next";
import i18next from "../../glossary";

import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SideBarItem,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
} from "./SidebarElements";

const Sidebar = ({ isOpen, toggle, items, navSettings }) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [navColorDark, setnavColorDark] = useState(navSettings.navBarDark);
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SideBarItem>
            <HashLink
              class="navBarItem"
              to="/#home"
              onClick={
                onclick
                  ? function () {
                      this.onclick();
                    }
                  : ""
              }
              smooth={navSettings.smooth}
              duration={navSettings.duration}
              spy={navSettings.spy}
              exact={navSettings.exact}
              offset={navSettings.offset}
            >
              {i18next.t("navbarItem0")}
            </HashLink>

            <HashLink
              class="navBarItem"
              to="/#newsletter"
              onClick={
                onclick
                  ? function () {
                      this.onclick();
                    }
                  : ""
              }
              smooth={navSettings.smooth}
              duration={navSettings.duration}
              spy={navSettings.spy}
              exact={navSettings.exact}
              offset={navSettings.offset}
            >
              {i18next.t("navbarItem1")}
            </HashLink>
            <HashLink
              class="navBarItem"
              to="/#surveys"
              onClick={
                onclick
                  ? function () {
                      this.onclick();
                    }
                  : ""
              }
              smooth={navSettings.smooth}
              duration={navSettings.duration}
              spy={navSettings.spy}
              exact={navSettings.exact}
              offset={navSettings.offset}
            >
              {i18next.t("navbarItem2")}
            </HashLink>

            <HashLink
              class="navBarItem"
              to="/#discover"
              onClick={
                onclick
                  ? function () {
                      this.onclick();
                    }
                  : ""
              }
              smooth={navSettings.smooth}
              duration={navSettings.duration}
              spy={navSettings.spy}
              exact={navSettings.exact}
              offset={navSettings.offset}
            >
              {i18next.t("navbarItem4")}
            </HashLink>

            <HashLink
              class="navBarItem"
              to="/#contacts"
              onClick={
                onclick
                  ? function () {
                      this.onclick();
                    }
                  : ""
              }
              smooth={navSettings.smooth}
              duration={navSettings.duration}
              spy={navSettings.spy}
              exact={navSettings.exact}
              offset={navSettings.offset}
            >
              {i18next.t("navbarItem3")}
            </HashLink>

            {/* {items.map((item, index) => {
            return (
              <SidebarLink to={item.to} onClick={toggle}>
                {item.title}
              </SidebarLink>
            );
          })} */}
          </SideBarItem>

          <LanguageSwitcher />
        </SidebarMenu>
        <SideBtnWrap></SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
