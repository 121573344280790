import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import severanceGuidance from "./components/SurveySection/images/SeveranceGuidance.jpg";
import severanceGuidance from "./components/SurveySection/images/SeveranceGuidance.jpg";
import abfindungBeratung from "./components/SurveySection/images/AbfindungBeratung.jpg";
import abfindungBerechnung from "./components/SurveySection/images/AbfindungBerechnung.jpg";
import calculationImage from "./components/SurveySection/images/calculation.png";
import lawyerAdvice from "./components/SurveySection/images/LawyerAdvice.jpg";
import person from "./components/SurveySection/images/person.png";
import pen from "./components/SurveySection/images/pen.png";
import table from "./components/SurveySection/images/table.png";
import finishLine from "./components/SurveySection/images/OCFinishLine.png";
import contactOC from "./components/SurveySection/images/contactOC.png";
import imgDownloadResult from "./components/SurveySection/images/downloadResult.png";
import dismissalProtectionProcess from "./components/SurveySection/images/dismissalProtectionProcess.png";
import dismissalProtectionProcessEN from "./components/SurveySection/images/dismissalProtectionProcessEN.png";
import severanceNegotiation from "./components/SurveySection/images/severanceNegotiation.png";
import severanceNegotiationEN from "./components/SurveySection/images/severanceNegotiationEN.png";
import reasonsForDismissal1 from "./components/SurveySection/images/reasonsForDismissal1.png";
import reasonsForDismissal1EN from "./components/SurveySection/images/reasonsForDismissal1EN.png";
import reasonsForDismissal2 from "./components/SurveySection/images/reasonsForDismissal2.png";
import reasonsForDismissal2EN from "./components/SurveySection/images/reasonsForDismissal2EN.png";
import dismissalProcess from "./components/SurveySection/images/dismissalProcess.png";
import dismissalProcessEN from "./components/SurveySection/images/dismissalProcessEN.png";
import genDoc from "./components/DocXTemplater/index";
import { enTranslation } from "./translation/enTranslation";
import { deTranslation } from "./translation/deTranslation";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const resources = {
  en: enTranslation,
  de: deTranslation,
};

// const detectionOptions = {
//   order: ["localStorage", "lang"],
//   lookupQuerystring: "lang",
//   lookupLocalStorage: "lang",
//   caches: ["localStorage"],
// };
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "de",
    debug: true,
    resources,
    // lng: "de",
    // detection: detectionOptions,
    // have a common namespace used around the full app
    // ns: ["translation"],
    // defaultNS: "translation",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
