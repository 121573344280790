import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

export const WikiContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#001017")};
  height: auto;
  width: auto;

  @media screen and (max-width: 768px) {
    padding-bottom: 2vh;
  }
  @media screen and (max-width: 450px) {
    padding-bottom: 2vh;
  }
`;

export const WikiWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 768px) {
    display: inline-block;

    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const WikiRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0px 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 100vw;
  padding-top: 0;
  padding-bottom: 30px;
  margin-top: 110px;
  @media screen and (max-width: 480px) {
    margin-top: 15vh;
    margin-bottom: 2px;
  }
`;

export const TopLine = styled.p`
  color: #1796bc;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  padding-top: 5vh;
  font-size: 42px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#001017")};

  @media screen and (max-width: 768px) {
    font-size: 24px;
    padding-top: 1vh;
    margin-bottom: 12px;
  }
`;

export const Subtitle = styled.p`
  max-width: 750px;
  margin-bottom: 35px;
  font-size: 14px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#001017" : "#fff")};

  @media screen and (max-width: 480px) {
    font-size: 16px;
    padding-top: 1vh;
    margin-bottom: 12px;
  }
`;

export const BtnWrap = styled.div`
  max-width: 150px;
  padding-top: 0;
  padding-bottom: 30px;
`;

export const ImgWrap = styled.div`
  max-width: 750px;
  height: 100%;
  align-items: center;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;

export const VideoBg = styled.video`
  height: 60vh;
  width: 60vw;
  max-width: 100%;
  padding-bottom: 3vh;
  -o-object-fit: cover;
  object-fit: contain;
  background: #001017;
`;

export const VideoWrapper = styled.div`
  max-width: 750px;
  height: 100%;
`;
export const Hashlink = styled(HashLink)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  float: left;

  //bottom green bar when selected
  &.active {
    border-bottom: 3px solid #1796bc;
  }
`;
